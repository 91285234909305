import { gql } from 'apollo';
import { CustomStoreExt } from 'types/datatypes/classes';
import { ILab } from 'types/datatypes/ILab';
import { INom } from 'types/datatypes/INom';
import { TDoc_types } from 'types/IOptions';
import { createDataSource } from 'utils/gql-datasource';
const dsGetNoms = gql(`query dsGetNoms($ref: String, $limit: Int, $offset: Int, $jfilt: [JSON]) {
  totalcount: noms(ref: $ref, totalCount: 1) { totalcount }
  list: noms(ref: $ref, limit: $limit, offset: $offset, jfilt: $jfilt) {
    ref 
    name 
    name_full 
    vat_rate
    article
    doc_type
  }
}`);

// use default proj ref as OTK
const otkProj = 'afe61914-f69c-11eb-8ac0-00155d000c0a';

export const dsNoms = createDataSource<INom>(dsGetNoms);

export const dsProjNoms = <T=INom>(projRef:string, lab:ILab, doc_type:TDoc_types|null = null): CustomStoreExt<T, any> => {
  const services = lab?.services?.map((row) => row.nom) ?? [];
  const dsProxy = Object.create(dsNoms);
  const projFilter = [ 'proj', '=', projRef ?? otkProj ];
  dsProxy.load = (options:any) => dsNoms.load({ ...options, filter: projFilter })
    .then((rows: any) => {
      if (services?.length) {
        const filtered = rows?.data?.filter((row:any) => services.includes(row.ref));
        if (filtered.length) rows.data = filtered;
      }
      //filtering by doc_type
      rows.data = rows?.data?.filter((row:any) => row?.doc_type === doc_type);
      return rows;
    });
 
  return dsProxy;
};
