import { FC, useRef } from "react"
import styles from './clients.module.scss';
import { DataGrid } from "devextreme-react";
import { Column, Paging, Scrolling } from "devextreme-react/data-grid";
import { ClientInfo, ClientInfoHandle } from "./ClientInfo";
import { gql } from "apollo";
import { createDataSource } from "utils/gql-datasource";
import { ClientRecords, ClientRecordsInstance } from "./ClientReports";
import { PngIcon } from "components/menuItem/PngItem";
import { SearchButton } from "../components/SearchButton";
import { TagStatus } from "../components/TagStatus";

const q = gql(`query CRMPartners ($limit: Int, $offset: Int, $userData: JSON, $requireTotalCount: Boolean!) {
totalcount: crmPartners( userData:$userData, totalCount: 1 ) @include(if: $requireTotalCount) { totalcount }
list:  crmPartners(limit:$limit, offset:$offset, userData: $userData) {
   ref name remind urgent_call lost
  cars {
    gnumber sname mvisit
    reports {ref date number_doc amount } 
  }}
}`)

export interface CRMReport {
  ref: string,
  date: string,
  // gnumber :string,
  norm: number,
  monthes: number,
  number_doc: string,
  amount: number,
}

interface CRMCar {
  gnumber: string,
  sname: string,
  mvisit: number,
  reports: CRMReport[]
}

interface CRMPartner {
  ref: string,
  name: string,
  remind: boolean,
  urgent_call: boolean,
  lost: boolean,
  cars: CRMCar[]
}

export const dsPartners = createDataSource<CRMPartner, any>(q);



export type TStates = 'remind' | 'urgent' | 'lost';
interface IFilters {
  name?: string;
  states?: TStates[]
}
export const Clients: FC = () => {
  const runOnce = useRef(false);
  const partnersGrid = useRef<DataGrid>(null)
  const clientInfo = useRef<ClientInfoHandle>(null)
  const clientRecords = useRef<ClientRecordsInstance>(null)
  const filters = useRef<IFilters>({});

  const setFilter = (props: IFilters) => {
    filters.current.name = props?.name;
    filters.current.states = props?.states;
    dsPartners.userData = filters.current ?? {}
    partnersGrid.current?.instance.refresh();
  }

  return (<div className={styles.pagewrapper}>
    <div className={styles.gridaside}>
      <div style={{ display: 'flex' }}>
        <SearchButton onInput={(text) => setFilter({ name: text })} />
        <TagStatus onInput={(states) => setFilter({ states: states })}/>
      </div>
      <DataGrid<CRMPartner>
        ref={partnersGrid}
        key={'partner'}
        height={'100%'}
        defaultFocusedColumnIndex={0}
        dataSource={dsPartners}
        showColumnHeaders={false}
        remoteOperations={{ grouping: false, filtering: true, paging: true, sorting: false }}
        focusedRowEnabled
        // onFilterValueChange={(val) => {
        //   console.log(val)
        // }}
        onOptionChanged={(d: any) => {
          if (d.fullName === 'columns[0].filterValues') {
            runOnce.current = false;
            // setFilter({
            //   name: 
            // })  
          }
        }}
        onContentReady={() => {
          if (!runOnce.current) {
            const el = partnersGrid.current?.instance.getCellElement(0, 'name')!
            if (!!el) {
              partnersGrid.current?.instance.focus(el);
              runOnce.current = true;
            }
          }
        }}
        onFocusedRowChanged={(e) => {
          if (e.row) {
            clientRecords.current?.setReports(e.row.data.cars)
            clientInfo.current?.setPartnerRef(e.row.data.ref)
          }
        }}
        showRowLines={false}
        onCellPrepared={(e) => {
          if (e.rowType === 'data') {
            if (e.data.remind) {
              e.cellElement.style.background = "green";
              e.cellElement.style.color = "white";
            }
            else if (e.data.urgent_call) {
              e.cellElement.style.background = "#e65e03";
              e.cellElement.style.color = "white";
            }
            else if (e.data.lost) {
              e.cellElement.style.background = "#4d4d4d";
              e.cellElement.style.color = "white";
            }
          }

        }}

      >
        {/* <FilterRow visible={true} /> */}
        <Scrolling showScrollbar={true} mode='infinite' />
        <Paging enabled={false} pageSize={100} />
        <Column dataField='name' allowSorting={false} />
        <Column allowSorting={false} cellRender={statusCell} width={80} allowFiltering={true} caption={''} />
      </DataGrid>
    </div>
    <div className={styles.rightaside}>
      <ClientInfo ref={clientInfo} />
      <ClientRecords ref={clientRecords} />
    </div>
  </div>)
}

const statusCell = (e: any) => {
  return (<div style={{ display: 'flex', gap: '5px', justifyContent: 'center' }}>
    {!!e.data.remind && <PngIcon size={16} iconType="bell" />}
    {!!e.data.urgent_call && <PngIcon size={16} iconType="alert" />}
    {!!e.data.lost && <PngIcon size={16} iconType="cross" />}
  </div>)
}



