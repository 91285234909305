import { exportDataGrid } from 'devextreme/excel_exporter';



export const exportExcel = async (fileName, dataGridInstance) => {
  const { Workbook } = await import('exceljs');
  const { default: saveAs } = await import('file-saver');
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet(fileName);
  exportDataGrid({ component: dataGridInstance, worksheet }).then(() => {
    worksheet.columns.forEach(c=> c.width= Math.min(50, c.width));
    workbook.xlsx
      .writeBuffer()
      .then((buffer) => saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${fileName}.xlsx`));
  });
};
