import { DATE_DISPLAY_FORMAT, DATE_FORMAT } from 'app-constants';
import minMax from 'dayjs/plugin/minMax';
import dayjs from 'dayjs';
import { useState } from 'react';

export const useDate = () => {
  dayjs.extend(minMax);
  const today = dayjs().startOf('day');
  const yesterday = dayjs().add(-1, 'day');
  const prevMonth = dayjs().add(-1, 'month').startOf('month').format(DATE_FORMAT);
  const formatDate = (value?:dayjs.ConfigType) => dayjs(value).format(DATE_FORMAT);
  const startOfMonth = (value?:dayjs.ConfigType) => dayjs(value).startOf('month').format(DATE_FORMAT);
  const endOfMonth = (value?:dayjs.ConfigType) => dayjs(value).endOf('month').format(DATE_FORMAT);
  const startOfDay = (value?:dayjs.ConfigType) => dayjs(value).startOf('day').format(DATE_FORMAT);
  const endOfDay = (value?:dayjs.ConfigType) => dayjs(value).endOf('day').format(DATE_FORMAT);
  const startOfYear = (value?:dayjs.ConfigType) => dayjs(value).startOf('year').format(DATE_FORMAT);
  const displayDateTime = (value?:dayjs.ConfigType) => dayjs(value).format(DATE_DISPLAY_FORMAT);
  
  const [func] = useState({
     today, yesterday, prevMonth, formatDate, displayDateTime, startOfYear, startOfMonth, endOfMonth, startOfDay, endOfDay
     })
  return func;//{ today, formatDate, displayDateTime, startOfYear, startOfMonth, startOfDay, endOfDay };
};
