import React, { useMemo,  ReactNode,  RefObject } from 'react';
import DataGrid, {
  Selection,
  Paging,
  FilterRow,
  Scrolling,
  Column,
  Lookup,
  Button as CButton,
  StateStoring,
  RemoteOperations,
  Sorting,
  HeaderFilter,
} from 'devextreme-react/data-grid';
import { Button, Menu } from 'devextreme-react';
import { confirm } from 'devextreme/ui/dialog';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  COLUMN_DATE_WIDTH,
  COLUMN_DOCNUMBER_WIDTH,
  COLUMN_EDIT_WIDTH,
  COLUMN_PARTNER_MINWIDTH,
  COLUMN_PARTNER_WIDTH,
  uaFilterRowText,
  DX_DATETIME_DISPLAY_FORMAT,
  DOCLIST_PAGE_SIZE,
  DX_DATE_SERIALIZATION_FORMAT,
} from 'app-constants';
import { StickyBar } from 'components/sticky-bar/sticky-bar';
import styles from './doclist-page-template.module.scss';
import { useScreenSize } from 'utils/media-query';
import { messages } from 'messages';
import { showError } from 'utils/notify';
import { dsPartners } from 'datasources';
import { exportExcel } from 'utils/export-excel';
import { CellHoverChangedEvent, CellPreparedEvent, RowDblClickEvent, RowPreparedEvent, SelectionChangedEvent } from 'devextreme/ui/data_grid';
import { ItemInfo, MenuItem } from 'types/otkTypes';
import { renderMenuItem } from 'components/menuItem/menuUtils';
import { IPartner } from 'types/datatypes/IPartner';
import { Store } from 'devextreme/data';

const defaultColumnsDataFields = {
  date: 'date',
  docNumber: 'number_doc',
  partner: 'partner',
  note: 'note',
};

const exportConfirmText = (totalCount:number) => (
  `Щоб зменшити кількість записів використовуйте фільтри.
  Будуть збережені тільки перші ${DOCLIST_PAGE_SIZE} з ${totalCount} записів`
);

interface Props<TItem> {
  children?: ReactNode;
  stateStoringEnabled?:boolean,
  deps?: any[],
  columnsDataFields?:{[key:string]:string},
  showFilter?:boolean,
  sorting?:boolean,
  remoteFiltering?:boolean,
  onCellHoverChanged?:(event:CellHoverChangedEvent<any, any>)=>void;
  onRowPrepared?:(e:RowPreparedEvent<TItem>) => void;
  onCellPrepared?:(e:CellPreparedEvent<TItem>) => void;
  onSelectionChanged?:(e:SelectionChangedEvent) => void;
  gridRef: RefObject<DataGrid>;
  pageName?:string;
  dataSource:Store<TItem>;
  onEdit?:(objRef:string, obj?:TItem)=>void,
  onDelete?:(objRef:string)=>void,
  headerBarComponent?:any,
  menuComponent?:any,
  optionsComponent?:any
  headerFilter?: {
    visible:true
  }
}

interface DefItem {
  ref:string,
  partner?:Pick<IPartner, 'ref'|'name'>
}


export const DoclistPageTemplate =  <TItem = DefItem>({
  gridRef,
  stateStoringEnabled = true,
  deps = [],
  showFilter = true,
  sorting = true,
  remoteFiltering = true,
  onCellHoverChanged,
  pageName = '',
  dataSource,
  columnsDataFields=defaultColumnsDataFields,
  headerFilter,
  ...props
}: Props<TItem>) => {
  const { isLarge } = useScreenSize();
  const location = useLocation();
  const navigate = useNavigate();

  const handleExport = async () => {
    const instance = gridRef.current?.instance;
    const totalCount = instance?.totalCount() ?? 0;

    (totalCount > DOCLIST_PAGE_SIZE ?
      confirm(exportConfirmText(totalCount), 'Багато записів') :
      Promise.resolve(true)
    ).then(async (confirmExport) => {
      if (confirmExport) {
        exportExcel(pageName, instance);
      }
    });
  };

  const Grid = useMemo(() => (
    <DataGrid width={'100%'}
      ref={gridRef}
      onCellHoverChanged={onCellHoverChanged}
      id={location.pathname}
      className='width-100'
      height={'100%'}
      dataSource={dataSource}
      onDataErrorOccurred={() => {
        showError(messages.DATA_LOAD_FAILED);
        setTimeout(() => navigate('/home'), 5000);
      }}
      onRowDblClick={({data}:RowDblClickEvent) => {
        if (data?.ref && props?.onEdit ) props.onEdit(data.ref, data);
      }}
      columnResizingMode='nextColumn'
      dateSerializationFormat={DX_DATE_SERIALIZATION_FORMAT}
      highlightChanges
      allowColumnReordering
      allowColumnResizing
      showBorders
      remoteOperations
      columnAutoWidth
      columnChooser={{ enabled: false, mode: 'select', height: 350 }}
      customizeColumns={(columns) => {
        if (isLarge) {
          columns.forEach((column) => {
            column.hidingPriority = undefined;
          });
        }
      }}
      onContentReady= {(e) => {
       // @ts-ignore
       const columnChooserView = gridRef?.current?.instance.getView('columnChooserView');
        if (!columnChooserView?._popupContainer) {
          columnChooserView?._initializePopupContainer();
          columnChooserView?.render();
        }
        columnChooserView?._popupContainer?.option('position',
          { of: e.element,
            my: 'right top',
            at: 'right top',
            offset: '0 -30',
          });

      }
     }
      {...props}
    >
      {!!headerFilter && <HeaderFilter visible={headerFilter.visible} />}
      <StateStoring
        enabled={stateStoringEnabled}
        type='localStorage'
        storageKey={location.pathname}
        // ignoreColumnOptionNames={[]}
      />
      <Selection mode='single' />
      <Scrolling mode='virtual' rowRenderingMode='virtual' />
      <Paging pageSize={DOCLIST_PAGE_SIZE} />
      <FilterRow visible={showFilter} {...uaFilterRowText} />
      <Sorting mode={sorting ? 'single': 'none'}/>

        <Column
          type='buttons'
          width={COLUMN_EDIT_WIDTH}
          allowResizing={true}
          allowHiding={false}
        >
      {!!props?.onEdit &&
          <CButton
            name='_edit'
            icon='edit'
            onClick={({row}:{row:any}) => {
              if (row?.key) props.onEdit!(row.key, row.data);
            }}
          />
        }
        {!!props?.onDelete &&
          <CButton
            name='_delete'
            icon='trash'
            onClick={({row}:{row:any}) => row?.key && props.onDelete!(row.key)}
          />
        }
        </Column>

      {'docNumber' in columnsDataFields &&
        <Column
          dataField={columnsDataFields.docNumber}
          caption='Номер'
          dataType='string'
          alignment='center'
          allowHiding={false}
          allowResizing={false}
          width={COLUMN_DOCNUMBER_WIDTH}
          editorOptions={{
            minSearchLength:3,
            searchTimeout:500
          }}
        />
      }
      {'date' in columnsDataFields &&
        <Column
          dataField={columnsDataFields.date}
          caption='Дата'
          dataType='date'
          format={DX_DATETIME_DISPLAY_FORMAT}
          alignment='center'
          width={COLUMN_DATE_WIDTH}
        />
      }
      {'partner' in columnsDataFields &&
        <Column
          allowSorting={false}
          allowFiltering={true}
          dataField={columnsDataFields.partner}
          caption='Контрагент'
          dataType='string'
          alignment='left'
          allowResizing={true}
          calculateDisplayValue={(data: { partner: { name: any; }; }) => data.partner?.name}
          width={COLUMN_PARTNER_WIDTH}
          minWidth={COLUMN_PARTNER_MINWIDTH}
        >
          <Lookup
            dataSource={dsPartners}
            allowClearing={true}
            valueExpr='ref'
            displayExpr='name'
            // minSearchLength={3}
            // searchTimeout={500}
          />
        </Column>
      }

      {props.children}

      {'note' in columnsDataFields &&
        <Column
          allowSorting={false}
          dataField={columnsDataFields.note}
          dataType='string'
          caption='Коментар'
          alignment='left'
          hidingPriority={0}
        />
      }
      <RemoteOperations filtering={remoteFiltering === true}></RemoteOperations>
    </DataGrid>
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [ isLarge, remoteFiltering, ...deps]);

  return (
    <div>
      <StickyBar>
        <div className='otk-info-bar'>
          {pageName}
          <div className={styles.headerBarContainer}>
            {props.headerBarComponent}
          </div>
        </div>
        <div className={styles.menu}>
          {props.menuComponent}

          <Menu
            onItemClick={(e:ItemInfo) => {
              if (e.itemData?.id === 'filter') {
                gridRef.current?.instance.clearFilter();
              } else if (e.itemData?.id === 'export') {
                handleExport();
              }
            }}
            dataSource={[
              {
                id: 'filter',
                text: 'Зняти всі фільтри',
                icon: '/img/pngicons/clear.png',
              },
              {
                id: 'export',
                text: 'Зберегти в Excel',
                icon: '/img/pngicons/excell.png',
              },
            ] as MenuItem[]} 

            itemRender={renderMenuItem}
          />
          <div className={styles.endbuttons}>
            <Button
              icon='fields'
              onClick={() => gridRef?.current?.instance.showColumnChooser()}
            ></Button>
          </div>
        </div>

      </StickyBar>

      <div className='otk-datagrid-fullpage'>
      {props.optionsComponent}
      {Grid}
      </div>      
    </div>
  );
};
