import {
    Body,
    Column,
    Container,
    Head,
    Heading,
    Html,
    Img,
    Preview,
    Row,
    Text,
  } from "@react-email/components";
  import * as React from "react";
  
  interface NeedHelpEmailProps {
    labNumber: number;
    userName: string;
    categoryName: string,
    formData: {
      category: string;
      phone: string;
      chanel: {
        phone: boolean;
        telegram: boolean;
        viber: boolean;
      };
      description: string;
    };
  }
  
  const baseUrl = process.env.REACT_APP_PUB_URL ? `${process.env.REACT_APP_PUB_URL}` : ""; 
  export const NeedHelpEmail = ({ labNumber, userName, categoryName, formData }: NeedHelpEmailProps) => {
  
      return(
    <Html lang="ua">
      <Head />
      <Preview>Лабораторія потребує допомоги</Preview>
      <Body style={main}>
        <Container style={container}>
          <Heading style={h1}>Лабораторія потребує допомоги</Heading>
  
          <Row>
            <span style={h4}>Звернення лабораторії {labNumber}</span>
          </Row>
  
          <Row style={{ ...row }}>
            <Column width={"25%"}>
              <Text style={{ ...text }}>
                <span> Користувач</span>
              </Text>
            </Column>
            <Column>
              <Text style={{ ...text, ...param }}>
                <span>{userName}</span>
              </Text>
            </Column>
          </Row>
  
          <Row style={{ ...row }}>
            <Column width={"25%"}>
              <Text style={{ ...text }}>
                <span>Категорія</span>
              </Text>
            </Column>
            <Column>
              <Text style={{ ...text, ...param }}>
                <span>{categoryName}</span>
              </Text>
            </Column>
          </Row>
  
          <Row style={{ ...row }}>
            <Column width={"25%"}>
              <Text style={{ ...text }}>
                <span>Телефон</span>
              </Text>
            </Column>
            <Column>
              <Text style={{ ...text, ...param }}>
                <span>{formData.phone}</span>
              </Text>
            </Column>
          </Row>
  
          <Row style={{ ...row }}>
            <Column width={"25%"}>
              <Text style={{ ...text }}>
                <span>Канал </span>
              </Text>
            </Column>
            <Column>
              <div style={{ display: "flex", columnGap: 25 }}>
              { formData.chanel.phone && 
                    <Img
                      width="28"
                      height="28"
                      src={`${baseUrl}/img/png/operator.png`}
                      alt=""
                    />
                  }
                  {formData.chanel.telegram && 
                    <Img
                      width="28"
                      height="28"
                      src={`${baseUrl}/img/png/telegram.png`}
                      alt=""
                    />
                  }
                  {formData.chanel.viber && 
                    <Img
                      width="28"
                      height="28"
                      src={`${baseUrl}/img/png/viber.png`}
                      alt=""
                    />
                  }
              </div>
            </Column>
          </Row>
  
          <div style={{ ...row, paddingTop: "10px" }}>
            <code style={descr}>{formData.description}</code>
          </div>
        </Container>
      </Body>
    </Html>
  )};
  
  NeedHelpEmail.PreviewProps = {
    labNumber: 999,
    userName: "testUser",
    formData: {
      category: "Категорія звернення",
      phone: "380676713686",
      chanel: {
        phone: true,
        telegram: true,
        viber: true,
      },
      description: "Повторна тестова відправка повідомлення. Лена, тобі щось добавити в такому листі?",
    },
  } as NeedHelpEmailProps;
  
  export default NeedHelpEmail;
  
  const main = {
    backgroundColor: "#ffffff",
  };
  
  const container = {
    paddingLeft: "12px",
    paddingRight: "12px",
    margin: "0 auto",
  };
  
  const h1 = {
    color: "#333",
    fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    fontSize: "24px",
    fontWeight: "bold",
    margin: "20px 0",
    padding: "0",
  };
  
  const h4 = {
    color: "#333",
    fontFamily:"-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    fontSize: "16px",
    fontWeight: "bold",
    margin: "20px 0",
    padding: "0",
  };
  
  const row = {
    margin: "0px 0",
    lineHeight: "22px",
  };
  
  const text = {
    color: "#333",
    fontFamily:"-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    fontSize: "14px",
    margin: "0px 0",
  };
  
  const param = {
    fontWeight: "bold",
  };
  
  const descr = {
    fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue'",
    display: "inline-block",
    padding: "16px 4.5%",
    width: "90.5%",
    backgroundColor: "#f4f4f4",
    borderRadius: "5px",
    border: "1px solid #eee",
    color: "#333",
  };
  