import { LabeledField } from "components";
import { DataGrid } from "devextreme-react";
import styles from './clients.module.scss';
import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from "react";
import { TextBox } from 'devextreme-react/text-box';
import { Controller, useForm } from "react-hook-form";
import { IPartner } from "types/datatypes/IPartner";
import { gql } from "apollo";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Column, Editing } from "devextreme-react/data-grid";
import { InitNewRowEvent } from "devextreme/ui/data_grid";
import dayjs from "dayjs";
import { locale } from "devextreme/localization";

locale('uk');

export type ClientInfoHandle = {
  setPartnerRef: (partnerRef: string) => void,
}

interface ClientInfoProps {
  height?: string;
}

const inputAttr = { style: 'color:black; background:white' };

const dsGetPartners = gql(`query CRMGetPartners($ref: String!) {
  partners(ref: $ref) { ref name edrpou id parent is_buyer is_supplier legal_address note name_full individual_legal inn isCorporate phones _data }
  partner_ext(ref: $ref)
}`);

const mutateNoteQuery = gql(`mutation mutateNote($input: JSONObject!, $mode:String!) { updatePartnerExtensions(input: $input, mode:$mode) }`);


interface INote {
    date: string;
    note: string;
}

interface CRMPartner extends Partial<IPartner> {
}

export const ClientInfo = forwardRef((props: ClientInfoProps, ref) => {
  const [fetchPartner]  = useLazyQuery(dsGetPartners);
  const [ mutateNote ] = useMutation(mutateNoteQuery)
  const [partner, setPartner] = useState<CRMPartner | undefined>()
  const [partner_ext, setPartnerExt] = useState<INote[] | undefined>()
  const noteGridRef = useRef<DataGrid>(null)

  const updatePartnerExt = useCallback((note: INote, mode:'i'|'u'|'r')=>{
    mutateNote({variables:{
      input:{
        partner: partner?.ref,
        note: { ...note }
        },
      mode
    }})
  },[mutateNote, partner?.ref])

  const {
    control,
    // setValue,
    // getValues,
    // reset,
    handleSubmit,
    formState: { errors,
      //  isValid
       },
  } = useForm<CRMPartner>({
    reValidateMode: 'onChange',
    mode: 'all',
    values: partner,
    shouldFocusError: false,
  });

  useImperativeHandle(ref, () => {
    return {
      setPartnerRef,
    };
  });

  const setPartnerRef = (newPartner: string) => {
    fetchPartner({variables:{
      ref: newPartner
    }}).then(({data}) =>{
      setPartner((data?.partners?.[0] ?? {}) as CRMPartner)
      setPartnerExt(data?.partner_ext?.[0].notes ?? [])
      // setPartner(data)
    })
  };
  const onSubmit = () => { }

  return (
    <div style={{ height: props?.height }}>
      <form style={{ display: 'grid', gap: '5px', paddingTop: '10px' }} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.col2}>
          <Controller
            name='phones'
            control={control}
            render={({ field }) => {
              return (
                <LabeledField label={'Телефони'} field={field} errors={errors} grow={0}>
                  <TextBox readOnly={false} inputAttr={inputAttr} />
                </LabeledField>
              );
            }}
          />
          <Controller
            name='id'
            control={control}
            // rules={{ required: 'Логін обовязковий' }}
            render={({ field }) => {
              return (
                <LabeledField label={'Код'} field={field} errors={errors} grow={0}>
                  <TextBox readOnly={true} inputAttr={inputAttr} />
                </LabeledField>
              );
            }}
          />
        </div>
        <Controller
          name='name_full'
          control={control}
          // rules={{ required: 'Логін обовязковий' }}
          render={({ field }) => {
            return (
              <LabeledField label={'Повна назва'} field={field} errors={errors} >
                <TextBox readOnly={false} inputAttr={inputAttr} />
              </LabeledField>
            );
          }}
        />
        <DataGrid
        ref={noteGridRef}
        key={'date'}
        noDataText="додайте першу нотатку"
        wordWrapEnabled={true}
        showColumnHeaders={false}
          dataSource={partner_ext}
          height={'200px'}
           toolbar={{
            items:[{
              name:"custom",
              locateInMenu:"auto",
              location:"before",
              text:'Нотатки'
            },{
              name:"addRowButton",
              locateInMenu:"auto",
              location:"after",
            }]
           }}
           onInitNewRow={({data}:InitNewRowEvent)=>{
              data.date = dayjs().toISOString();
              data.note=''
            setTimeout(function(){
              // data.date = dayjs().format('DD-MM-YYYY');
              // data.note=''
            }, 100)
          }} 
          onRowUpdated={(e)=>{updatePartnerExt(e.data, 'u')}}
          onRowInserted={(e)=>{updatePartnerExt(e.data, 'i')}}
          onRowRemoved={(e)=>{updatePartnerExt(e.data, 'r')}}
         >
          <Editing mode='cell' allowAdding={true}  allowUpdating={true} allowDeleting={true}
           texts={{
            confirmDeleteMessage: 'Вилучити нотатку?'
           }}/>
          <Column dataField='date'
            calculateCellValue={(e:any)=>(dayjs(e.date).format('DD.MM.YYYY'))}
            allowEditing={false}
            width={95}
            sortOrder={'desc'} sortIndex={0}/>
          <Column dataField='note' />
         </DataGrid>
      </form>
    </div>
  )
})