import dayjs from 'dayjs';
import { showError } from 'utils/notify';
import { messages } from 'messages';
import { loader } from 'graphql.macro';
import { documents_text, priviliges_text } from './fields';
import { ApolloClient } from '@apollo/client';
import { IInsurancePolicy, IPaymentInfo, payType } from 'types/datatypes/IInsurance';
const getAssurance = loader('../gql/getAssurance.graphql');
const checkEasyPayPaymentInfo = loader('../gql/getPaymentInfo.graphql');



interface IGetPolicyProps {
  ref: string;
  gqlClient:ApolloClient<object>
}

export const getPolicy = async ({ ref, gqlClient }:IGetPolicyProps) => {
  let result:IInsurancePolicy|undefined;
  await gqlClient
    .query({
      query: getAssurance,
      variables: { ref },
    })
    .then((response) => {
      const res = response?.data?.getAssurance?.[0];
      if (res) {
        const body: IInsurancePolicy = res.body;
        result = {
          ...res.body,
          payed_type: payType?.[body?.paymentType as keyof typeof payType] || '',
          type_of_insured_person: body?.type_of_insured_person || 'Фіз. особа',
          privilege: `${priviliges_text[body?.privilege] || ''}`,
          policy_number: body?.policy_reserve?.policy_number,
          start_date: body?.start_date || body?.policy_start_date,
          end_date:
            body?.policy_confirm?.end_date
              ? dayjs(body?.policy_confirm?.end_date).format('YYYY-MM-DD') : '',
          selectedFranchise: body?.companie?.selectedFranchise,
          selectedPrice: `${body?.companie?.selectedPrice} грн.`,
          companie_name: body?.companie?.name,
          full_name:
            `${body?.lastname} ${body?.firstname} ${body?.middlename || ''}`,
          document_full_name:
            `${documents_text?.[body?.document_type] || ''}
             ${body?.document_series || ''}
             ${body?.document_number || ''} ${body?.document_record || ''}`,
        };
      } else {
        showError(messages?.DATA_LOAD_FAILED);
      }
    })
    .catch(() => {
      showError(messages?.DATA_LOAD_FAILED);
    });
  return result;
};

export const getPayment = async ({ ref, gqlClient }:IGetPolicyProps) => gqlClient
  .query({
    query: checkEasyPayPaymentInfo,
    variables: { ref },
  })
  .then((response) => {
    const res = response.data.checkEasyPayPaymentInfo;
    if (res.length) return res[0].body as IPaymentInfo;
    showError(messages.DATA_LOAD_EMPTY);
    return undefined;
  }).catch(() => {
    showError(messages.DATA_LOAD_FAILED);
  });
