import React, { useRef } from 'react';
import { StickyBar, DocInfoBar, DocMenu, DocHeader } from 'components';
import { useDocState, useDate } from 'hooks';
import { v4 as uuid_v4 } from 'uuid';
import dayjs from 'dayjs';
import { gqlClient } from 'gql-client';
import { loader } from 'graphql.macro';
import {
  DataGrid,
  DateBox,
  Form,
  LoadPanel,
  TextArea,
  ValidationGroup,
} from 'devextreme-react';
import {
  ColCountByScreen,
  GroupItem,
  Label,
  SimpleItem,
} from 'devextreme-react/form';

import { FORM_STYLING_MODE, orderDocStatuses } from 'app-constants';
import { WorkersGrid } from './workersGrid';
import { custom } from 'devextreme/ui/dialog';
import { docValidationMsg } from 'messages';


import { cloneDeep } from 'lodash';
import { useAuth } from 'contexts';
const getDoc = loader('./gql/getWorkTime.gql');
const updateWorkTime = loader('./gql/updateWorkTime.graphql');
const dsGetWorkTimes = loader('../../datasources/dsWorkTimes/dsGetWorkTimes.gql');

const CLASS_NAME = 'doc.work_time';

const legend = [
  { value: 'в', caption: 'Відпустка' },
  { value: 'тн', caption: 'Лікарняний' },
  { value: 'ін', caption: 'Мобілізація' },
  { value: 'вд', caption: 'Відрядження' },
  { value: 'на', caption: 'Відпустка без збереження' },
  { value: 'нз', caption: 'Невихід з нез\'ясованих. причин' },
];

export const WorkTime = () => {
  const { options } = useAuth();
  const { formatDate, startOfMonth, startOfDay } = useDate();
  const formGroupRef = useRef();
  const workersRef = useRef();

  const [ data, setData, { loading, isDocNew } ] = useDocState({
    className: CLASS_NAME,
    defaultData: {
      date: startOfDay(),
      period_reg: startOfMonth(),
      number_doc: '',
      class_name: CLASS_NAME,
      note: '',
    },
    load: async(id) => {
      if (id==='new') return data;
      return gqlClient.query({
        query: getDoc,
        variables: { ref: id },
      })
      .then((response) => {
        const doc = response?.data?.worktime?.[0];
        if (!doc?.ref) {
          throw new Error();
        }
        return doc;
      })},
    update: (state, data) => ({ ...state, ...data }),
  });

  const isDocClosed = (startOfMonth(data.date) !==
   startOfMonth(dayjs().subtract(7, 'day'))) &&
   (startOfMonth(data.date) !== startOfMonth());

  const isClosedByPeriod = () => {
    const dataClose = options.closeByDocs.grid
      .find((r) => r.docType === 'doc.work_time')?.period;
    return dataClose && (startOfMonth(data.period_reg) < startOfDay(dataClose));
  };
  const docReadOnly = data.protected || isDocClosed || isClosedByPeriod();

  const validateForm = async () => {

    let errorMessage = '';

    // check for existed doc for period_reg
    const existedDocs = await gqlClient.query({
      query: dsGetWorkTimes,
      variables: {
        requireTotalCount: false,
        branches: [], //check only for main branch
        jfilt: [
          { fld: 'period_reg', expr: '=', val: formatDate(startOfMonth(data.period_reg)) },
          { c: 'and' },
          { fld: 'ref', expr: '<>', val: `${data.ref ?? ''}` },
        ],
      },
    }).then((response) => response?.data?.list ?? []);

    if (existedDocs.length) {
      errorMessage += docValidationMsg(`Існує документ № ${existedDocs[0].number_doc} для данного періоду`);
    }

    if (startOfMonth(data.date) < startOfMonth(data.period_reg)) {
      errorMessage += docValidationMsg('Заборона створення документу в майбутній період');
    }

    return errorMessage;
  };

  const handleDocumentSave = async () => {

    const err = await validateForm();
    if (err) {
      return Promise.reject(err);
    }

    const doctosave = cloneDeep(data);
    doctosave.date = formatDate(doctosave.date);
    doctosave.worktable = workersRef.current;
    const saveUuid = uuid_v4();
    if (isDocNew) {
      doctosave._id = `${CLASS_NAME}|${saveUuid}`;
      doctosave.class_name = CLASS_NAME;
    }

    const worktable = [];
    workersRef.current.forEach((row) => {
      const obj = {
        person_id: row.person_id,
        person_fio: row.person_fio,
        positions: row.positions,
        graphic_id: row.graphic_id,
        graphic_name: row.graphic_name,
        hours: [],
      };
      for (let i = 1; i < 32; i++) {
        if (`d_${i}` in row) {
          obj.hours.push({
            day: i,
            hours: row[`d_${i}`],
          });
        }
      }
      worktable.push(obj);
    });
    doctosave.worktable = worktable;
    doctosave.period_reg = formatDate(doctosave.period_reg);
    doctosave.hours = undefined;
    doctosave.savedByLab = true;
    return gqlClient.mutate({
      mutation: updateWorkTime,
      variables: { input: doctosave },
    });
  };

  const calculateStatus = (data) => !data?.number_doc ? orderDocStatuses.NEW: orderDocStatuses.ACCEPTED;

  const changeReq = (e) => {
    setData((prevState) => ({
      ...prevState,
      [e.element.id]: e.event?.target?.value,
    }));
  };

  const LegendRow = (rowInfo) => (
    <React.Fragment>
      <tr>
        <td style={{ padding: 2 }}>{rowInfo?.data.value}</td>
        <td style={{ padding: 2 }}>{rowInfo?.data.caption}</td>
      </tr>
    </React.Fragment>);

  const status = calculateStatus(data);

  return (
    <React.Fragment>
      <StickyBar>
        <DocInfoBar
          name='Табель робочого часу'
          data={{
            date: data.date,
            number: data.number_doc,
          }}
          loading={loading}
          isNew={isDocNew}>
          {data.protected && (
            <div className={'otk-tag otk-status-warning'}>
              <div className='dx-icon-warning'></div>
              &nbsp;Документ тільки для перегляду
            </div>
          )}
          <div className={`otk-tag otk-status-${status.status}`}>
            {status.text}
          </div>
        </DocInfoBar>

        <DocMenu
          isDocNew={isDocNew}
          allowSaving={!docReadOnly}
          onSave={handleDocumentSave}
          printItems={[
            // template for later use
            // {
            //   text: 'Рахунок',
            //   // url: `${API_HOST}/printform/${id}/inv`,
            //   disabled: !data.number_doc,
            // },
          ]}
        />
      </StickyBar>

      <LoadPanel visible={loading} />
      <ValidationGroup ref={formGroupRef}>
        <div className='content-block otk-content-block'>
          <div
            className={
              'otk-doc-container otk-doc-form otk-doc-form-large dx-card'
            }>
            <Form labelLocation='top' formData={data}>
              <GroupItem>
                <ColCountByScreen xs={1} sm={2} md={2} lg={2} />

                <GroupItem colSpan={1}>
                  <ColCountByScreen xs={1} sm={1} md={1} lg={1} />
                  <SimpleItem colSpan={1} isRequired>
                    <Label text='Період' location='left' />
                    <DateBox
                      value={data.period_reg}
                      displayFormat='monthAndYear'
                      onValueChanged={(e) => {
                        e.value = startOfMonth(e.value);
                        if (!e.event ||
                          (startOfMonth(e.previousValue) === e.value)) return;
                        if (data?.worktable?.length) {
                          const myDialog = custom({
                            showTitle: false,
                            messageHtml: `
                              <p style="text-align:center;">
                              <b>Таблиця буде очищена<br/>
                              Продовжити?
                              </b></p>`,
                            buttons: [
                              { text: 'Так', onClick: () => 1 },
                              { text: 'Ні', onClick: () => 2 },
                            ],
                          });
                          myDialog.show().then((dialogResult) => {
                            if (dialogResult === 1) {
                              setData((prev) => ({ ...prev,
                                period_reg: e.value,
                                worktable: [],
                              }));
                            } else {
                              e.value = e.previousValue;
                            }
                          });
                        } else {
                          setData((prev) => ({ ...prev,
                            period_reg: e.value,
                          }));
                        }
                      }}>
                    </DateBox>
                  </SimpleItem>
                  <GroupItem
                    colSpan={1}
                  >
                    <SimpleItem
                      render={() => (
                        <DataGrid
                          dataSource = {legend}
                          showBorders={true}
                          showColumnHeaders={false}
                          columns ={[
                            {
                              dataField: 'value',
                              width: 40,
                            },
                            {
                              dataField: 'caption',
                            },
                          ]}
                          dataRowRender={LegendRow}
                        />
                      )}
                    >
                      <Label text='Позначення (права кнопка мишки)'/>
                    </SimpleItem>
                  </GroupItem>
                </GroupItem>

                <SimpleItem>
                  <DocHeader
                    number_doc={data.number_doc}
                    date={data.date}
                    statusText={status.text}
                  />
                </SimpleItem>
              </GroupItem>
              <SimpleItem>
                <WorkersGrid data={data} gData={workersRef} legend={legend} />
              </SimpleItem>

              <GroupItem>
                <ColCountByScreen xs={1} sm={8} md={8} lg={10} />
                <SimpleItem colSpan={8}>
                  <Label text='Коментар' />
                  <TextArea
                    id='note'
                    readOnly={docReadOnly}
                    value={data.note}
                    stylingMode={FORM_STYLING_MODE}
                    hint='коментар'
                    onChange={changeReq}
                  />
                </SimpleItem>
              </GroupItem>
            </Form>
          </div>
        </div>
      </ValidationGroup>
    </React.Fragment>
  );
};
