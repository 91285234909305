import notify from 'devextreme/ui/notify';

export const showError = (message:string, displayTime = 4000) => {
  wNotify({message},  'error', displayTime);
};
export const showWarning = (message:string, displayTime = 4000) => {
  wNotify({message}, 'warning', displayTime);
};
export const showSuccess = (message:string,displayTime = 3000) => {
  wNotify({message}, 'success', displayTime);
};

interface INotifyProps {
  message:string;
  position?: {
     my?: 'left'|'center'|'right'; 
     at?: 'left'|'center'|'right';
     of?: string 
  },
  closeOnClick?:boolean,
  closeOnOutsideClick?:boolean
}
type ttype = 'error'|'warning'|'success'|'default'

export const wNotify = ({
   message,
   position = { at: 'center' },
   closeOnClick=true,
   closeOnOutsideClick=true
  }:INotifyProps, mtype:ttype='default', displayTime = 3000) => {
  notify({
    message,
    position,
    shading:false,
    closeOnClick,
    closeOnOutsideClick
   },
    mtype,
    displayTime);
}