import { DocHeader, DocInfoBar, DocMenu, StickyBar, SelectProject } from 'components';
import { Reducer, Suspense, useEffect, useReducer, useRef } from 'react';
import { useParams } from 'react-router';
import { reducer } from './orderCollectionReducer';
import { OrderCollections } from 'models/documents/orderCollections/orderCollectionModel';
import { Form, LoadPanel, SelectBox, TextArea, ValidationGroup } from 'devextreme-react';
import { ColCountByScreen, GroupItem, Label, SimpleItem } from 'devextreme-react/form.js';
import { DocContext } from 'contexts/docContext';
import { OrdersGrid } from './OrdersGrid.jsx';
import { FORM_STYLING_MODE } from 'app-constants';
import dayjs from 'dayjs';
import PaymentForm from 'components/payment-form/payment-form';
import { chain } from 'lodash';
import { showError, showSuccess } from 'utils/notify';

import { useAuth } from 'contexts';
import { useDocChangeListen, usePayKinds } from 'hooks';
import { docValidationMsg, messages } from 'messages';

import styles from '../order.module.scss';

const MAX_AMOUNT = 30_000;

interface ComponentState {
  doc: OrderCollections,
  loading:boolean,
}

const createInitState = (id:string, auth:any):ComponentState=>({
  doc: new OrderCollections(id, auth),
  loading: id !== 'new',
  
})

export const OrderCollectionsDoc = () => {
  const { id='' } = useParams();
  const auth = useAuth();
  const { payKindByName } = usePayKinds();

  const [state, dispatch] = useReducer<Reducer<ComponentState, any>>(reducer, createInitState(id,auth));

  const doc = state.doc;
  const isReadOnly = !!doc?.ext_json?.paymentId;
  const { projects } = auth;

  useDocChangeListen(
    'doc.order_collections',
    () => {
      doc.load().then(() => {
        dispatch({ type: 'UPDATE_DOC', payload: {} });
        // showWarning('Документ був оновлений з бази даних', 5000);
      });
    },
    doc
  );

  useEffect(() => {
    if (id !== 'new') {
      // dispatch({ type: 'LOADING', payload: true });
      doc
        .load(id)
        .then(() => {
          dispatch({ type: 'UPDATE_DOC', payload: {} });
          dispatch({ type: 'LOADING', payload: false });
        })
        .catch(() => {
          dispatch({ type: 'LOADING', payload: false });
        });
    }
  }, [doc, id]);

  // useTraceUpdate({auth, state, id})

  const formGroupRef = useRef<ValidationGroup>(null);
  const pFormRef = useRef<{show:()=>void}>(null);

  const handleDocumentSave = async () => {
    const err = validateForm();
    if (err) return Promise.reject(err);

    doc.to1C = true;
    doc.ext_json.saveByLab = dayjs();
    doc.note = doc?.note ?? '';

    return doc.save()
  };

  const handleProjchange = (e:string) => {
    dispatch({
      type: 'UPDATE_DOC',
      payload: {
        proj: e,
        ext_json: { ...doc.ext_json, proj: projects.idByRef(e) },
        orders: [],
      },
    });
  };

  const validateForm = () => {
    const validatorResult = formGroupRef.current?.instance.validate();
    let errorMessage = '';
    const amountTotal = doc.totalAmount;

    validatorResult?.brokenRules?.forEach((rule) => {
      errorMessage += docValidationMsg(rule.message);
    });

    // if (doc.date < closeDate) {
    //   errorMessage += docValidationMsg(' Дата документу недозволена');
    // }

    if (doc.orders.length === 0) {
      errorMessage += docValidationMsg(' Таблична частина порожня');
    }
    if (amountTotal > MAX_AMOUNT) {
      errorMessage += docValidationMsg(` Сумма документу перевищує ліміт ${MAX_AMOUNT} грн`);
    }

    return errorMessage;
  };

  const paymentData = (doc:any) =>
    doc.number_doc
      ? {
          amount: doc.totalAmount,
          description: `Oплата за послуги згідно рахунку ${doc.number_doc} у т.ч. ПДВ`,
          id: doc.number_doc,
          data: { _id: doc._id },
          proj: doc.proj,
          organization: doc.organization?.ref,
          doc,
        }
      : undefined;

  // const availablePayMethods = () => ['EasyPay'];
  const availablePayMethods = () => chain(auth.lab?.pay_systems?.filter((ps) => ps.proj === doc.proj))
    .map('resource').uniq().value() ?? [];

  return (
    <div>
      <StickyBar>
        <DocInfoBar name='Замовлення до оплати' data={doc} loading={state.loading} isNew={id === 'new'}></DocInfoBar>
        <DocMenu isDocNew={id === 'new'} allowSaving={!isReadOnly} onSave={handleDocumentSave} />
      </StickyBar>

      <ValidationGroup ref={formGroupRef}>
        <div className='content-block otk-content-block'>
          <div className='otk-doc-container otk-doc-form otk-doc-form-large dx-card'>
            <Form labelLocation='left' formData={doc}>
              <GroupItem>
                <ColCountByScreen xs={1} sm={2} md={2} lg={2} />
                <GroupItem colSpan={1}>
                  <ColCountByScreen xs={1} sm={2} md={2} lg={2} />
                  <SimpleItem colSpan={2} label={{ location: 'left' }}>
                    <Label text='Тип' />
                    <SelectProject
                      readOnly={isReadOnly}
                      availableOnly
                      value={doc.proj}
                      onValueChange={handleProjchange}
                      stylingMode={FORM_STYLING_MODE}
                    />
                  </SimpleItem>
                  <SimpleItem colSpan={2} label={{ location: 'left' }}>
                    <SelectBox
                      stylingMode={FORM_STYLING_MODE}
                      items={[payKindByName.online]}
                      displayExpr='name'
                      valueExpr='ref'
                      placeholder=''
                      labelMode='static'
                      label='Тип платежу'
                      value={payKindByName?.online?.ref}
                      buttons={[
                        { name: 'dropDown', location: 'before' },
                        {
                          name: 'pay',
                          location: 'after',
                          options: {
                            text: 'Оплатити',
                            icon: 'money',
                            stylingMode: 'text',
                            visible: !doc?.ext_json?.paymentId,
                            disabled: id === 'new',
                            onClick: () => handleDocumentSave()
                                .then(() => {
                                  showSuccess(messages.DOC_SAVED);
                                  pFormRef.current?.show();
                                }).catch(showError),
                          },
                        },
                        {
                          name: 'payed',
                          location: 'after',
                          options: {
                            text: 'Сплачено',
                            elementAttr: { class: styles.payedButton },
                            icon: 'check',
                            stylingMode: 'text',
                            visible: !!doc?.ext_json?.paymentId,
                          },
                        },
                      ]}
                    />
                  </SimpleItem>
                </GroupItem>
                <GroupItem>
                  <SimpleItem>
                    <DocHeader number_doc={doc.number_doc} date={doc.date.toISOString()} />
                  </SimpleItem>
                </GroupItem>
              </GroupItem>
              <SimpleItem>
                <DocContext.Provider value={{ doc, state }}>
                  <OrdersGrid />
                </DocContext.Provider>
              </SimpleItem>

              <GroupItem>
                <ColCountByScreen xs={1} sm={8} md={8} lg={10} />
                <SimpleItem colSpan={8}>
                  <Label text='Коментар' />
                  <TextArea
                    id='note'
                    readOnly={doc.isReadOnly}
                    value={doc?.note}
                    stylingMode={FORM_STYLING_MODE}
                    hint='коментар'
                    onChange={(e) => dispatch({ type: 'UPDATE_DOC', payload: { note: e.event?.target.textContent ?? '' } })}
                  />
                </SimpleItem>
              </GroupItem>
            </Form>
          </div>
        </div>
      </ValidationGroup>
      <Suspense fallback={<LoadPanel visible={true} />}>
        <PaymentForm
          ref={pFormRef}
          paymentData={paymentData(doc)}
          description={`зведене замовлення послуг`}
          visible={false}
          availableMethods={availablePayMethods()}
        />
      </Suspense>
      <LoadPanel visible={state.loading} />
    </div>
  );
};
