import { useNavigate } from 'react-router-dom';
import { DocInfoBar, StickyBar } from 'components';
import { Menu } from 'devextreme-react/menu';
import { ImportCars } from './import-cars';
import { ItemInfo, MenuItem } from 'types/otkTypes';

export const ImportCarsPage = () => {
  const navigate = useNavigate();
  return (
    <div className='dx-card'>
      <StickyBar>
        <DocInfoBar name='Імпорт даних авто'/>
        <Menu
          onItemClick={({itemData}:ItemInfo) => {
            if (itemData?.id === 'close') navigate('/home');
          }}
          dataSource={[ { id: 'close', icon: 'close', text: 'Закрити' } ] as MenuItem[]}
        />
      </StickyBar>
      <ImportCars />
    </div>
  );
};
export default ImportCarsPage;
