
import { LoadOptions } from "devextreme/data";
import CustomStore, { Options } from "devextreme/data/custom_store";
import { ApolloAnswer, TDataBaseObjectClasseNames } from "types/otkTypes";

export interface IDocProps {
  _id?: string; // "doc.blankorder|ba22031d-b07e-4f2c-b6a6-14a651f4ec01",
  _rev?: string; // "3-4c27722715d44f2c23b52ca454f9032a",
  ref?: string;
  date: string; // "2024-11-01T16:11:47",
  number_doc?: string; // "ТЕХ00000159",
  organization?: string; //"206b71b2-ffb1-11e8-80fd-00155dcccf0a",
  department?: string;//"df42448b-b393-11e9-8107-00155dcccf0a",
  note?: string; // "Довіреність на Сидоряк М.І.",
  posted?: boolean;
  class_name: TDataBaseObjectClasseNames;

};

export class CustomStoreExt<TItem=any, TKey=any> extends CustomStore<TItem, TKey> {
    private _userData?: any
    userOptions: {
      [key: string]: any
    };
  
    constructor(options?:Options<TItem, TKey>) {
      super(options);
      this.userOptions = {}
    }

    set userData (userData:any) {
        this._userData = userData;
    }
    get userData ():any {
        return this._userData;
    }

    filter (value?: object) {
      this.userOptions.hardFilter = value;
    }


    async loadExt(options?:LoadOptions<TItem>): Promise<ApolloAnswer<TItem>>{
      //@ts-ignore
      const superData:ApolloAnswer<TItem> = options ? (await super.load(options)) : await super.load();
      const { data, totalCount} = superData;
      return {
        data,
        totalCount
      }

    };

    // load(options:LoadOptions<TItem>): Promise<DxExtendedPromise<TItem[]>|ApolloAnswer<TItem[]>>{

    // };

  }