import React, { useRef, useState } from 'react';
import { Column } from 'devextreme-react/data-grid';
import { Menu } from 'devextreme-react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import { API_HOST } from 'app-constants';
import { openDoc } from 'utils/open-doc';
import { useDocChangeListener } from 'hooks';
import {
  DoclistPageTemplate,
} from 'components';
import { dsWorkTimes } from 'datasources';


export const WorkTimeList = () => {
  const [ selectedRowData, setSelectedRowData ] = useState({});
  const navigate = useNavigate();
  const gridRef = useRef();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const showNewOrders = !!params.get('show_new');
  useDocChangeListener('doc.work_time', () => {
    gridRef.current.instance.refresh(true);
  });

  return (
    <DoclistPageTemplate
      gridRef={gridRef}
      pageName='Табелі робочого часу'
      onEdit={(ref) => navigate(`/work_time/${ref}`)}
      dataSource={dsWorkTimes}
      columnsDataFields={{
        date: 'date',
        docNumber: 'number_doc',
        note: 'note',
      }
      }
      onRowPrepared={(e) => {
        if (e.data?.protected && e.rowType === 'data') {
          e.rowElement.classList.add('otk-row-status-warning');
        }
      }}
      onSelectionChanged={(e) => {
        setSelectedRowData(e.selectedRowsData[0] || {});
      }}
      stateStoringEnabled={!showNewOrders}
      menuComponent={(
        <Menu
          onItemClick={(e) => {
            switch (e.itemData.id) {
            case 'new': {
              navigate('/work_time/new');
              break;
            }
            case 'print': {
              openDoc(e.itemData.url);
              break;
            }
            default:
            }
          }}
          dataSource={[
            {
              text: 'Додати',
              icon: '/img/pngicons/plus.png',
              id: 'new',
            },
            {
              text: 'Друк',
              icon: '/img/pngicons/print.png',
              disabled: !(selectedRowData.ref && selectedRowData.number_doc),
              items: [
                {
                  id: 'print',
                  text: 'Рахунок',
                  url: `${API_HOST}/printform/${selectedRowData.ref}/inv`,
                },
                {
                  id: 'print',
                  text: 'Договір',
                  url: `${API_HOST}/printform/${selectedRowData.ref}/dog`,
                },
                {
                  id: 'print',
                  text: 'Договір сертифікації',
                  url: `${API_HOST}/printform/${selectedRowData.ref}/dogs`,
                },
                {
                  id: 'print',
                  text: 'Договір для Казначейства',
                  url: `${API_HOST}/printform/${selectedRowData.ref}/dogk`,
                },
              ],
            },
          ]}
        />
      )}
    >

      <Column
        cssClass='otk-cell-no-padding'
        dataField='period_reg'
        dataType='date'
        caption='Період'
        alignment='center'
        format = 'monthAndYear'
        hidingPriority={1}
        width={130}
      />
    </DoclistPageTemplate>
  );
};
