import { forwardRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';

import { COLUMN_PARTNER_MINWIDTH, DX_DATETIME_DISPLAY_FORMAT, uaFilterRowText } from 'app-constants';
import { SearchDropdown, forwardProps } from 'components/search-dropdown';
import { dsPartners, dsOrders } from 'datasources';
import { DataGrid, Menu } from 'devextreme-react';
import { Column, FilterRow, Lookup, Paging, Scrolling, Selection } from 'devextreme-react/data-grid';
import { useSearchDatagridHandler } from 'hooks';

const OrdersDatagrid = forwardRef(
  ({
      searchField = 'number_doc',
      filterData,
      dataSource: propsDataSource,
      onSelectionChanged: propsOnSelectionChanged,
      value,
    },
    ref
  ) => {
    const navigate = useNavigate();

    const [currentRowData, setCurrentRowData] = useState(null);
    const [byPartner, setByPartner] = useState(!!filterData?.partnerRef);
    const [dataSource] = useState(propsDataSource ?? dsOrders);

    if (byPartner) {
      dataSource?.filter(['partner', '=', filterData?.partnerRef]);
    } else {
      dataSource?.filter();
    }

    useEffect(() => {
      setByPartner(!!filterData?.partnerRef);
    }, [filterData?.partnerRef]);

    //on close component clear global filter
    useEffect(
      () => () => {
        dataSource.userData = undefined;
        dataSource.filter();
      },
      [dataSource]
    );

    const _onSelectionChanged = (row) => {
       dataSource.byKey(row.ref).then(propsOnSelectionChanged);
    };

    const { gridRef, focusElementRef, dataReady, onCurrentRowChange } = useSearchDatagridHandler(
      ref,
      searchField,
      _onSelectionChanged
    );

    useEffect(() => {
      dataSource.userData = { globalSearch: !!byPartner };
      gridRef.current.instance.refresh();
    }, [byPartner, dataSource, gridRef]);

    const clickMenu = ({ itemData: { id } }) => {
      if (id === 'select') {
        _onSelectionChanged(currentRowData);
      } else if (id === 'open') {
        navigate(`/order/${value.ref}`);
      } else if (id === 'byPartner') {
        setByPartner(!byPartner);
      }
    };

    return (
      <div ref={focusElementRef} style={{ display: 'block', width: '100%', height: '100%' }}>
        <Menu
          onItemClick={clickMenu}
          activeStateEnabled={false}
          dataSource={[
            {
              text: 'Вибрати',
              id: 'select',
              icon: 'check',
              visible: !!propsOnSelectionChanged,
              disabled: !currentRowData,
            },
            {
              text: 'Відкрити',
              id: 'open',
              icon: 'find',
              disabled: !value?.ref,
            },
            {
              text: byPartner ? 'По контрагенту' : 'Всі',
              id: 'byPartner',
              icon: 'preferences',
              selected: byPartner,
              visible: !!filterData?.partnerRef,
            },
          ]}
        />
        <DataGrid
          ref={gridRef}
          showBorders
          remoteOperations
          dataSource={dataReady ? dataSource : []}
          hoverStateEnabled={true}
          onRowDblClick={(e) => {
            _onSelectionChanged(e.data);
          }}
          onSelectionChanged={(e) => {
            if (e.selectedRowsData.length > 0) {
              setCurrentRowData(e.selectedRowsData[0]);
              onCurrentRowChange(e.selectedRowsData[0]);
            }
          }}
          height='90%'
        >
          <Selection mode='single' />
          <Scrolling mode='virtual' rowRenderingMode='virtual' />
          <Paging enabled={true} pageSize={100} />
          <FilterRow visible={true} {...uaFilterRowText} />

          <Column dataField='number_doc' caption='Номер' alignment='center' width={150} />

          <Column
            dataField='date'
            caption='Дата'
            dataType='date'
            format={DX_DATETIME_DISPLAY_FORMAT}
            alignment='center'
            width={190}
          />

          <Column
            allowSorting={false}
            dataField='partner.ref'
            caption='Контрагент'
            dataType='string'
            alignment='left'
            allowResizing={false}
            calculateDisplayValue={(data) => data.partner?.name}
            minWidth={COLUMN_PARTNER_MINWIDTH}
            allowFiltering={!byPartner}
          >
            <Lookup
              dataSource={dsPartners}
              allowClearing={true}
              valueExpr='ref'
              displayExpr='name'
              minSearchLength={3}
              searchTimeout={500}
            />
          </Column>
        </DataGrid>
      </div>
    );
  }
);

OrdersDatagrid.displayName = 'OrdersDataGrid';

OrdersDatagrid.propTypes = {
  value: PropTypes.shape({
    ref: PropTypes.string.isRequired,
    caption: PropTypes.string.isRequired,
  }),
  searchField: PropTypes.string,
  onSelectionChanged: PropTypes.func,
  onPartnerClose: PropTypes.func,
  filterData: PropTypes.object,
  dataSource: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export const OrdersSearch = (props) => {
  return (
    <SearchDropdown
      {...props}
      value={props.order || { ref: '', caption: '' }}
      text={props.order?.caption || ''}
      onSelect={props.onSelect}
      minSearchLength={3}
      searchTimeout={500}
      placeholder='Замовлення...'
      saveSearchValueOnLeave={true}
      width={'100%'}
      popupMode='popup'
      dropdownWidth={700}
      dropdownHeight={500}
      onOpenDocButton={props?.onOpenDocButton}
    >
      <OrdersDatagrid dataSource={props.dataSource} value={props?.value} filterData={props.filterData} />
    </SearchDropdown>
  );
};

OrdersSearch.propTypes = {
  ...forwardProps,
  order: PropTypes.shape({
    ref: PropTypes.string.isRequired,
    caption: PropTypes.string.isRequired,
  }),
  filterData: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
  onOpenDocButton: PropTypes.func,
};
