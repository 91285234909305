import React, { useState, useEffect, useCallback, Dispatch } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSubscription } from '@apollo/client';
import { gqlClient } from 'gql-client.js';
import { savePolicyData } from './utils';
import { getPayment } from '../card-policy/getPolicy';
import { IInsurancePolicy, IPolicyEasyPayment, payType, TPaymentType } from 'types/datatypes/IInsurance';
import dayjs from 'dayjs';

//components
import { FormField } from 'components/form-field/form-field';
import { TextLine } from 'pages/ep-main/components/text-line';
import { LoadIndicator, Button, RadioGroup } from 'devextreme-react';

//payment forms
import { CardPaymentForm } from './payment_forms/card';
import { QRCodePaymentForm } from './payment_forms/qr_code';
import { TerminalPaymentForm } from './payment_forms/terminal';
import ByLinkPaymentForm from './payment_forms/ByLink';
import { CashlessPaymentForm } from './payment_forms/cashless';

import styles from '../styles/style.module.scss';
import { EASYPAY_CALLBACK } from '../gql/gqlQueries';
import { useTraceUpdate } from 'hooks';

const dataSource = Object.keys(payType).map(key => ({ title: payType[key as keyof typeof payType], value: key }));

//hook detect After init render
const useDidMount = () => {
  const [didMount, setDidMount] = useState(false);
  useEffect(() => setDidMount(true), []);
  return didMount;
};

interface Part4Props {
  setData: Dispatch<React.SetStateAction<IInsurancePolicy>>;
  data: IInsurancePolicy
};



export const Part4 = (props: Part4Props) => {
  const navigate = useNavigate();
  const { setData, data } = props;
  const [loading, setLoading] = useState(false);

  const didMount = useDidMount();

 useTraceUpdate({dat:data.policy_cahless_payment})
  const subscription = useSubscription(EASYPAY_CALLBACK, { variables: { orderId: data?.ref || '5' } });

  //збереження данив в базі
  const handleSavePolicy = useCallback(async () => {
    setLoading(true);
    savePolicy(data)
      .finally(() => setLoading(false));
  }, [ data ]);

  const handleOrderChange = useCallback( (cashlessPayment:any)=>{
      console.log(cashlessPayment);
      setData((prev)=>({
        ...prev,
        policy_cashless_payment:cashlessPayment,
      }));
      savePolicy({...data, policy_cashless_payment:cashlessPayment})
  } ,[setData, data])

  const savePolicy = async (doc:IInsurancePolicy) => {
    return savePolicyData({ gqlClient, data: doc })
  }

  //перевірка даних про оплату
  const handleGetPaymentInfo = () => {
    getPayment({ ref: data.ref!, gqlClient })
      .then(async (res) => {
        if (!res) return;
        setData((prev) => ({
          ...prev,
          policy_easyPay: res,
        }));
      });
  };

  useEffect(() => {
    if (subscription.data?.extCallBack && !subscription.loading) {
      setData((prev) => ({
        ...prev,
        policy_easyPay: subscription.data?.extCallBack,
      }));
    }
  }, [setData, subscription.data, subscription.loading]);

  //+++ 2023-05-17 фунціонал перенесено на сторону back-end
  //відправляєм конфірм проплати коли змнились дані для EasyPay callback

  useEffect(() => {
    if (didMount) handleSavePolicy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.policy_confirm?.id, data.payment_type]);

  const handleSetPaymentType = (value: TPaymentType) => {
    setData((prev) => ({ ...prev, payment_type: value }));
  };

  useEffect(() => {
    if (!didMount) setData((prev) => ({ ...prev, payment_type: data?.payment_type ?? 'card' }));
  }, [data, didMount, setData]);

  return (<>
    <h3>Крок 4. Оплата</h3>
    <TextLine />
    <FormField textWidth={'220px'} text={'Зарезервовано: '} fluid={true}>
      <div className={styles.formElementDiv}>
        <div style={{ width: '100%' }}>
          <FormField textWidth={'150px'} text={'Дата/час:'}>
            {dayjs(data?.policy_reserve?.policy_created_at).format('DD.MM.YYYY HH:mm:ss')}
          </FormField>
          <FormField textWidth={'150px'} text={'Статус:'}>
            {data?.policy_reserve?.policy_status}
          </FormField>
          <FormField textWidth={'150px'} text={'Номер полісу:'}>
            {data?.policy_reserve?.policy_number}
          </FormField>
        </div>
        {/* посилання на поліс в базі МТСБУ */}
        <div className={styles.buttonsdiv}>
          <Link to={data?.policy_reserve?.policy_direct_link ?? ''} target='_blank' rel="noreferrer">
            <img width='180' alt=''
              className={styles.image_button}
              src='https://mtsbu.ua/themes/mtsbu/assets/images/logo.png'
            />
          </Link>
          <br />
          {/* оплата на сайті easypay */}
          {data.paymentType===payType.card && (data?.policy_payment as IPolicyEasyPayment)?.forwardUrl &&
            <Link to={data?.policy_payment?.forwardUrl??'#'} rel="noreferrer" target='_blank'>
              <img width='180' height='64' alt=''
                className={styles.image_button}
                src='https://cdn.easypay.ua/images/svg/logo.svg'
              />
            </Link>}
        </div>
      </div>
    </FormField>
    <TextLine />
    <FormField textWidth={'220px'} text={'Тип оплати: '}>
      <RadioGroup
        dataSource={dataSource}
        layout="horizontal"
        onValueChanged={(e) => handleSetPaymentType(e?.value)}
        displayExpr="title"
        valueExpr="value"
        value={data.payment_type}
      />
    </FormField>
    <TextLine />
    {/*форми проплати відображаються залежно від обраного типу
    усі в папці ./payment_forms*/}
    {data?.payment_type === 'card' && <CardPaymentForm data={data} />}
    {data?.payment_type === 'qr' && <QRCodePaymentForm data={data} />}
    {data?.payment_type === 'terminal' && <TerminalPaymentForm data={data} />}
    {data?.payment_type === 'link' && <ByLinkPaymentForm data={data} />}
    {data?.payment_type === 'cashless' && <CashlessPaymentForm data={data} updatePayment={handleOrderChange}/>}

    <TextLine />
    <FormField textWidth={'220px'} text={' '}>
      <div className={styles?.res_title}>
        <Button className={styles?.mr_40}
          onClick={() => setData((prev) => ({ ...prev, step: 3 }))}
          text='< Назад' />
        <Button onClick={handleSavePolicy}
          disabled={loading}
          className={styles?.mr_60}
          text='Зберегти дані' />
        {data?.policy_confirm?.policy_status === 'confirmed' &&
          <Button onClick={() => navigate(`/policy/${data?.ref}`)}
            disabled={loading} className={styles?.mr_60}
            text='Перейди до полісу' />
        }
        {data?.policy_confirm?.policy_status !== 'confirmed' &&
          <Button className={styles?.mr_60}
            onClick={handleGetPaymentInfo}
            disabled={loading}
            type='danger'
            text='Перевірити оплату' />
        }
        {loading && <LoadIndicator className={styles?.res_loader} indicatorSrc='img/loader.gif' width={50} height={50} />}
      </div>
    </FormField>
  </>);
};

export default Part4;
