import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './optionsBar.module.scss';
import SelectBox from 'devextreme-react/ui/select-box';
import { Button, CheckBox, LoadPanel } from 'devextreme-react';
import { useVehicleTypesList } from 'pages/insurance-policy/hooks';
import { getCompaniesOffers } from 'pages/insurance-policy/api/requests';
import { checkVehicleTypeCDE } from 'pages/insurance-policy/new-policy/utils';
import dayjs from 'dayjs';
import { cities } from './citiesData';
import { useCallback } from 'react';
import {groupBy} from 'lodash';

const calcNextServiceDate = (period) => {
  let [valuePeriod, textPeriod] = [1, 'year'];
  switch (period) {
    case '15_days': {
      [valuePeriod, textPeriod] = [15, 'day'];
      break;
    }
    case 'year': {
      [valuePeriod, textPeriod] = [1, 'year'];
      break;
    }
    default: {
      [valuePeriod, textPeriod] = period.split('_');
      valuePeriod = Number(valuePeriod);
    }
  }
  return { valuePeriod, textPeriod };
};

const queryOffers = async (vehicleType, { city, privilege, period }) => {
  const requestOptions = {
    vehicle_type: vehicleType?.value ?? '',
    vehicle_city_id: city,
    privilege: privilege,
    period: period,
  };

  if (checkVehicleTypeCDE(requestOptions.vehicle_type)) {
    const { valuePeriod, textPeriod } = calcNextServiceDate(period);
    requestOptions.next_service_date = dayjs().add(valuePeriod, textPeriod).add(1, 'day').format('YYYY-MM-DD');
    requestOptions.start_date = dayjs().add(1, 'day').startOf('day').format('YYYY-MM-DD HH:mm:ss');
  }

  return getCompaniesOffers(requestOptions).then((offers) => offers?.map((r) => ({ ...r, vehicleType })));
};

const initDataSet = {
  vehicleType: undefined,
  period: 'year',
  city: '8000000000',
  privilege: 'without',
  bestOnly: true,
};

export const OptionsBar = ({ onChange, onExport }) => {
  const [offers, setOffers] = useState([]);
  const { vehicleTypesList, periodsList } = useVehicleTypesList();
  const [dataSet, setDataSet] = useState(initDataSet);
  const [ loader, setLoad ] = useState(false);

  const queryAllOffers = useCallback(() => {
    setLoad(true);
    if (dataSet.vehicleType) {
      const vt = vehicleTypesList.find(v=> v.value===dataSet.vehicleType);
      queryOffers(vt, dataSet).then((res) => {
        setOffers(res ?? [])
        setLoad(false);
      });
    } else {
      setOffers([]);
      const queries = [];
      vehicleTypesList.forEach((vt) => {
        const { valuePeriod, textPeriod } = calcNextServiceDate(dataSet.period);
        if (
          ['D1', 'D2'].includes(vt.value) &&
          (dataSet.period === 'year' || (textPeriod === 'month' && valuePeriod > 6))
        ) return;
        queries.push(queryOffers(vt, dataSet));
      });
      Promise.all(queries).then((res) =>{
        setLoad(false);
        setOffers(res.flat());
      });
    }
  }, [dataSet, vehicleTypesList]);

  useEffect(() => {
    if (vehicleTypesList.length) queryAllOffers();
  }, [queryAllOffers, vehicleTypesList.length]);

  useEffect(() => {
    const result = offers.map((r) => ({ ...r, zPrice: r?.tariffs.find((tr) => tr.franchise === 0)?.price ?? 0 }));
    if (result.length && dataSet.bestOnly) {
      const filteredResult = filterBestPrice(result);
      onChange(filteredResult);
      return;
    }
    onChange(result);
  }, [dataSet.bestOnly, offers, onChange]);

  const filterBestPrice = (dataOffers) => {
    let filteredResult = [];
//    const grp = Object?.groupBy?.(dataOffers ?? [], ({ vehicleType }) => vehicleType?.value) ?? {};
    const grp = groupBy(dataOffers ?? [], ({ vehicleType }) => vehicleType?.value);
    for (const value of Object.values(grp)) {
      const minValue = value.reduce((minValue, vRow) => Math.min(minValue, vRow.zPrice), value?.[0].zPrice ?? 0);
      filteredResult = filteredResult.concat(value.filter((vRow) => vRow.zPrice === minValue));
    }
    return filteredResult;
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.fitem}>
        <div className={styles.caption}> Тип ТЗ: </div>
        <SelectBox
          items={vehicleTypesList}
          placeholder='Всі'
          displayExpr='title'
          valueExpr='value'
          showClearButton={true}
          value={dataSet.vehicleType}
          onValueChanged={({ value }) => setDataSet((prev) => ({ ...prev, vehicleType: value }))}
        />
      </div>
      <div className={styles.fitem}>
        <div className={styles.caption}> Період: </div>
        <SelectBox
          items={periodsList}
          displayExpr='name'
          valueExpr='code'
          value={dataSet.period}
          onValueChanged={({ value }) => setDataSet((prev) => ({ ...prev, period: value }))}
          width={80}
        />
      </div>
      <div className={styles.fitem}>
        <div className={styles.caption}> Місто: </div>
        <SelectBox
          items={cities}
          displayExpr='name'
          valueExpr='id'
          searchEnabled={true}
          value={dataSet.city}
          onValueChanged={({ value }) => setDataSet((prev) => ({ ...prev, city: value }))}
        />
      </div>
      <div className={styles.fitem}>
        <div className={styles.caption}>
        <CheckBox id='bestOnly'
        text='Найкращі'
          activeStateEnabled={false}
          value={dataSet.bestOnly}
          onValueChanged={({ value }) => setDataSet((prev) => ({ ...prev, bestOnly: value })) }
        />
         </div>
      </div>
      <div className={styles.caption}> 
      <Button className={styles.caption}
        icon='exportxlsx'
        text='Експорт'
        type='default'
        onClick={onExport}
        verticalAlignment='center'
        horizontalAlignment='right'
      />
      </div>
      <LoadPanel visible={loader}  />
    </div>
  );
};

OptionsBar.propTypes = {
  onChange: PropTypes.func.isRequired,
  onExport: PropTypes.func,
};
