import { MenuItem } from "types/otkTypes"

import styles from './PngItem.module.scss'

interface PngItemProps {
    item: MenuItem,
    pngWidth?: string;
    pngHeight?: string;
}
export const PngItem = ({
    item,
    pngWidth = '20',
    pngHeight = '20'
}:PngItemProps) => {
    const hasIcon = !!item.icon;
    return(
    <div className={styles.wrapper}>
    {hasIcon && <img width={pngWidth} height={pngHeight} src={item.icon} alt='' 
      className={styles.png}
    />}
    <span
      style={{paddingLeft:`${hasIcon?pngWidth:0}px`}}
    >{item.text}</span>
    </div>
    )
}


const pngIcons = {
  alert: '/img/pngicons/alert.png',
  bell: '/img/pngicons/bell.png',
  scull: '/img/pngicons/scull.png',
  cross: '/img/pngicons/cross.png',
  remove: '/img/pngicons/remove.png',
}
export type PNGIconTypes = keyof typeof pngIcons;

interface PngIconProps {
  iconType: PNGIconTypes,
  iconText?: string,
  size?: string|number;
  onClick?: ()=>void;
  // pngHeight?: string|number;
}
export const PngIcon = ({
  iconType,
  iconText,
  size = '20',
  ...props
}:PngIconProps) => {
  return(
  <div className={styles.wrapperIcon} {...props}>
   <img width={size} height={size} src={pngIcons[iconType]} alt='' 
  />
  {!!iconText && <span style={{paddingLeft:`${size}px`}}>{iconText}</span>}
  </div>
  )
}