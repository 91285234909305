import { PngItem } from "components/menuItem/PngItem";
import { IButtonOptions } from "devextreme-react/button"
import { FC } from "react"

interface TabButtonProps extends IButtonOptions {
    data?:{ text:string, icon:string}
};

export const TabButton:FC<TabButtonProps> = ({ data, ...props})=>{
    return <PngItem item={ data! }/>
}