import { useContext, useEffect, useState } from 'react';
import { MessageContext } from 'contexts/messages/index.js';

// interface IValues {
//   id: string;
//   ref: string;
// }

export const useMessageVars = (varNames:string[] = [], skip = false) => {
  const messageHandler = useContext(MessageContext);
  const [ values, setValues ] = useState<any>(messageHandler.getVars(varNames));

  useEffect(() => {
    let unsubscribe: ()=>void|undefined;
    if (!skip) {
      unsubscribe = messageHandler.subscribeVars(varNames, (vals:any) => setValues(vals));
    }

    return () => {
      try {
        unsubscribe?.();
      } catch (err) {
        console.log(err);
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ ...varNames, skip ]);

  return values;
};
