import { DateRange, PageContent } from 'components';
import {
  ButtonItem,
  Form,
  GroupItem,
  SimpleItem,
  Tab,
  TabbedItem,
  TabPanelOptions,
} from 'devextreme-react/form';
import {
  DATE_FORMAT,
  DX_DATE_DISPLAY_FORMAT,
  FORM_STYLING_MODE,
  FORM_STYLING_MODE_UNDER,
  } from 'app-constants';
import dayjs from 'dayjs';
import { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import { Button, DataGrid, DateBox, Popup, RadioGroup, TextBox, Validator } from 'devextreme-react';

import { showError, showSuccess, showWarning } from 'utils/notify';
import { usePayKinds, useDate } from 'hooks';
import { dsLabReportsFull } from 'datasources';
import { useMutation } from '@apollo/client';
import { gql } from 'apollo';
import { LoadPanel } from 'devextreme-react/load-panel';
import { NeedHelp } from 'components/needhelp/NeedHelp';
import { useAuth } from 'contexts';
import { updateBranchOptions } from 'utils/helpers';
import { EmailRule } from 'devextreme-react/validator';
import { DataGridReport } from './components/DataGridReport';
import { gridToExcell, groupBy, labels, processRowData, processTotal } from './helper';
import { PopupLegend } from './components/PopupLegend';

import { IReport } from 'datasources/dsLabReports/IReport';

import styles from './labreport.module.scss';
import { ITotalValues } from './IReportRow';
import { locale } from 'devextreme/localization';
import { useSessionStorage, useUnmount } from 'usehooks-ts';

interface ReportData {
  [month: string]: IReport[];
}

const notifySuccesEmailText = 'Звіт надіслано.';
const checkInterval = (start: any, end: any) => dayjs(end).diff(start, 'month', true) <= 2.0;

const buildFilterValue = (filters: any, selectDataType:Periods = 'period') => {
  const start = dayjs(filters.date?.start).startOf('day').format(DATE_FORMAT);
  const end = dayjs(filters.date?.end).endOf('day').format(DATE_FORMAT);
  let filter:any =  [['date', '>', start], 'and', ['date', '<', end]] ;

  if (selectDataType === 'month') {
    filter =  [...filter, 'or', [['date_update', '>', start], 'and', ['date_update', '<', end]] ];
  }

  return filter;
};

const mapNoms = new Map();

interface DGRefs { [key: string]: DataGrid | null };
type Periods = 'period' | 'month';
interface PeriodItem {
  id: Periods;
  text: string;
};
const selectDataTypeItems: PeriodItem[] = [{ id: 'period', text: 'Поточний період' }, { id: 'month', text: 'Минулі місяці' }];

interface FormStorage {
  showDataGrid?: boolean;
  selectDataType?: Periods;
  dataByPeriods?: ReportData;
  totals?: ITotalValues;
  filters?: {
    date: {
      start: string;
      end: string;
    }
  }

}

export const DayLabReport = () => {

  const [sessionState, setSessionState] = useSessionStorage<FormStorage>('dayLabReport', {})

  locale('uk');

  const { startOfMonth, endOfMonth, endOfDay, displayDateTime, prevMonth } = useDate();
  const [showDataGrid, setShowDataGrid] = useState(sessionState.showDataGrid ?? false);
  const [loadingVisible, setLoadingVisible] = useState(false);
  const [selectDataType, setSelectDataType] = useState<Periods>(sessionState.selectDataType ?? selectDataTypeItems[0].id);
  const [totals, setTotals] = useState<ITotalValues | undefined>(sessionState.totals);
  const [filters, setFilters] = useState(sessionState?.filters ?? {
    date: {
      start: startOfMonth(),
      end: endOfDay(),
    },
  });

  const { start, end } = filters.date;
  const {
    lab,
    options: { labReports: gOptions, branchOptions },
    options: authOptions,
  } = useAuth();//+++

  const { payKindByRef, availablePayKinds } = usePayKinds();
  const [dataByPeriods, setDataByPeriods] = useState<ReportData | undefined>(sessionState.dataByPeriods);
  const [sendLabDayReport] = useMutation(gql(`mutation sendLabDayReport($input: JSONObject) {sendLabDayReport(input: $input)}`))
  const dgRefs = useRef<DGRefs>({});
  const payDetailPopupRef = useRef<Popup>(null);
  const validatorRef = useRef<Validator>(null);

  useEffect(() => {
    mapNoms.clear();
    gOptions.nomChangeLabReport.forEach((r) => mapNoms.set(r.inNom.ref, r.outNom));
  }, [gOptions]);

  useUnmount(() => {
    setSessionState({
      filters,
      showDataGrid,
      selectDataType,
      dataByPeriods,
      totals
    });
  })

  const applyFilters = useCallback(async (date?: { date: { start: string, end: string } }) => {
    setShowDataGrid(false);
    setLoadingVisible(true);
    dsLabReportsFull.loadExt({
      filter: buildFilterValue(date ?? filters),
      sort: [{ selector: 'date', desc: false }],
      take: 9999,
    })
      .then(({data}) => {
        const groupedMonth = groupBy<IReport>(data, (repRow: IReport) => (dayjs(repRow.date).format('MM-YYYY')));
        const _totals: { [x: string]: any } = {};
        const loadDataPerMonth: ReportData = {} as ReportData;
        const grids: { [x: string]: DataGrid | null } = {};

        Object.keys(groupedMonth).forEach((key, index) => {
          const keyData = groupedMonth[key];
          if (selectDataType === 'month' && index > 0) return
          _totals[key] = { Каса: 0 };
          _totals[key] = processRowData({ data: keyData, options:authOptions, payKindByRef, mapNoms, isLabRv: !!lab['РВ'] } );
          _totals[key].byType = processTotal(keyData);
          loadDataPerMonth[key as keyof ReportData] = keyData;
          grids[key] = null
        });
        setTotals(_totals as ITotalValues);
        setDataByPeriods(loadDataPerMonth);
        setLoadingVisible(false);
        setShowDataGrid(true);
        dgRefs.current = grids;
      });
  }, [authOptions, filters, lab, payKindByRef, selectDataType]);

  const validateData = useCallback(() => {
    const validation = validatorRef.current?.instance.validate() ?? {};

    const availablePK = availablePayKinds(lab).filter((r: { disabled: any; }) => !r.disabled).map((r: { name: any; }) => r.name);
    const errorsPlus: any = {};

    const grids = dgRefs.current!;
    if (grids === null) return;

    Object.keys(grids).forEach((key) => {
      const items = grids[key]?.instance.getDataSource().items();
      items?.forEach((row) => {
        if (!availablePK.includes(row.pay_kind || 'Безготівка')) errorsPlus.typeError = true;
        if (row.has_error) errorsPlus.hasError = true;
      });

      if (errorsPlus?.typeError) {
        if (validation && !validation?.brokenRules) validation.brokenRules = [];
        validation.isValid = false;
        validation?.brokenRules?.push({ message: `${key} - Недопустимий тип оплати`, type: 'required' });
      }
      if (errorsPlus?.hasError) {
        if (!validation.brokenRules) validation.brokenRules = [];
        validation.isValid = false;
        validation.brokenRules.push({ message: `${key} - Є звіти з помилками`, type: 'required' });
      }
    })
    return validation;
  }, [availablePayKinds, lab]);

  const sendReport = useCallback(async () => {

    const validation = validateData();
    if (!validation?.isValid) {
      const messages = validation?.brokenRules?.map((rule) => rule.message).join('\n');
      showError(messages ?? 'Помилка');
      return;
    }

    const grids = dgRefs.current!;
    if (grids === null) return;

    const attachments = await Promise.all(Object.keys(grids).map(async (key: any) => {
      const filename = `${String(lab.lab_number).padStart(4, "0")}_${key}_щоденний_звіт_ОТК`;

      const excelObjext = await gridToExcell({
        dataGridInstance: grids[key]?.instance!,
        texts: {
          header: `Звіт лабораторії ${String(lab.lab_number).padStart(4, "0")} за період`,
          period: ` з ${displayDateTime(start)} по ${displayDateTime(end)}`
        },
        totals: totals?.[key].byType ?? {}
      });

      return {
        filename,
        buf: (excelObjext as Buffer).toString('base64')
      }
    }));

    updateBranchOptions(branchOptions);
    const subject = `Щоденний звіт лаб ${String(lab?.lab_number).padStart(4, "0")} ${dayjs(end).format('MM/YYYY')}`;
    sendLabDayReport({
      variables: {
        input: {
          subject: subject,
          mailcc: branchOptions.mailcc,
          attachments,
        },
      },
    }).then(({ data }) => {
      if (data?.sendLabDayReport?._id === 'ok') showSuccess(notifySuccesEmailText);
      else showError('Помилка надсилання повіломлення');
    }).catch(() => { throw new Error('Помилка надсилання повіломлення') });
  }, [branchOptions, displayDateTime, end, lab.lab_number, sendLabDayReport, start, totals, validateData]);

  const buttonApplyOptions = useMemo(() => ({
    disabled: !checkInterval(filters.date.start, filters.date.end),
    text: 'Cформувати',
    icon: 'search',
    type: 'default',
    stylingMode: 'outlined',
    onClick: () => applyFilters()
  }), [applyFilters, filters.date.end, filters.date.start]);

  const buttonLegendOptions = useMemo(() => ({
    text: 'Розшифровка по оплатам',
    type: 'default',
    stylingMode: 'outlined',
    onClick: () => payDetailPopupRef.current?.instance.show(),
  }), []);

  const changeReq = (e: any) => (branchOptions.mailcc = e.event?.target?.value);

  const DateComponent = () => {
    return selectDataType === 'period' ?
      <DateRange
        startValue={filters.date.start}
        endValue={filters.date.end}
        stylingMode={FORM_STYLING_MODE}
        onRangeChanged={(range: any) => {
          if (!checkInterval(range.start, range.end)) showWarning('Період більше одного місяця');
          setFilters((prev) => ({ ...prev, date: range }));
          applyFilters({ date: range });
        }}
        displayFormat={DX_DATE_DISPLAY_FORMAT}
        width='100%'
      /> :
      <DateBox
        type='date'
        displayFormat='monthAndYear'
        calendarOptions={{
          maxZoomLevel: 'year',
          minZoomLevel: 'century'
        }}
        max={prevMonth}
        openOnFieldClick
        value={startOfMonth(filters.date.start)}
        onValueChanged={({ value }) => {
          const date = {
            start: startOfMonth(value),
            end: endOfMonth(value)
          };
          setFilters(prev => ({...prev, date}))
          applyFilters({ date });
        }}
      />
  };

  return (
    <PageContent size='large'>
      <LoadPanel visible={loadingVisible} />
      <div className='otk-page-header' style={{ paddingBottom: '10px' }}>
        <span>Звіт Протоколи ОТК за період</span>
        <NeedHelp category='DayLabReport' canChangeCatagory={false} text='Потрібна допомога' />
      </div>
      <Form>
        <GroupItem colCount={4} >
          <SimpleItem>
            <div>
              <DateComponent />
            </div>
          </SimpleItem>
          <SimpleItem>
            <RadioGroup
              items={selectDataTypeItems}
              layout="horizontal"
              valueExpr='id'
              value={selectDataType}
              onValueChanged={({ value }) => {
                setSelectDataType(value)
                let start = startOfMonth();
                let end = endOfDay()

                if (value === 'month') {
                  start = startOfMonth(filters.date.start);
                  end = endOfMonth(filters.date.start);
                }

                if (filters.date.start !== start || filters.date.end !== end) {
                  setShowDataGrid(false)
                  setFilters(prev => ({ ...prev, date: { start, end } }))
                }
              }}
            />
          </SimpleItem>
          <ButtonItem buttonOptions={buttonApplyOptions} verticalAlignment='center' horizontalAlignment='center' />
          <ButtonItem buttonOptions={buttonLegendOptions} verticalAlignment='center' horizontalAlignment='center' />

        </GroupItem>
        <GroupItem cssClass={styles.dataGridItem} visible={showDataGrid && Object.keys(dataByPeriods ?? {}).length > 0}>
          <TabbedItem itemType={'tabbed'}>
            <TabPanelOptions deferRendering={false} animationEnabled={true}/>
            {Object.keys(dataByPeriods ?? {}).map(key => {
              return (
                <Tab key={key} title={key}>
                  <DataGridReport gridRef={dgRefs} refKey={key} dataSource={dataByPeriods?.[key] ?? []} rvVisible={lab['РВ']} />
                </Tab>
              )
            })}
          </TabbedItem>
        </GroupItem>
        <GroupItem colCount={1} visible={showDataGrid && Object.keys(dataByPeriods ?? {}).length > 0}>
          <div className={styles.pagefooter}>
            <div className='dx-field'>
              <div className='dx-field-label'>{labels.mailcc}</div>
              <TextBox
                width={250}
                labelMode='static'
                mode='email'
                id='mailcc'
                defaultValue={branchOptions.mailcc}
                hint='копія отримувача звіту'
                onChange={changeReq}
                stylingMode={FORM_STYLING_MODE_UNDER}
              >
                <Validator ref={validatorRef}>
                  <EmailRule message={'Некоректний email!'} />
                </Validator>
              </TextBox>
            </div>

            <Button
              className={styles.exportButton}
              text='Надіслати'
              icon='exportxlsx'
              type='default'
              useSubmitBehavior={false}
              onClick={sendReport}
            />
          </div>
        </GroupItem>
      </Form>
      <PopupLegend payDetailPopupRef={payDetailPopupRef} totals={totals ?? {}} />
    </PageContent>
  );
};


