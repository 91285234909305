//шляхи для запитів
import {
  get_vehicle_by_plate_num,
  policy_exist,
  get_companies_offers,
  get_vehicle_types,
  search_cities,
  get_privileges,
  get_periods,
  get_paysystems,
  policy_reserve,
  policy_reserve_check,
  policy_send_otp,
  policy_verify_otp,
  policy_confirm,
  payment_create_order,
  initapi,
  payment_order_state,
} from './services';

export const initApi = (tokens) => {
  if (!policy_exist.pkey) {
    initapi(tokens);
  }
};

//Отримання інформації про ТЗ по номеру
export const getVehicleByPlateNum = async (plateNumber) => {
  let res = '';
  if (plateNumber) {
    res = await get_vehicle_by_plate_num.get(plateNumber);
  }
  return res;
};

//пошук міст - search vehicle cities list with region
export const getVehicleCitiesSearch = async (search) => {
  let res = null;
  if (search) {
    res = await search_cities.get('', false, { key: `${search}` });
  }
  return res;
};

//тип ТЗ - vehicle types list
//{ "name": "Мотоцикл, мотороллер менше 300 см3","code": "A1"}
export const getVehicleTypesList = async () => {
  let res = null;
  const list = await get_vehicle_types.get('') || [];
  res = list.map((item) => ({
    title: `${item?.code} (${item?.name})`,
    value: item?.code }));
  return res;
};

//пільги privileges list with document types
export const getPrivilegesList = async () => {
  let privileges = [];
  privileges = await get_privileges.get('') || [];
  return privileges;
};

//період дії договору - потрібен для категорій С D E
export const getPeriodsList = async () => {
  let periods = [];
  periods = await get_periods.get('') || [];
  return periods;
};

//список платіжних систем paysystems list
export const getPaysystems = async () => {
  let paysystems = [];
  paysystems = await get_paysystems.get('') || [];
  return paysystems;
};

//Перевірка на наявність полісу
export const checkPolicyExist = async (number, vin) => {
  let res = '';
  if (number && vin) {
    res = await policy_exist.get('', false, {
      vehicle_plate_num: number, vehicle_vin: vin });
  }
  return res;
};

//Отримання пропозицій страхових компаній
export const getCompaniesOffers = async (data) => {
  let res;
  if (data) {
    res = await get_companies_offers.get('', false, { ...data });
  }
  return res;
};

//Резервування полісу
export const policyReserve = async (data) => {
  let res;
  if (data) {
    res = await policy_reserve.post('', false, { ...data });
  }
  return res;
};

//Перевірка на можливість резервування полісу (час)
export const policyReserveCheck = async () => {
  let res = '';
  res = await policy_reserve_check.get('');
  return res;
};

//Отримання otp паролю
export const policySendOtp = async (data) => {
  let res;
  if (data) {
    res = await policy_send_otp.get('', false, { ...data });
  }
  return res;
};

//Перевірка otp паролю
export const policyVerifyOtp = async (data) => {
  let res;
  if (data) {
    res = await policy_verify_otp.get('', false, { ...data });
  }
  return res;
};

//Підтвердження полісу після успішної оплати та перевірки otp паролю
export const policyConfirm = async (data) => {
  let res;
  if (data) {
    res = await policy_confirm.post('', false, { ...data });
  }
  return res;
};

//створення ордеру easypay
export const paymentCreateOrder = async (data, opt) => {
  let res;
  if (data) {
    res = await payment_create_order.post('', false, { ...data }, opt);
  }
  return res;
};

//перевірка створення ордеру easypay
export const paymentOrderState = async (data, opt) => {
  let res = '';
  if (data) {
    res = await payment_order_state.post('', false, { ...data }, opt);
  }
  return res;
};
