import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { dsDebitsByAct } from 'datasources';
import style from './Debitorka.module.scss'
import { LoadIndicator } from "devextreme-react";

interface DebitorkaProps {
  partnerRef?: string;
  // colSpan?: number
}

export const Debitorka = ({ partnerRef }: DebitorkaProps) => {
  const [value, setValue] = useState<number>(0);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (partnerRef === '') {
      setLoading(false);
      return
    }
    setLoading(true);
    dsDebitsByAct.userData = { partner: partnerRef };
    dsDebitsByAct.loadExt().then((res) => {
      const deb = res?.data?.reduce((prev, curr) => prev + curr.amount, 0) ?? 0;
      setValue(deb);
      setLoading(false);
    });
    setValue(0);
  }, [partnerRef])

  return <div className={style.wrapper}>
    <Link to={`/debitsbyact_report/${partnerRef || ''}`}>
      {(value !== 0) && <div className={style.element}>
        <span className={style.label}> {(value > 0) ? 'Борг клієнта' : 'залишок коштів'} </span>
        <span className={(value > 0) ? style.redsum : style.sum}>{String(Math.abs(value))}</span>
      </div>}
    </Link>
    <LoadIndicator width={16} height={16} id="small-indicator" visible={loading} />
  </div>
}