import { gql, useSubscription } from '@apollo/client';
import { useAuth } from 'contexts';
import { useEffect, useState } from 'react';
import { TDataBaseObjectClasseNames } from 'types/otkTypes';

const DOC_SUBCRIPTION = gql(
  `subscription OnDocChanged($input: JSONObject, $className: String, $ref: String) {
    docChange(input: $input, class_name: $className, ref: $ref)
  }`,
);

interface DataType {
  docChange: {
    ref: string; // doc ref "4be2d6d7-5c80-468a-849e-11ad5cd8ca15",
    branch: string; //brabch ref "0461a5f7-5bf0-11eb-8a95-00155d000b04",
    class_name: TDataBaseObjectClasseNames;// "doc.buyers_order",
  }
}

export const useDocChangeListener = (className: TDataBaseObjectClasseNames, onChange: () => void, ref?: string) => {
  const { user } = useAuth();
  const [ state ] = useState({
    className,
    onChange,
    ref
  })

  const { data } = useSubscription<DataType>(DOC_SUBCRIPTION, {
    variables: {
      input: { username: user?.name ?? '' },
      state: state.className,
      ref: state.ref,
    },
  });

  useEffect(() => {
    // if (!loading && (!!_ref || data?.docChange?.ref === _ref)) onChange();
    state.onChange();
  }, [data, state]);
};
