import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import { DX_DATETIME_DISPLAY_FORMAT, uaFilterRowText } from 'app-constants';
import { SearchDropdown, forwardProps } from 'components/search-dropdown';
import { DataGrid, Menu } from 'devextreme-react';
import { Column, FilterRow, Paging, Scrolling, Selection } from 'devextreme-react/data-grid';
import { useSearchDatagridHandler } from 'hooks';
import { dsReports, dsReportsCancelled } from '../datasources';
import PropTypes from 'prop-types';

const dsSourceReports = (filterData, cancelled = false) => {
  const { invoice, partnerRef, thisDoc } = filterData;
  if (!cancelled) return dsReports(invoice?.ref, thisDoc);
  return dsReportsCancelled(partnerRef, thisDoc);
};

const ReportsDatagrid = forwardRef(({ searchField = 'number_doc', ...props }, ref) => {
  const { gridRef, focusElementRef, onCurrentRowChange } = useSearchDatagridHandler(
    ref,
    searchField,
    props.onSelectionChanged
  );

  const [currentRowData, setCurrentRowData] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [cancelledStatus, setCancelledStatus] = useState(false);

  useEffect(() => {
    setDataSource(dsSourceReports(props.filterData, cancelledStatus));
  }, [cancelledStatus, props.filterData, props.filterData?.invoice?.ref, setDataSource]);

  const handleMenuClick = (e) => {
    if (e.itemData.id === 'select') {
      props.onSelectionChanged(currentRowData);
    } else if (e.itemData.id === 'clear') {
      props.onSelectionChanged({});
    } else if (e.itemData.id === 'cancelled') {
      setCancelledStatus((prev) => !prev);
    }
  };

  const grid = useMemo(() => 
    <DataGrid
      ref={gridRef}
      showBorders
      remoteOperations
      dataSource={dataSource}
      hoverStateEnabled={true}
      onRowDblClick={({data}) => props.onSelectionChanged(data)}
      onSelectionChanged={({selectedRowsData}) => {
        if (selectedRowsData.length > 0) {
          setCurrentRowData(selectedRowsData[0]);
          onCurrentRowChange(selectedRowsData[0]);
        }
      }}
      height='90%'
    >
      <Selection mode='single' />
      <Scrolling mode='virtual' rowRenderingMode='virtual' />
      <Paging enabled={true} pageSize={100} />
      <FilterRow visible={true} {...uaFilterRowText} />

      <Column
        dataField='number_doc'
        caption='Номер'
        dataType='string'
        alignment='center'
        allowHiding={false}
        allowResizing={false}
        width={150}
      />

      <Column
        dataField='date'
        caption='Дата'
        dataType='date'
        format={DX_DATETIME_DISPLAY_FORMAT}
        alignment='center'
        width={190}
      />
      <Column dataField='gnumber' caption='Держ.номер' alignment='center' width={190} />
      <Column dataField='invoice.number_doc' caption='Замовлення' alignment='center' width={190} />
    </DataGrid>
, [dataSource, gridRef, onCurrentRowChange, props]);

  return (
    <div ref={focusElementRef} style={{ display: 'block', width: '100%', height: '100%' }}>
      <Menu
        onItemClick={handleMenuClick}
        activeStateEnabled={false}
        dataSource={[
          {
            text: 'Вибрати',
            id: 'select',
            icon: 'check',
            visible: !!props.onSelectionChanged,
            disabled: !currentRowData,
          },
          {
            text: 'Очистити',
            id: 'clear',
            icon: 'close',
            disabled: !props?.value.ref,
          },
          {
            text: cancelledStatus ? 'Анульовані' : 'По заказу',
            id: 'cancelled',
            icon: 'preferences',
            selected: cancelledStatus,
          },
        ]}
      />

      {grid}
    </div>
  );
});

ReportsDatagrid.displayName = 'ReportsDataGrid';

ReportsDatagrid.propTypes = {
  value: PropTypes.shape({
    ref: PropTypes.string.isRequired,
  }),
  searchField: PropTypes.string,
  onSelectionChanged: PropTypes.func,
  onPartnerClose: PropTypes.func,
  filterData: PropTypes.shape({
    invoice: PropTypes.object,
    partnerRef: PropTypes.string,
    thisDoc: PropTypes.string,
  }),
};

export const ReportsSearch = (props) => {
  return (
    <SearchDropdown
      {...props}
      value={props?.report ?? { ref: '' }}
      text={props.report?.caption || ''}
      onSelect={props.onSelect}
      minSearchLength={1}
      searchTimeout={500}
      placeholder='звіт лабораторії...'
      saveSearchValueOnLeave={true}
      calcSearchValue={() => ''}
      width='100%'
      dropdownWidth='fit-content'
      dropdownHeight={400}
      top
    >
      <ReportsDatagrid dataSource={props.dataSource} filterData={props.filterData} />
    </SearchDropdown>
  );
};

ReportsSearch.propTypes = {
  ...forwardProps,
  report: PropTypes.shape({
    ref: PropTypes.string.isRequired,
    caption: PropTypes.string.isRequired,
  }),
  onSelect: PropTypes.func.isRequired,
  filterData: PropTypes.shape({
    invoice: PropTypes.object,
    partnerRef: PropTypes.string,
    thisDoc: PropTypes.string,
  }),
};
