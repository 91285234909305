import { gql } from "apollo";

export const updateEasyPayMessageQuery = gql(`mutation updateEasyPayMessage($input: JSONObject) { setEasyPayMessage(input: $input) { _id }}`);

export const getEasyPayMessageQuery = gql(`query getEasyPayMessage($ref: String) {
    getEasyPayMessage: getEasyPayMessage(ref: $ref) {
    _id
    ref
    ext_json
    number_doc
    date
    posted
    totalsum
    protected
    caption
    typeof_message
    status
    terminal_number
    description
    printscreen
    moder_file
    amount
    proj
    note
    data_incident
    department {ref name}
    organization {ref name}
    }
  }`);