import { gql } from 'apollo';
import { INom } from 'types/datatypes/INom';
import { createDataSource } from 'utils/gql-datasource';

const dsGetServicesNoms = gql(`query dsGetServisesNoms($ref: String, $limit: Int, $offset: Int, $jfilt: [JSON]) {
    totalcount: noms(ref: $ref, totalCount: 1, jfilt: $jfilt,options: {selectServices: true}) { totalcount }
    list: noms(ref: $ref, limit: $limit, offset: $offset, jfilt: $jfilt, options: {selectServices: true}) {
      ref 
      name 
      name_full 
      vat_rate
      article
      doc_type
    }
  }`);

export const dsServicesNoms = createDataSource<INom>(dsGetServicesNoms);


