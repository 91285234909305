import { DoclistPageTemplate } from 'components';
import { dsOrderCollections } from 'datasources/dsOrderCollections/dsOrederCollections.js';
import { Menu } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid.js';
import { useRef } from 'react';
import { useNavigate } from 'react-router';

export const OrderCollections = () => {
  const gridRef = useRef();
  const navigate = useNavigate();
  return (
    <DoclistPageTemplate
      gridRef={gridRef}
      pageName='Зведені замовлення - оплата картою он-лайн'
      onEdit={(ref) => navigate(`/orderCollections/${ref}`)}
      dataSource={dsOrderCollections}
      menuComponent={
        <Menu
          onItemClick={(e) => {
            switch (e.itemData.id) {
              case 'new': {
                navigate('/orderCollections/new');
                break;
              }
              default:
            }
          }}
          dataSource={[
            {
              text: 'Додати',
              icon: 'add',
              id: 'new',
            },
          ]}
        />
      }
      columnsDataFields = {{
        date: 'date',
        docNumber: 'number_doc'
      }}
    >
      <Column
        allowSorting={true}
        allowResizing={true}
        allowHiding={false}
        dataField='doc_amount'
        caption='Сума'
        dataType='number'
        alignment='right'
        minWidth={90}
      />
      <Column
        allowFiltering={false}
        allowSearch={false}
        allowResizing={true}
        allowHiding={false}
        dataField='pay'
        caption='Опл'
        dataType='boolean'
        alignment='center'
        minWidth={90}
      />
    </DoclistPageTemplate>
  );
};
