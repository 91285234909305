import { Button, CheckBox, Popup, SelectBox, TextArea, TextBox } from "devextreme-react";
import { useRef, useState } from "react";

import styles from './needhelp.module.scss';
import { Controller, useForm } from "react-hook-form";
import { LabeledField } from "components/react-hook-form/form";
import { useAuth } from "contexts/auth";
import { render } from '@react-email/render';
import NeedHelpEmail from "components/emails/NeedHelpEmail";
import { gql } from "apollo";
import { showError, showSuccess } from "utils/notify";
import { useMutation } from "@apollo/client";

const inputAttr = { style: 'color:black; background:white' };

export const Category = {
  Common: "Загальні питання",
  DayLabReport: "Звіт Протоколи ОТК (за період)"
};

type KeyType = keyof typeof Category;
const categoryKeys = Object.keys(Category);

const isKey = <T extends object,>(x: T, k: PropertyKey): k is keyof T => {
  return k in x;
}
export const categoryDataSource = categoryKeys.map((key) => {
  if (isKey(Category, key))
    return {
      category: key,
      text: Category[key],
    }
  return {}
})



interface PopupLegendProps {
  category?: KeyType,
  canChangeCatagory?: boolean
  text?: string
}

interface IChanels {
  phone:boolean;
  telegram: boolean;
  viber:boolean;
}

interface INeedHelpFormData {
  category: KeyType;
  phone: string;
  chanel: IChanels;
  description: string;
}

const mutationQuery = gql(`mutation sendNeedHelp($input: JSONObject) { sendNeedHelp(input: $input) }`)

export const NeedHelp = ({ category = 'Common', canChangeCatagory = true, text='' }: PopupLegendProps) => {

  const [postChange] = useMutation(mutationQuery);
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<INeedHelpFormData>({
    reValidateMode: 'onChange',
    mode: 'all',
    values: {
      category,
      phone: '',
      description: '',
      chanel: {
        phone:true,
        telegram:false,
        viber:false,
      }
    },
    shouldFocusError: false,
  });


  const [selectedCategory, setCategory] = useState<KeyType>(category)
  const {user, lab} = useAuth();
  const popupRef = useRef<Popup>(null);


  const checkContactRules = ({phone, telegram, viber}:IChanels) => {
    return (phone||telegram||viber) || 'Вкажіть доступні канали зв\'язку'; 
  };

  const onSubmit = async (data:INeedHelpFormData) => {
    const categoryName=Category[data.category];
    // const userData = data;
    const html = await render(<NeedHelpEmail
      formData={data}
      labNumber={lab.lab_number}
      userName={user.name}
      categoryName={categoryName}
      />);

      postChange({
        variables: {
          input: {
            body: html,
            category: data.category,
            categoryName,
            lab_number: lab.lab_number,
            attachments: []
          },
      }})
      .then(({data}) => {
        data?.sendNeedHelp?._id !== 'ok' ? showError('Відправка не вдалась') :
          showSuccess('Повідомлення надіслано\nНайближчим часом з Вами зв\'яжеться наш оператор', 6000);
      })
  };

  return <div className={styles.wrapper} >
    <div className={styles.buttonlike} onClick={() => popupRef.current?.instance.show()}>
      <img width='24' height='24' src='/img/png/operator.png' alt='' />
      <span>{text}</span>
    </div>
    <Popup
      ref={popupRef} showCloseButton
      closeOnOutsideClick
      width={'450'}
      height={'auto'}
      title="Маю питання:"
      onShown={()=>{
        const labPhone=lab?.contacts?.phone_corp ?? '';
        setValue('phone', labPhone.substring(labPhone.length - 10))
      }}
    >
      <form 
        style={{display:'flex', flexDirection:'column', gap:15}}
        onSubmit={handleSubmit(onSubmit)}
      >
          <Controller
            name='category'
            control={control}
            render={({ field }) => {
              return (
                <LabeledField label={'Категорія'} field={field} errors={errors}>
                  <SelectBox
                    style={{ background: 'white', color: 'black' }}
                    dataSource={categoryDataSource}
                    displayExpr='text'
                    searchEnabled={true}
                    searchExpr='category'
                    onSelectionChanged={(e) => {
                      const item = e.selectedItem?.category as KeyType;
                      if (!!item) setCategory(item)
                    }}
                    value={selectedCategory}
                    valueExpr={(e) => e.category}
                    readOnly={!canChangeCatagory}
                  />
                </LabeledField>
              )
            }}
          />
          <Controller
            name='phone'
            control={control}
            rules={{ required: 'Телефон для контакту' }}
            render={({ field }) => {
              return (
                <LabeledField label={'Телефон'} field={field} errors={errors}>
                  <TextBox inputAttr={inputAttr}  mask="38 (000) 000-0000"/>
                </LabeledField>
              )
            }}
          />
          <Controller
            name='chanel'
            control={control}
            rules={{ validate: { contactRules: checkContactRules } }}
            render={({ field }) => {
              return (
                <LabeledField label={'Канал'} field={field} errors={errors}>
                  <div className={styles.chanels}>
                    <div className={styles.chanel}>
                      <img width='24' height='24' src='/img/png/operator.png' alt='' />
                      <CheckBox
                        onValueChange={(e)=>field.onChange({...field.value,  phone: e})}
                        value={field.value.phone}
                      />
                    </div>
                    <div className={styles.chanel}>
                      <img width='24' height='24' src='/img/png/telegram.png' alt='' />
                      <CheckBox
                        onValueChange={(e)=>field.onChange({...field.value,  telegram: e})}
                        value={field.value.telegram}
                      />
                    </div>
                    <div className={styles.chanel}>
                      <img width='24' height='24' src='/img/png/viber.png' alt='' />
                      <CheckBox
                        onValueChange={(e)=>field.onChange({...field.value,  viber: e})}
                        value={field.value.viber}
                      />
                    </div>  
                  </div>
                </LabeledField>
              )
            }}
          />
          <Controller
          name='description'
          control={control}
          render={({ field }) => {
            return (
              <LabeledField label={'Опис'} field={field} errors={errors}>
                  <TextArea inputAttr={inputAttr} placeholder="короткий опис проблеми чи просьби"/>
              </LabeledField>
            )
          }}
        />
        <div style={{ width: '100%' }}>
          <Button
            width='100%'
            type='default'
            useSubmitBehavior
            text='Надіслати'
          />
        </div>
        </form>
    </Popup>
  </div>

}    