import { useQuery } from '@apollo/client';
import { gql } from 'apollo';

export interface IOrg {
  ref: string;
  name: string;
}

const gQuery = gql(`query getOrgs($ref: String, $limit: Int, $offset: Int, $jfilt: [JSON]) {
      # totalcount: getOrgs(ref: $ref, totalCount: 1) {totalcount}
      getOrgs(ref: $ref, limit: $limit, offset: $offset, jfilt: $jfilt) { ref name }
  }`);


export const useOrgs = ()=>{
  const {data} = useQuery(gQuery, { fetchPolicy: 'cache-first' });
  return {
    orgs :(data?.getOrgs ?? []) as IOrg[]
  }
}

