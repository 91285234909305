import { useState } from 'react';
import { Tooltip } from 'devextreme-react/tooltip';

interface CopyButtonProps {
  value: string;
  visible?:boolean,
  hint?: string;
}

export const CopyButton = (props:CopyButtonProps) => {
  const [ showSuccess, setShowSuccess ] = useState(false);
  const copyToClipboard = () => {
    navigator.clipboard.writeText(props.value)
      .then(() => {
        setShowSuccess(true);
        setTimeout(() => setShowSuccess(false), 3000);
      })
      .catch((err) => console.error(err));
  };

  return (
    <div>
      <Tooltip
        style={{ position: 'absolute' }}
        target='#copyButton'
        visible={showSuccess}
        position={{ my: 'left', at: 'right' }}
      >
        <div>скопійовано</div>
      </Tooltip>
      <div
        style={{cursor: 'pointer'}}
        id='copyButton'
        // visible={!!props.value}
        // hint={props.hint}
        onClick={copyToClipboard}
      >

      <i className={showSuccess?'dx-icon dx-icon-check':'dx-icon dx-icon-copy'} style={{fontSize: '14px', zIndex:2}}/>
      </div>
      {/* <Button 
        id='copyButton'
        icon={showSuccess ? 'check' : 'copy'}
        visible={!!props.value}
        focusStateEnabled={false}
        hint={props.hint}
        onClick={copyToClipboard}
      >
        <i className='dx-icon dx-icon-copy'/>
        </Button> */}
    </div>
  );
};
