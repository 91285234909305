import { LoadIndicator } from 'devextreme-react/load-indicator';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import styles from './doc-info-bar.module.scss';
import dayjs, { Dayjs } from 'dayjs';
import { DATE_DISPLAY_FORMAT } from 'app-constants';
import React from 'react';

interface DocInfoBarProps {
  loading?: boolean;
  isNew?:boolean;
  name:string;
  data?:{
    date?: string | Dayjs;
    number_doc?: string;
    number?:string;
  };
  children?: any
}

export const DocInfoBar = ({ loading = false, data = {}, ...props }:DocInfoBarProps) => {
  const dateStr = data?.date ? `від ${dayjs(data.date).format(DATE_DISPLAY_FORMAT)}` : '';
  const numb = data?.number ?? data?.number_doc;
  const docNumber = numb ?? ' ';
  const docDesc = props?.isNew ? ' (новий)' : ` ${docNumber} ${dateStr}`;
  return (
    <div className='otk-info-bar'>
      {loading ?
        <LoadIndicator
          indicatorSrc='img/loader.gif'
          width={50}
          height={50}
        /> :
        <>
          <Toolbar className={styles.toolbar}>
            <Item
              location='before'
              cssClass={styles.desc}
              text={`${props.name} ${docDesc}`}
            >
            </Item>

            {React.Children.toArray(props.children)
              .filter((child) => !!child).map((child, index) => <Item
                cssClass={styles.menuItem}
                key={index}
                locateInMenu='auto'
                location='before'
              >
                {child}
              </Item>,
              )}
          </Toolbar>
          <div>

          </div>
        </>
      }
    </div>
  );
};
