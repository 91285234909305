import { DataGrid } from "devextreme-react"
import { Column, MasterDetail, Paging, Scrolling } from "devextreme-react/data-grid";
import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from "react"
import { CRMReport } from "./Clients";
import { useNavigate } from "react-router";
import { PngIcon } from "components/menuItem/PngItem";

interface ClientRecordsProps {
    testProp?:string
} 
interface DataRep {
    gnumber:string,
    mvisit: number,
    sname:string,
    reports:CRMReport[]
};

export type ClientRecordsInstance = {
    setReports: (cars:DataRep[])=>void;
  };
  
export const ClientRecords = forwardRef(({testProp}:ClientRecordsProps, ref)=>{
    const [data, setData] = useState<DataRep[]>([]);
    const refGrid = useRef<DataGrid>(null);

    useImperativeHandle(ref, () => {
        return {
            setReports
        };
      });

      const setReports = (reports:DataRep[]) => setData(reports);  

			const deatilRender = useCallback((e:any)=>{
				return <DetailSection data={e.data}/>
			}, [])

      return (<DataGrid<DataRep>
        keyExpr={'gnumber'}
        ref={refGrid}
        dataSource={data}
        focusedRowEnabled
        showColumnHeaders={false}
        onDataErrorOccurred={(e)=> {
            console.log(e)
            // navigate('/login')
        }}
       
        showBorders={false}
        noDataText=""
        onCellPrepared={(e)=>{ 
            if ( e.rowType === 'data' ) {
              if (e.data.mvisit === -1) {
                e.cellElement.style.background = "green";
                e.cellElement.style.color = "white";
              }
              else if(e.data.mvisit === 0) {
                e.cellElement.style.background = "#e65e03";
                e.cellElement.style.color = "white";
              }
              else if(e.data.mvisit > 0) {
                e.cellElement.style.background = "#4d4d4d";
                e.cellElement.style.color = "white";
              }
              else {
                e.cellElement.style.background = "#d9ffd8";
                // e.cellElement.style.color = "white";
              }
            }
          }}
      >
        <Scrolling showScrollbar={'always'} /> 
        <MasterDetail
                enabled={true}
                autoExpandAll={true}
                render={deatilRender}
            />

        <Paging enabled={false} pageSize={100} />
        <Column dataField='gnumber' cellRender={GroupCell}/>
      </DataGrid>)
});

const GroupCell = (e:{data:DataRep}) =>{
    const mvisit = e.data.mvisit;
    return (< div style={{display:'flex', gap: '5px', alignItems:'center' }}>
        <span style={{width:'100px'}}>{e.data.gnumber} ({e.data.sname})</span>
        { (mvisit === -1) && <PngIcon size={16} iconType="bell"/>}
        { (mvisit === 0) && <PngIcon size={16} iconType="alert"/>}
        { (mvisit > 0) && <PngIcon size={16} iconType="cross"/>}
    </div>)
}

interface DetailSectionProps {
    data: DataRep
}

const DetailSection = ({data}:DetailSectionProps)=>{
    const navigate = useNavigate();
		const dblClicHandler = useCallback((e:any)=>{
				navigate(`/lab_report/${e.data.ref}`)
		},[navigate])
    return <DataGrid className="ddd"
        key={'ref'}
        dataSource={data.reports}
        showColumnHeaders={false}
        showRowLines={false}
        onCellDblClick={dblClicHandler}
      >
      <Column dataField='date' dataType='date' sortOrder='asc'/>      
      <Column dataField='number_doc'/> 
    </DataGrid>
}