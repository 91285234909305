import { useMutation, useQuery } from "@apollo/client";
import { Button } from "devextreme-react";
import { useDocChangeListen } from "hooks";
import { MUTATE_ORDER_QUERY, ORDER_FOR_PAY_QUERY } from "pages/orderForPay/data/orderForPayQuery";
import { createNewOrderForSave } from "pages/orderForPay/OrderForPay";
import { useState } from "react";
import { Link } from "react-router-dom";
import { IInsurancePolicy, IPolicyCashlessPayment } from "types/datatypes/IInsurance";
import { IOrderForPay } from "types/datatypes/IOrderForPay";
import { splitId } from "utils/helpers";

interface CashlessPaymentFormProps{
    data:  IInsurancePolicy;
    updatePayment: (data:IPolicyCashlessPayment)=>void
}

export const CashlessPaymentForm = ({data, updatePayment}:CashlessPaymentFormProps) => {
    const [order, setOrder] = useState<Partial<IOrderForPay>>();
    const policy_payment = data?.policy_cashless_payment;

    const { refetch } = useQuery(ORDER_FOR_PAY_QUERY, {
        variables: {
          ref:policy_payment?.order_for_payment??'555',
        },
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
          setOrder( data?.orderForPay?.[0] as IOrderForPay);
        }
      })

    const onOrderChange = ()=>{
      refetch().then(({data}) => {
        setOrder( data?.orderForPay?.[0] as IOrderForPay);
      })
    }

    useDocChangeListen('doc.blankorder', onOrderChange, order);


    const [createOrderForPay] = useMutation(MUTATE_ORDER_QUERY);

    const createOrder = ()=>{
  
            // if(!!order) {
            //   const orderForSave = {...order};
            //   orderForSave.partner = 'f9fcd42a-9c70-11ef-8b3e-00155d000b4b'
            //   orderForSave.pos_blank![0].nom = '874dfc8f-2ae5-11e9-8100-00155dcccf0a';
            //   orderForSave.note = '+++Tect - отладка - НЕ ПЛАТИТИ'
            //   // orderForSave.partner = order.partner.ref
            //   createOrderForPay({
            //       variables:{
            //         input:orderForSave
            //     }}).then(dat=>{
            //       console.log(dat)
            //     })
            //    return; 
            // }
            const ref = '6e6411e0-43a3-45d8-84f2-f1630dbfecff';
            const orderForPay: IOrderForPay = createNewOrderForSave(ref);
            orderForPay.input_doc_number = data.policy_reserve?.policy_number;
            orderForPay.note = 'Tect - отладка - НЕ ПЛАТИТИ';
            orderForPay.pos_blank.push({
              nom: '874dfc8f-2ae5-11e9-8100-00155dcccf0a',
              row: 1,
              amount: data.paymentPrice,
              price: data.paymentPrice,
              quantity: 1,
              coefficient: 1,
              vat_amount: 0,
              vat_rate: 'НДС0',
              in_doc_number: data.policy_reserve?.policy_number,
            });
            createOrderForPay({variables:{input:orderForPay}})
              .then(resp=>{
                if (resp.data?.setOrderForPay.ok) {
                    const ref = splitId(resp.data?.setOrderForPay.id).ref;
                    const policy_payment = {...data.policy_payment,
                      order_for_payment: ref,
                      amount: data.paymentPrice
                    }
                    updatePayment(policy_payment);
                }
            })

            const policy_payment = {
              order_for_payment: ref,
              amount: data.paymentPrice
            }
            updatePayment(policy_payment);

    }

    if (!policy_payment?.order_for_payment) {
        return (
            <div style={{display:"flex", flexDirection:'column', alignItems:'center'}}>
                <div> Замовлення на оплату відсутнє</div>
                <Button text="Створити" type="success" onClick={createOrder}/>
            </div>    
        )
    };

    return (
        <div>
            <Link to={`/orderforpay/${splitId(order?._id!).ref}`} >
            <h4>
  
              Замовлення на оплату: {order?.number_doc} {order?.note}
              
            </h4> 
            </Link>
            {/* <Button text="Створити" type="success" onClick={createOrder}/> */}
        </div>
    );
};
