import { useRef, useState, Suspense, lazy } from 'react';
import DataGrid, { Column } from 'devextreme-react/data-grid';
import { Menu } from 'devextreme-react/menu';
import { useNavigate } from 'react-router-dom';
import { COLUMN_DOCNUMBER_WIDTH, COLUMN_EP_TYPE, COLUMN_EP_VIN, COLUMN_EP_CAR_NUMBER } from 'app-constants';
import { dsEPOTK } from './dsEPOTK';
import { DoclistPageTemplate } from 'components';
import { ItemInfo, MenuItem } from 'types/otkTypes';

import styles from '../ep-main/styles/style.module.scss';
import { useDocChangeListener } from 'hooks';

const PrintBTNadapter = lazy(() => import('../ep-main/components/print/printBTNadapter'));

export const EPPage = () => {

  const navigate = useNavigate();
  const [ selectedRowData, setSelectedRowData] = useState<any>({});

  const gridRef = useRef<DataGrid>(null);
  const printRef = useRef<any>(null);

  useDocChangeListener('doc.ep', () => gridRef.current?.instance.refresh(true));

  const handleEditIconClick = (ref: string, data: any) => {
    if (data) {
      switch (data?.body?.type) {
        case 'ОТК':
          navigate(`/ep/${ref}`);
          break;
        case 'Taho':
          navigate(`/ep-taho/${ref}`);
          break;
        case 'CO2':
          navigate(`/ep-co2/${ref}`);
          break;
        default:
          navigate(`/ep/${ref}`);
          break;
      }
    }
  };

  return (<>
    <DoclistPageTemplate
      pageName='Електронні протоколи'
      gridRef={gridRef}
      dataSource={dsEPOTK}
      onEdit={(ref, obj) => handleEditIconClick(ref, obj)}
      onSelectionChanged={(e) => setSelectedRowData(e.selectedRowsData[0] || {})}
      menuComponent={(<Menu
        className={styles?.padding_0_10}
        onItemClick={({ itemData }: ItemInfo) => {
          switch (itemData?.id) {
            case 'new-otk': {
              navigate('/ep/new');
              break;
            }
            case 'new-taho': {
              navigate('/ep-taho/new');
              break;
            }
            case 'new-co2': {
              navigate('/ep-co2/new');
              break;
            }
            case 'new-ekmt': {
              navigate('/ep-ekmt/new');
              break;
            }
            case 'close': {
              navigate('/home');
              break;
            }
            case 'print': {
              printRef?.current?.props?.onClick();
              break;
            }
            default:
              break;
          }
        }}
        dataSource={[
          {
            icon: '/img/pngicons/plus.png',
            text: 'Додати',
            items: [
              {
                id: 'new-otk',
                text: 'ОТК',
              },
              {
                id: 'new-taho',
                text: 'Тахограф',
                // disabled: true,
              },
              {
                id: 'new-co2',
                text: 'CO2',
                // disabled: true,
              },
              // {
              //   id: 'new-ekmt',
              //   text: 'EKMT',
              // },
            ],
          },
          {
            id: 'close',
            icon: 'close',
            text: 'Закрити',
          },
          {
            id: 'clear',
            icon: '/img/pngicons/print.png',
            text: 'Друк',
            disabled: !selectedRowData?.number_doc || !selectedRowData?.body?.type,
          },
        ] as MenuItem[]}
      />
      )}
    >
      <Column
        dataField="body.type"
        caption="Тип"
        dataType="string"
        alignment="left"
        width={COLUMN_EP_TYPE}
      />
      <Column
        dataField="body.order.number_doc"
        caption="№ Замовлення"
        dataType="string"
        alignment="left"
        width={COLUMN_DOCNUMBER_WIDTH}
      />
      <Column
        dataField="body.vin"
        caption="VIN Кузова"
        dataType="string"
        alignment="left"
        minWidth={COLUMN_EP_VIN}
      />
      <Column
        dataField="body.car_number"
        caption="Держ. номер"
        dataType="string"
        alignment="left"
        minWidth={COLUMN_EP_CAR_NUMBER}
      />
    </DoclistPageTemplate>
    <div hidden={true}>
      <Suspense fallback={<></>}>
        <PrintBTNadapter forwardedRef={printRef}
          data={{ ...selectedRowData, ...selectedRowData?.body }}
          handlePdfLoading={()=>{}}
          disabled={!selectedRowData?.number_doc} />
      </Suspense>
    </div>
  </>
  );
};
