import { Clients } from "./Clients/Clients";

export  const tabsData = [
    {
      id: 0,
      title: 'Клієнти',
      icon: '/img/pngicons/user.png',
      component: Clients,
      // tabComponent: TabButton,
    },
]