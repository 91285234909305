import { IOrderForPay, IOrderForPayItem } from 'types/datatypes/IOrderForPay';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router';
import { useCallback, useRef, useState } from 'react';
import { DocHeader, DocInfoBar, DocMenu, PartnerSearch, StickyBar } from 'components';
import dayjs from 'dayjs';
import { API_HOST, COLUMN_EDIT_WIDTH, DATE_FORMAT, FORM_STYLING_MODE } from 'app-constants';
import { DataGrid, Form, LoadPanel, TextArea, ValidationGroup } from 'devextreme-react';
import { ColCountByScreen, GroupItem, Label, SimpleItem } from 'devextreme-react/form';
import { Column, Editing, Texts, Button as DgButton, Summary, TotalItem } from 'devextreme-react/data-grid';
import { openDoc } from 'utils/open-doc';
import { IPartner } from 'types/datatypes/IPartner';
import { ORDER_FOR_PAY_QUERY } from './data/orderForPayQuery';
import { v4 as uuid_v4 } from 'uuid';

const CLASS_NAME = 'doc.blankorder';

const defaultData:IOrderForPay = {
    pos_blank:[],
    class_name:'doc.blankorder',
    doc_amount:0,
    account_kind:'Страхування',
    vat_included:false,
    date: dayjs().format(DATE_FORMAT),
    posted:false,
    partner: {
        name:'',
        ref:''
    }
}

export const createNewOrderForSave = (ref?:string):IOrderForPay=>{
    return {...defaultData,
        _id: `${CLASS_NAME}|${ref ?? uuid_v4()}`,
        partner: 'f9fcd42a-9c70-11ef-8b3e-00155d000b4b', //ББС ІНШУРАНС
    }
}

const OrderForPay = () => {
  const { id=''} = useParams();
  const isDocNew = id ==='new';

  const formGroupRef = useRef<ValidationGroup>(null);
  const dataGridRef = useRef<DataGrid>(null);

  const [data, setData] = useState<IOrderForPay>({} as IOrderForPay)

  const { loading } = useQuery(ORDER_FOR_PAY_QUERY,
    {
      variables: { ref: id },
      fetchPolicy: 'no-cache',
      onCompleted:(data)=>{
        const loadedDoc = data.orderForPay?.[0] as IOrderForPay;
        if (!loadedDoc) return;

        setData({...defaultData, ...loadedDoc} );
      }
    });

   const handleFormSave = async()=>{
    return Promise.resolve('666');
   } 

   const fileLinkRender = useCallback((cellData:any) => {
    const link = cellData?.data?.file_1c || '';
    const slashIndex = link.lastIndexOf('/');
    const renderLink = slashIndex >= 0 ? link.substring(slashIndex + 1) : link;

    return link ? (
      <a
        // className={`dx-icon-file ${styles.fileLink}`}
        href={link}
        title={renderLink}
        target='_blank'
        rel='noopener noreferrer'
        onClick={() => openDoc(link)}
      />
    ) : (
      <></>
    );
  }, []);

    console.log(data)

  return(
  <div>
  <StickyBar>
    <DocInfoBar
      name={'Рахунок до оплати'}
      data={{
        date: data?.date,
        number: data?.number_doc,
      }}
      loading={false}
      isNew={isDocNew}
    />
    <DocMenu
      isDocNew={isDocNew}
    //   allowSaving={!docReadOnly}
      onSave={handleFormSave}
      printItems={[
        {
          id: 'print',
          text: 'Довiренiсть',
          url: `${API_HOST}/printform/${data.trust_doc}/trs`,
          disabled: !data.trust_doc,
        },
      ]}
    />
  </StickyBar>
  <LoadPanel visible={loading} />
  <ValidationGroup ref={formGroupRef}>
        <div className='content-block otk-content-block'>
          <div className={'otk-doc-container otk-doc-form otk-doc-form-large dx-card'}>
            <Form labelLocation='top' formData={data}>
              <GroupItem>
                <ColCountByScreen xs={1} sm={3} md={6} lg={6} />
                <GroupItem colSpan={3}>
                  <SimpleItem colSpan={1} isRequired>
                    <Label text='Контрагент' />
                    <PartnerSearch
                      partner={data.partner as IPartner}
                      onSelect={(e) => {
                        setData((prev) => ({
                          ...prev,
                          partner: {
                            ref: e.ref || '',
                            name: e.name || '',
                          },
                        }));
                      }}
                    //   readOnly={docReadOnly}
                    //   stylingMode={FORM_STYLING_MODE}
                    //   validator={
                    //     <Validator>
                    //       <RequiredRule />
                    //     </Validator>
                    //   }
                    />
                  </SimpleItem>
                </GroupItem>
                <GroupItem colSpan={3}>
                  <SimpleItem>
                    <DocHeader number_doc={data.number_doc??''} date={data.date} />
                  </SimpleItem>
                </GroupItem>
              </GroupItem>
              {/* <ButtonItem
                horizontalAlignment='left'
                buttonOptions={{
                  text: 'Додати тип бланків',
                //   disabled: !availableTypes.length,
                  icon: 'edit',
                //   onClick: () => setShowEditForm(true),
                }}
              /> */}

              <SimpleItem 
                // cssClass={styles.dataGridItem}
                >
                <DataGrid<IOrderForPayItem>
                  ref={dataGridRef}
                  keyExpr='nomType'
                  noDataText='Список порожній'
                  remoteOperations={false}
                  showBorders
                  showColumnLines
                  allowColumnResizing
                  hoverStateEnabled
                  columnAutoWidth
                  columnResizingMode='widget'
                  dataSource={data.pos_blank}
                  errorRowEnabled={false}
                //   validationGroup={formGroupRef}
                  sorting={{ mode: 'none' }}
                //   onRowDblClick={(e) => onEditStart(e.data)}
                  onRowRemoved={(e) =>
                    setData((prev) => ({
                      ...prev,
                      pos_blank: prev.pos_blank.filter((row) => row.nomType !== e.key),
                    }))
                  }
                >
                  <Editing mode='row' allowUpdating={true} allowDeleting={true} useIcons confirmDelete={false}>
                    <Texts confirmDeleteMessage='Вилучити?' deleteRow='вилучити' />
                  </Editing>

                  {/* <Column name='edit' type='buttons' width={COLUMN_EDIT_WIDTH} allowResizing={false}>
                    <DgButton name='_edit' icon='edit' onClick={(e) => onEditStart(e.row.data)} />
                  </Column> */}

                  <Column
                    caption='Номенклатура'
                    dataField='nom.name'
                    alignment='left'
                    allowEditing={false}
                    // calculateDisplayValue={(row: any) => nomTypes[row.nomType]?.name}
                  />

                  <Column
                    dataField='quantity'
                    dataType='number'
                    allowEditing={false}
                    alignment='right'
                    caption='Кількість'
                    width={95}
                  />

                  <Column
                    dataField='price'
                    dataType='number'
                    allowEditing={false}
                    alignment='right'
                    caption='Цiна'
                    width={60}
                  />

                  <Column
                    dataField='amount'
                    dataType='number'
                    allowEditing={false}
                    calculateCellValue={(rowData:any) =>
                      rowData?.quantity && rowData?.price ? (rowData.price * rowData.quantity).toFixed?.(2) : null
                    }
                    alignment='right'
                    caption='Сума'
                  />

                  <Column
                    dataField='in_doc_number'
                    dataType='string'
                    allowEditing={false}
                    alignment='center'
                    caption='Номер (вх.док)'
                    minWidth={120}
                    width='30%'
                  />

                  <Column
                    dataField='in_doc_date'
                    dataType='date'
                    allowEditing={false}
                    alignment='center'
                    caption='Дата (вх.док)'
                    showEditorAlways={false}
                    width={120}
                  />

                  <Column
                    dataField='file_lab'
                    caption='Файл'
                    cellRender={(e) => <div className={`dx-icon-${e.data.file_lab ? 'check' : 'close'}`} />}
                    alignment='center'
                    allowEditing={false}
                    width={60}
                  />

                  <Column
                    dataField='file_1c'
                    allowEditing={false}
                    cellRender={fileLinkRender}
                    alignment='center'
                    caption='Платiжки'
                    width={100}
                  />

                  <Column type='buttons' width={COLUMN_EDIT_WIDTH} allowResizing={false}>
                    <DgButton name='delete' />
                  </Column>

                  <Summary>
                    <TotalItem column='amount' summaryType='sum' displayFormat='Разом: {0}' />
                  </Summary>
                </DataGrid>
              </SimpleItem>

              <SimpleItem>
                <Label text='Коментар' />
                <TextArea
                  value={data.note}
                  id='note'
                  hint='коментар'
                  stylingMode={FORM_STYLING_MODE}
                //   readOnly={docReadOnly}
                //   onChange={handleFieldValueChange}
                />
              </SimpleItem>
            </Form>
          </div>
        </div>
      </ValidationGroup>
  </div>
  );
};


export default OrderForPay;
