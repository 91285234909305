import styles from './status-cell-render.module.scss';
import { ColumnCellTemplateData } from 'devextreme/ui/data_grid';

export const statusCellRender = (
    { value, displayValue }:ColumnCellTemplateData,
    colorizeRules:{ [x: string]: string}| null,
    colorizeFunc?:(value:any)=>string
) => <div className={`${styles.status} otk-tag otk-status otk-status-${colorizeFunc?.(value) ?? colorizeRules?.[value] ?? 'default'}`}>
    {value?.text ?? displayValue}
</div>;
