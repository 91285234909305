import { FC, ReactNode } from 'react';
import styles from './Form-field.module.scss';

interface IProps {
  text?: string;
  textWidth?: string|number;
  fluid?: boolean,
  dfGrow?: boolean,
  wrapText?: boolean,
  mrBottom?: boolean,
  style?: object,
  children: ReactNode,
}
export const FormField:FC<IProps> = ({
  text = '',
  textWidth = '',
  fluid = false,
  mrBottom = false,
  dfGrow = false,
  wrapText = false,
  style,
  ...props
}) => {
  // const styleFromProps = props.style;
  const fmStyle = {...style, 
    display: 'flex',
    alignItems: 'center',
    width: fluid ? '100%' : '',
  };
  return (
    <div
      className={`${mrBottom && styles?.mr_bottom} form-field-wrap`}
      style={fmStyle}
      {...props}
    >
      {text && (
        <div
          style={{
            width: textWidth ? textWidth : 'auto',
            minWidth: textWidth ? textWidth : 'auto',
            fontSize: '16px',
            overflow: textWidth ? 'hidden' : 'visible',
            alignSelf: 'initial',
            paddingTop: '5px',
          }}
          className={`${dfGrow && styles?.df_grow} ${wrapText &&
            styles?.wrap_text } dx-field-label`}
        >
          {text}
        </div>
      )}

      <div style={{ width: fluid ? '100%' : '' }}>{props.children}</div>
    </div>
  );
};
