import React from "react";
import styles from './forms.module.scss';
import { ControllerRenderProps, FieldErrors, FieldPath, FieldValues } from "react-hook-form";

 

interface PropsLabeledField<V  extends FieldValues,TName extends FieldPath<V>> {
 field: ControllerRenderProps<V,TName>;
 label?:string;
 errors?:FieldErrors<V>;
 grow?:number;
 children:any;
}

export const LabeledField = <V extends FieldValues,TName  extends FieldPath<V>>(
  {
    field,
    label,
    children,
    errors,
    grow = 0
  } :PropsLabeledField<V,TName>) => {
  
    return (
      <div style={{ flexGrow: grow }}>
        <div className={styles.fieldRow}>
          <div className={styles.fieldLabel}>{label}</div>
          <div className={styles.fieldValue}>
            {React.Children.map(children, (child) => {
              return React.cloneElement(child, child.type !=='div' ? {
                ...field,
                style: { width: '100%' },
                onChange: undefined,
                onValueChanged: async (prop:any) => {
                  if (child.props?.onValueChanged) await child.props.onValueChanged(prop);
                  if (Array.isArray(field.value)){
                    if (JSON.stringify(field.value)!==JSON.stringify(prop.value)) field.onChange(prop.value);
                  } 
                  else { 
                    field.onChange(prop.value);
                  }
                }
              }:{
                ...field,
                style: { width: '100%' },
                onChange: undefined,
              });
            })}
          </div>
        </div>
        {errors?.[field.name] && <span className={styles.fieldError}> {errors?.[field.name]?.message as string}</span>}
      </div>
    );
  };