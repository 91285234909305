
import { gqlClient } from 'gql-client';
// import { loader } from 'graphql.macro';
import React, { useState, useRef, useEffect } from 'react';
import { uniqBy } from 'lodash';
import { Popup } from 'devextreme-react';
import { DataGrid, Column } from 'devextreme-react/data-grid';
import { Position } from 'devextreme-react/popup';
import { Order } from 'models/documents/order/order';
import { INom } from 'types/datatypes/INom';
import { getOrderServices } from './queries';

interface ServiceRow {
  row: number,
  nom: INom,
  quantity: number,
  amount: number,
  msto: string,
  price: number
};

interface DetailComponentProps {
  detailPopupRef: React.RefObject<Popup>,
  order: Partial<Order>,
  onSelect: (rowData:ServiceRow)=>void;
};

export const DetailComponent = (props:DetailComponentProps) => {

    const { detailPopupRef, order, onSelect } = props;
    const [ noms, setNoms ] = useState<ServiceRow[]>([]);

    const refGrid = useRef<DataGrid>(null);

    useEffect(() => {
      if (order?.ref) {
        gqlClient.query({
        query: getOrderServices,
        variables: { ref: order.ref }
      }).then(async (response) => {
          const report = response?.data?.buyers_orders?.[0];
          if (!report?.ref) throw new Error();
          const noms = report?.services ?
            uniqBy(report.services.map((sRow) => ({
               row: sRow?.row,
               name: sRow?.nom?.name,
               quantity: sRow?.quantity,
               amount: sRow?.amount,
               msto: sRow?.msto ??  false,
               price: sRow?.amount! / sRow?.quantity!
               })), 'row') : [] as ServiceRow[];
               setNoms(noms as ServiceRow[]);
        })
      } 
    }, [order?.ref, props.order]);

    return (
      <Popup
      ref={detailPopupRef}
      showCloseButton
      closeOnOutsideClick
      showTitle={false}
      width={600} height={300}
       >
        <DataGrid
          ref={refGrid}
          dataSource={noms}
          keyExpr='row'
          onFocusedRowChanged={({ row }) => {
            if (row && onSelect) onSelect(row.data);
            refGrid.current?.instance.option('focusedRowIndex', -1);
            detailPopupRef.current?.instance.hide();

          }}
          focusedRowEnabled
          >
          <Column dataField='name' dataType='string' caption='Послуга' />
          <Column dataField='quantity' dataType='number' caption='К-сть' width={60} />
          <Column dataField='msto' dataType='boolean' caption='МСТО' width={60} />
          <Column dataField='amount' dataType='number' caption='Сума' width={100} />
          <Column dataField='price' dataType='number' caption='Ціна' width={100}/>
        </DataGrid>
    <Position at="bottom" my="right top" of='#amount' />
    </Popup>
    );
  };


