import { IReport } from 'datasources/dsLabReports/IReport';
import dayjs from 'dayjs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import dxDataGrid from 'devextreme/ui/data_grid';
import { IOptions } from 'types/IOptions';

export const labels = {
    dateRange: { text: 'Виберіть діапазон дат' },
    mailcc: 'Копія на email:',
    tableHeads: {
      vehicle_map: 'Відповідність',
      number_doc: '№ документа',
      blank: 'Бланк',
      status: 'Статус',
      date: 'Дата',
      time: 'Час',
      vehicle_model: {
        name: 'Модель',
        type: {
          name: 'Тип ТЗ',
        },
        brand: {
          name: 'Марка',
        },
      },
      service: {
        name: 'Категорія',
      },
      gnumber: 'Держ.номер',
      partner_name: 'Замовник',
      individual_legal: 'Тип власника',
      pay_kind: 'Тип Опл',
      amount: 'Сума',
      rp: 'Термінал',
      rv: 'РВ',
      order: 'Замовлення',
    },
  }; 

  export const groupBy = <T=any>(list:T[], keyGetter:(a:any)=>string):{[x:string]:T[]} => {
    const map = new Map<string, T[]>();
    list?.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    const sortMap = new Map([...map].sort((a,b)=> {
      let [_a] = a; 
      let [_b] = b;
      _a= _a.split('-').reverse().join('-');
      _b= _b.split('-').reverse().join('-');
      if (_a < _b) {
        return 1
      } else if (_a > _b) {
        return -1
      }
      // a === b
      return 0
    }));

    return Object.fromEntries(sortMap.entries());
  };

  interface processRowDataProps {
    data: IReport[],
    payKindByRef:(ref?:string)=>any,
    options:IOptions,
    mapNoms:Map<any,any>,
    isLabRv: boolean 
  }

  export const processRowData = ({data, payKindByRef, options, mapNoms, isLabRv}:processRowDataProps) => {
    const { partUUID, partValue, teminalUUID } = options.easyPay;

    const _totals: { [x: string]: number } = { 'Каса': 0 };
    data.forEach((r) => {
      r.individual_legal = r.partner?.individual_legal === 'ЮрЛицо' ? 'Юр' : 'Фіз';
      r.time = dayjs(r.date).format('HH:mm:ss');
      r.blank = `${r.blank_series ?? '-'} ${r.blank_number ? String(r.blank_number).padStart(6, '0') : ''}`;
      r.pay_kind = payKindByRef(r?.invoice?.pay_kind)?.name ?? 'Безготівка';
      r.partner_name = r.partner?.name ?? '';
      r.invoiceRef = r.invoice?.ref;
      r.invoiceNumberDoc = r.invoice?.number_doc;
      if (mapNoms.has(r.service?.ref)) r.service = mapNoms.get(r.service.ref);
  
      // const invoiceRow = r?.invoice?.services.find((s) => r.service.ref === s.nomRef);
      // const isMsto = invoiceRow?.msto;
  
      // column "rp"
      if (r?.invoice?.pay_kind === partUUID) { r.rp = Math.min(partValue, r.amount); }
      else if (r?.invoice?.pay_kind === teminalUUID) { r.rp = r.amount; }
  
      //
      // if (!r.amount && !r.source_report && r.status !== 'Аннулирован') {
      //   const invoiceAmount = invoiceRow?.amount ?? 0;
      //   if (r?.invoice?.pay_kind === partUUID) {
      //     r.amount = invoiceAmount;
      //     r.rp = Math.min(partValue, r.amount);
      //   } else if (r?.invoice?.pay_kind === teminalUUID) {
      //     r.amount = invoiceAmount;
      //   }
      // }
  
      // if (r?.invoice?.isSubContract && r.amount) {
      //   r.amount = r?.invoice?.ext_json?.subNomsPrice?.find((row) => row.nomRef === r.service.ref)?.price || r.amount;
      //   r.partner_name = `${r.partner_name} (${r.invoice.ext_json?.subPartnerName || ''})`;
      //   if (isMsto) r.amount += orderOptions.mstoValue;
      // }
  
      if (r?.invoice?.badact_reason !== null && r?.invoice?.badact_reason !== 'Платний') r.amount = 0;
  
      if (r.status === 'Аннулирован') r.rp = r.amount ? r.rp : 0;
  
      if (r.amount && r?.invoice?.pay_kind === partUUID) {
        const cash = r.amount - partValue;
        _totals[r.pay_kind] = (_totals[r.pay_kind] || 0) + partValue;
        _totals['Каса'] += cash;
      } else {
        _totals[r.pay_kind] = (_totals[r.pay_kind] || 0) + r.amount;
      }
  
      if (r.rv && isLabRv) _totals['РВ'] = (_totals['РВ'] || 0) + r.rv;
      // delete r.partner;
      // delete r.invoice;
    });
    return _totals;
  };

  export const processTotal = (data:IReport[]) => data.reduce((prev: { [x: string]: number }, curr: IReport) => {
    return {
      ...prev,
      total:    prev.total    + 1,
      inactive: prev.inactive + (curr.status === 'Недействителен' ? 1 : 0),
      annul:    prev.annul    + (curr.status === 'Аннулирован' ? 1 : 0),
      badact:   prev.badact   + (curr.vehicle_map ? 0 : 1)
    }
  }, {
    total: 0,
    inactive: 0,
    annul: 0,
    badact: 0
  });
  
  

  interface GridToExcellProps {
    dataGridInstance: dxDataGrid<IReport, any>;
    totals: {[x: string]: number };
    texts: {
      header:string;
      period: string;
    }
  }

  export const totalByKeyLegend = {
    total: 'Всього',
    inactive: 'Недійсні',
    annul: 'Анульовані',
    badact:'Акт невідпов.'
  }

  export const gridToExcell = async ({dataGridInstance, totals, texts}: GridToExcellProps) => {
    const { Workbook } = await import('exceljs');
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Поточний');
    let cell = worksheet.getCell(1, 5);
    cell.value = texts.header;
    cell.font = {size:16, bold:true};
    cell = worksheet.getCell(2, 5);
    cell.value = texts.period;
    cell.font = {size:14, bold:true};

    const legendPos = {r:1, c:2};
    Object.keys(totals).forEach(key=>{
      const r = legendPos.r++; 
      const cellName = worksheet.getCell(r, legendPos.c);
      const cellValue = worksheet.getCell(r, legendPos.c+1);
      cellName.value = totalByKeyLegend[key as keyof typeof totalByKeyLegend];
      cellValue.value = totals[key];
      
      cellName.font = {size:14};
      cellValue.font = {size:14};
    })

    dataGridInstance?.columnOption('TypeName', 'visible', true);
    dataGridInstance?.columnOption('BrandName', 'visible', true);
    dataGridInstance?.columnOption('ModelName', 'visible', true);
    await exportDataGrid({
      component: dataGridInstance,
      worksheet,
      topLeftCell: { row: 6, column: 1 },
      customizeCell: ({ gridCell, excelCell }) => {
        if (gridCell?.rowType !== 'header' && excelCell.col === 1) excelCell.value = excelCell.value ? '\u{2705}' : '';
      },
    })

    const res = await workbook.xlsx.writeBuffer();

    dataGridInstance?.columnOption('TypeName', 'visible', false);
    dataGridInstance?.columnOption('BrandName', 'visible', false);
    dataGridInstance?.columnOption('ModelName', 'visible', false);

    return res
  }

  export const formatter = (value:number, precision = 0)=>(value.toFixed(precision));