import { createDataSource } from 'utils/gql-datasource';
import uniqBy from 'lodash/uniqBy';
import orderBy from 'lodash/orderBy';
import { gql } from 'apollo';

const query = gql(`query dsGetOrdersLookup($ref: String, $limit: Int, $jfilt: [JSON], $offset: Int, $sort: sort, $requireTotalCount: Boolean!) {
  totalcount: buyers_orders( ref: $ref, jfilt: $jfilt, totalCount: 1 ) @include(if: $requireTotalCount) {totalcount}
  list: buyers_orders(ref: $ref, limit: $limit, jfilt: $jfilt, offset: $offset, sort: $sort) {ref number_doc date}
}`)

export const dsOrdersLookup = createDataSource(query, {
  processData: (data) => orderBy(uniqBy(data, 'number_doc'), [ 'number_doc' ], [ 'asc' ])
});

