import { useMemo } from 'react';
import { Menu } from 'devextreme-react';
import { messages } from 'messages';
import { useNavigate, useLocation } from 'react-router';
import { showError, showSuccess } from 'utils/notify';
import { openDoc } from 'utils/open-doc';
import { ItemInfo, MenuItem } from 'types/otkTypes';

import styles from './doc-menu.module.scss';

interface DocMenuProps {
  allowSaving?: boolean;
  actionItems?: Array<MenuItem>;
  isDocNew?: boolean
  printItems?: Array<MenuItem>;
  rightItems?: Array<MenuItem>;
  onSave: ()=>Promise<string>;//

}

export const DocMenu = ({
  printItems = [],
  actionItems = [],
  rightItems = [],
  allowSaving = true,
  isDocNew,
  onSave
  // ...props
}:DocMenuProps) => {
  const navigate = useNavigate();
  const path = useLocation().pathname;
  const basePath = path.substring(0, path.lastIndexOf('/'));

  const items:Array<MenuItem> = useMemo(() => [
    {
      id: 'ok',
      text: 'Зберегти і закрити',
      icon: 'save',
      disabled: !allowSaving,
    },
    {
      id: 'close',
      text: 'Закрити',
      icon: 'close',
    },
    {
      id: 'save',
      text: 'Зберегти',
      icon: 'save',
      disabled: !allowSaving,
    },

    ...(printItems.length > 0 ?
      [
        {
          text: 'Друк',
          icon: 'print',
          items: printItems.map((item) => ({ ...item, id: 'print' })),
          disabled: printItems.every((item) => item.disabled),
        },
      ] :
      []
    ),
  ], [ allowSaving, printItems ]);

  return (
    <div className={styles.menubar}><Menu
      id='MainMenu'
      className='otk-toolbar'
      onItemClick = {(e:ItemInfo) => {
        const itemData = e.itemData;
        switch (itemData?.id) {
        case 'ok': {
          onSave()
            .then(() => {
              showSuccess(messages.DOC_SAVED);
              navigate(-1);
            })
            .catch((e) => showError(e));
          break;
        }
        case 'save': {
          onSave()
            .then((ref) => {
              showSuccess(messages.DOC_SAVED);
              if (isDocNew) {
                //delay for replication done for new objects
                setTimeout(
                  () => navigate(`${basePath}/${ref}`, { replace: true }),
                  1000);
              }
            })
            .catch((e) => showError(e));
          break;
        }
        case 'close': {
          navigate(-1);
          break;
        }
        case 'print': {
          if (itemData?.onClick) {
            itemData.onClick();
          } else {
            openDoc(itemData?.url ?? '');
          }
          break;
        }
        default:
        }
      } }
      dataSource={items}>
    </Menu>
    <Menu className={`${styles.endbuttons} otk-toolbar`}
      id='RightMenu'
      items={ actionItems.length>0 || rightItems.length>0 ?
        [ ...rightItems,
           {
            icon: 'toolbox',
            visible: actionItems.length>0,
            items: actionItems.map((item) => ({ ...item, id: 'action' })),
            disabled: actionItems.every((item) => item.disabled),
          },
        ] :
        []
      }
    />
    </div>
  );
};

