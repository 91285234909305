import { gql } from 'apollo';
import { createDataSource } from 'utils/gql-datasource';

const dsGetNews = gql(`query dsNews($ref: String, $limit: Int, $jfilt: [JSON], $offset: Int, $sort: sort, $requireTotalCount: Boolean!) {
  totalcount: getnews(ref: $ref, jfilt: $jfilt, totalCount: 1) @include(if: $requireTotalCount) { totalcount }
  list: getnews(ref: $ref, limit: $limit, jfilt: $jfilt, offset: $offset, sort: $sort) { 
    id date ref name images main_text need_approve news_priority
  }
}
`);

export const dsNews = createDataSource(dsGetNews, {
  processData: (data) => (
    data?.map((row) => ({ ...row }))
  ),
});


