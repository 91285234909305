import React, { FC } from 'react';
import styles from '../../styles/style.module.scss';
import { IInsurancePolicy } from 'types/datatypes/IInsurance';

interface CardPaymentFormProps {
  data:IInsurancePolicy
}

export const CardPaymentForm:FC<CardPaymentFormProps> = ({ data }) => (
  <div className={styles?.iframe_container}>
    <iframe src={data.policy_payment?.forwardUrl} title='оплата'/>
  </div>
);

export default CardPaymentForm;
