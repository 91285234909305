import { DocContext } from 'contexts/docContext';
import { DataGrid } from 'devextreme-react';
import { Column, Paging, StateStoring, Summary, TotalItem, LoadPanel } from 'devextreme-react/data-grid.js';
import Toolbar, { Item } from 'devextreme-react/toolbar';
// import { useTraceUpdate } from 'hooks/useTraceUpdate.js';
import { useContext, useRef } from 'react';
import { OrderSelector } from './OrderSelector.jsx';
import { useNavigate } from 'react-router';

const columnChooserOptions = {
  enabled: false,
  mode: 'select',
  height: 350,
};

export const OrdersGrid = () => {
  const navigate = useNavigate();

  const ordersDataGridRef = useRef();
  const selectorRef = useRef();



  const {  state: { doc } } = useContext(DocContext);
  const { 
    // _isReadOnly: docReadOnly,
     orders: dataSource, proj } = doc;

  const ordersDataGridInstance = ordersDataGridRef.current?.instance;

  // useTraceUpdate({ dataSource, doc, grid, docReadOnly });

  const addButtonOptions = {
    onClick: () => selectorRef.current.show(),
    text: '+ Підбір',
  };

  const columnsButtonOptions = {
    icon: 'columnchooser',
    onClick: () => ordersDataGridInstance?.showColumnChooser(),
    hint: 'Видимість колонок',
  };

  const onSelected = (selectedRows, orgRef, labRef) => {
    const mapOrders = selectedRows.map((r) => ({
      ref: r.ref,
      caption: r.caption,
      amount: r.doc_amount,
      partnerName: r.partner?.name,
    }));
    dataSource.length = 0;
    dataSource.push(...mapOrders);
    ordersDataGridRef.current?.instance?.refresh(true);
    doc.organization = orgRef;
    doc.department= labRef;
  };

  return (
    <>
      <Toolbar className='otk-toolbar dx-theme-border-color'>
        <Item widget='dxButton' location='before' locateInMenu='auto' options={addButtonOptions} />
        <Item widget='dxButton' location='after' locateInMenu='auto' options={columnsButtonOptions} />
      </Toolbar>
      <DataGrid
        ref={ordersDataGridRef}
        keyExpr={'row'}
        noDataText='Список порожній'
        allowHiding={false}
        allowColumnResizing
        allowColumnReordering={true}
        // columnAutoWidth
        columnChooser={columnChooserOptions}
        // columnResizingMode='widget'
        dataSource={dataSource}
        errorRowEnabled={false}
        // focusedRowEnabled={!docReadOnly}
        hoverStateEnabled
        remoteOperations={false}
        // selectTextOnEditStart
        showBorders
        showColumnLines
        showRowLines={false}
        onRowDblClick={({rowType, data})=>{
          if (rowType==='data' && data?.ref) navigate(`/order/${data.ref}`)
        }}
      >
        <StateStoring enabled={true} type='localStorage' storageKey='storageOrdersGrid' ignoreColumnOptionNames={[]} />
        <Paging enabled={false} />
        <LoadPanel enabled={false}/>
        <Column dataField='caption' caption='Замовлення' minWidth={310} editorOptions={{ searchMode: 'contains' }} />
        <Column dataField='partnerName' caption='Контрагент' minWidth={310}  allowEditing={false} />
        <Column dataField='amount' caption='Сума' widthidth={100} dataType='number' />
        <Summary>
          <TotalItem
            column='amount'
            summaryType='sum'
            displayFormat='Разом: {0}'
            valueFormat={(value) => value.toFixed(2)}
          />
        </Summary>
      </DataGrid>
      <OrderSelector ref={selectorRef} ordersList={dataSource} proj={proj} onSelected={onSelected} />
    </>
  );
};
