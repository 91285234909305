export const ProcessTag = () => (
  <div className={"otk-tag otk-status-warning"}>
    <div className="dx-icon-warning"></div>
    &nbsp; на обробці
  </div>
);

export const ReadOnlyTag = () => (
  <div className={"otk-tag otk-status-warning"}>
    <div className="dx-icon-warning"></div>
    &nbsp;Документ тільки для перегляду
  </div>
);

export const StatusTag = (status:{status:string, text:string}) => (
  <div className={`otk-tag otk-status-${status?.status}`}>
    {status?.text}
  </div>
);
