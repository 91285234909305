import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDate } from 'hooks';
import dayjs from 'dayjs';
//components
import { FormField } from 'components/form-field/form-field';
import { TextLine } from 'pages/ep-main/components/text-line';
import {
  LoadIndicator,
  TextBox,
  Button,
  DateBox,
  SelectBox,
  CheckBox,
  ValidationSummary,
  ValidationGroup,
} from 'devextreme-react';
import Validator, {
  RequiredRule,
  PatternRule,
  StringLengthRule,
  RangeRule,
} from 'devextreme-react/validator';
import { SearchCity } from './searchCity';
import {
  getVehicleByPlateNum,
  getVehicleTypesList,
  getPeriodsList,
  initApi,
} from '../api/requests';

import {
  pVehiclevVIN,
  pNum,
} from 'moks/patterns';
import { checkVehicleTypeCDE } from './utils';
import styles from '../styles/style.module.scss';
import { useAuth } from 'contexts';
import { normalizeCarNumber } from 'utils/normalize-car-number';


const policy_types_list = [ { title: 'ОСАГО', value: 'osago' },
  // { title: 'КАСКО', value: 'kasko' }
];
const type_of_insured_person_list = [ 'Фіз. особа', 'Юр. особа' ];

const required = 'обов`язкове поле';

export const Part1 = (props) => {
  const { today, formatDate } = useDate();
  const { setData = () => {}, data = {
    next_service_date: null,
    start_date: today.add(1, 'day'),
    period: 'year',
  } } = props;
  const groupRef = useRef();
  const [ loading, setLoading ] = useState(false);
  const [ vehicleTypesList, setVehicleTypesList ] = useState([]);
  const [ periodsList, setPeriodsList ] = useState([]);


  const { options } = useAuth();
  initApi(options.assurance);

  //валідація полів, перехід на наступний крок
  const handleSearch = () => {
    const validate = groupRef?.current?.instance?.validate();
    if (validate?.status === 'valid') {
      setData((prev) => ({ ...prev, step: 2 }));
    } else {
      console.log('No valid');
    }
  };

  //пошук по номеру авто
  const handleGetVehicle = async () => {
    setLoading(true);
    const vehicle = await getVehicleByPlateNum(data?.search_plate_num);
    if (vehicle) {
      setData((prev) => ({
        ...prev,
        vehicle_no_search_data: false,
        vehicle_plate_num: vehicle?.plate_num,
        vehicle_type: vehicle?.type,
        vehicle_year: vehicle?.year,
        vehicle_vin: vehicle?.vin,
        vehicle_mark: vehicle?.mark?.name,
        vehicle_model: vehicle?.model?.name,
        next_service_date: null,
        start_date: today.add(1, 'day'),
        period: 'year',
      }));
    } else {
      setData((prev) => ({
        ...prev,
        vehicle_no_search_data: true,
      }));
    }

    setLoading(false);
  };

  //зберігаємо дані з полів
  const handleValueChanged = (e) => {
    let value = e?.event?.target?.value || e?.value;
    if (e?.element?.id === 'vehicle_vin' ||
    e?.element?.id === 'vehicle_plate_num' ||
    e?.element?.id === 'search_plate_num') value = normalizeCarNumber(value);
    setData((prev) => ({
      ...prev,
      [e?.element?.id]: value }));
  };
  //зберігаємо дані для полів з датами
  const handleDateChanged = (e) => {
    setData((prev) => ({
      ...prev,
      [e?.element?.id]: formatDate(e?.value) }));
  };
  //отримуємо список для вибору типу ТЗ і періоду дії полісу
  useEffect( () => {
    const aFunc = async () => {
      setLoading(true);
      const typesList = await getVehicleTypesList();
      setVehicleTypesList(typesList);
      const periodsList = await getPeriodsList();
      setPeriodsList(periodsList);
      setLoading(false);
    };
    aFunc();
  }, []);
  //зануляємо дані полів при зміні типу ТЗ якщо не попадають в категорію CDE
  useEffect(() => {
    if (!checkVehicleTypeCDE(data?.vehicle_type)) {
      setData((prev) => ({
        ...prev,
        next_service_date: null,
        // start_date: null,
        // period: null,
      }));
    }
  }, [data?.vehicle_type, setData]);
  //змінюємо період дії полісу залежно в дати останього сервісу і дати початку
  //дії полісу
  useEffect(() => {
    if (data?.next_service_date && data?.start_date) {
      const difference = dayjs(data?.next_service_date).diff(data?.start_date, 'month');
      if (difference < 1) {
        setData((prev) => ({ ...prev, period: '15_days' }));
      } else if (difference > 11) {
        setData((prev) => ({ ...prev, period: 'year' }));
      } else {
        setData((prev) => ({ ...prev, period: `${difference}_month` }));
      }
    }
  }, [data?.next_service_date, data?.start_date, setData]);

  return (<>
    <h3>Крок 1</h3>
    <ValidationGroup ref={groupRef}>
      <FormField textWidth='300px' text='Тип страхувальника: '>
        <SelectBox
          id='type_of_insured_person'
          items={type_of_insured_person_list}
          onValueChanged={handleValueChanged}
          value={data?.type_of_insured_person}
          wrapItemText={true}
          stylingMode='outlined'
          width='200px'
        >
          <Validator>
            <RequiredRule message={`Тип страхувальника - ${required}`}/>
          </Validator>
        </SelectBox>
      </FormField>
      <br />
      <FormField textWidth='300px' text='Тип полісу: '>
        <SelectBox
          id='policy_type'
          items={policy_types_list}
          onValueChanged={handleValueChanged}
          value={data?.policy_type}
          displayExpr='title'
          valueExpr='value'
          wrapItemText={true}
          stylingMode='outlined'
          width='200px'
        >
          <Validator>
            <RequiredRule message={`Тип полісу - ${required}`}/>
          </Validator>
        </SelectBox>
      </FormField>
      <br />
      <FormField textWidth='300px' text='Державний номер авто: '>
        <TextBox
          id='search_plate_num'
          value={data?.search_plate_num }
          stylingMode='outlined'
          onValueChanged={handleValueChanged}
          width='200px'
        >
          <Validator>
            <RequiredRule message={`Держ номер авто - ${required}`}/>
          </Validator>
        </TextBox>
      </FormField>
      <br />
      <FormField textWidth='300px' text='Пошук в базі МТСБУ по номеру:'>
        <Button onClick={handleGetVehicle} disabled={loading}>
          Перевірити
        </Button>
      </FormField>
      <br />
      <TextLine text='дані авто'/>
      <div className={styles?.res_title}>
        <span>Результати перевірки</span>
        {loading &&
          <LoadIndicator
            className={styles?.res_loader}
            indicatorSrc='img/loader.gif'
            width={50}
            height={50}
          />
        }
        {data?.vehicle_no_search_data && !loading &&
          <span className={styles?.red}>(Даних не знайдено)</span>
        }
      </div>
      <br />
      <FormField textWidth='300px' text='Державний номер авто: '>
        <TextBox
          id='vehicle_plate_num'
          value={data?.vehicle_plate_num }
          stylingMode='outlined'
          onValueChanged={handleValueChanged}
          width='200px'
        >
          <Validator>
            <RequiredRule message={`Державний номер авто - ${required}`}/>
            <StringLengthRule
              ignoreEmptyValue={true}
              max={8}
              message='довжина не більше 8 знаків'
            />
          </Validator>
        </TextBox>
      </FormField>
      <br />
      <FormField textWidth='300px' text='Марка: '>
        <TextBox
          id='vehicle_mark'
          value={data?.vehicle_mark}
          stylingMode='outlined'
          onValueChanged={handleValueChanged}
          width='200px'
        >
          <Validator>
            <RequiredRule message={`Марка - ${required}`} />
            <StringLengthRule
              ignoreEmptyValue={true}
              min={2}
              max={100}
              message='довжина від 2 до 100 символів'
            />
          </Validator>
        </TextBox>
      </FormField>
      <br />
      <FormField textWidth='300px' text='Модель: '>
        <TextBox
          id='vehicle_model'
          value={data?.vehicle_model}
          stylingMode='outlined'
          onValueChanged={handleValueChanged}
          width='200px'
        >
          <Validator>
            <RequiredRule message={`Модель - ${required}`}/>
            <StringLengthRule
              ignoreEmptyValue={true}
              min={1}
              max={100}
              message='довжина від 1 до 100 символів'
            />
          </Validator>
        </TextBox>
      </FormField>
      <br />
      <FormField textWidth='300px' text='VIN: '>
        <TextBox
          id='vehicle_vin'
          value={data?.vehicle_vin}
          stylingMode='outlined'
          onValueChanged={handleValueChanged}
          width='200px'
        >
          <Validator>
            <RequiredRule message={`VIN - ${required}`} />
            <PatternRule
              message='VIN має хибний формат'
              pattern={pVehiclevVIN}
            />
          </Validator>
        </TextBox>
      </FormField>
      <br />
      <FormField textWidth='300px' text='Тип ТЗ: '>
        <SelectBox
          id='vehicle_type'
          items={vehicleTypesList}
          onValueChanged={handleValueChanged}
          value={data?.vehicle_type}
          displayExpr='title'
          valueExpr='value'
          showClearButton={true}
          wrapItemText={true}
          stylingMode='outlined'
          width='200px'
        >
          <Validator>
            <RequiredRule message={`Тип ТЗ - ${required}`} />
          </Validator>
        </SelectBox>
      </FormField>
      <br />
      {data?.vehicle_type === 'B3' && <FormField textWidth='520px'
        text={`(дозволяє зробити пільгу для типу
         В3 з об'ємом двигуна <= 2500)`} >
        {''}
      </FormField>}
      <FormField textWidth='300px' text='Об`єм двигуна(см3): '>
        <TextBox
          id='vehicle_engine_volume'
          value={data?.vehicle_engine_volume}
          onValueChanged={handleValueChanged}
          stylingMode='outlined'
          width='200px'
        >
          <Validator>
            <PatternRule pattern={pNum} message='тільки цифри' />
            {data?.vehicle_type === 'B3' &&
             <RequiredRule message={`Об'єм двигуна - ${required}`} />
            }
            {data?.vehicle_type === 'A1' &&
             <RangeRule max={300} message=' допустиме менше 300' />
            }
            {data?.vehicle_type === 'A2' &&
             <RangeRule min={300} message=' допустиме більше 300' />
            }
            {data?.vehicle_type === 'B2' &&
             <RangeRule min={1601} max={2000}
               message=' допустиме значення (1601 - 2000)' />
            }
            {data?.vehicle_type === 'B3' &&
              <RangeRule min={2001} max={3000}
                message=' допустиме значення (2001 - 3000)' />
            }
            {data?.vehicle_type === 'B4' &&
            <RangeRule min={3001} message=' допустиме значення понад 3000' />
            }
          </Validator>
        </TextBox>
      </FormField>
      <br />
      <FormField textWidth='300px' text='Рік випуску: '>
        <TextBox
          id='vehicle_year'
          value={data?.vehicle_year}
          onValueChanged={handleValueChanged}
          stylingMode='outlined'
          width='200px'
        >
          <Validator>
            <RequiredRule message={`Рік випуску - ${required}`} />
            <PatternRule pattern={pNum} message='тільки цифри' />
            <RangeRule
              min={1950} max={today.get('year')}
              message={`від 1950 до ${today.get('year')}`}
            />
          </Validator>
        </TextBox>
      </FormField>
      {checkVehicleTypeCDE(data?.vehicle_type) &&
      (<>
        <FormField textWidth='500px'
          text='_________________________________________________________'>
          {''}
        </FormField>
        <FormField textWidth='500px'
          text="Обов'язкові поля для типів ТЗ: C1, C2, D1, D2, E.">
          {''}
        </FormField>
        <FormField textWidth='300px' text='Дата наступного ОТК: '>
          <DateBox
            value={data?.next_service_date}
            id='next_service_date'
            type='date'
            displayFormat='dd-MM-yyyy'
            useMaskBehavior={true}
            stylingMode='outlined'
            onValueChanged={handleDateChanged}
            hint='Дата наступного ОТК'
            dateOutOfRangeMessage={
              'мінімальне значеня +15 днів від поточної дати і ' +
              'максимальне 2 роки/(пів року для D1, D2)'
            }
            min={today?.add(16, 'day')}
            max={
              data?.vehicle_type.startsWith('D') ?
                today?.add(1, 'day')?.add(6, 'month') :
                today?.add(1, 'day')?.add(2, 'year')
            }
            width='200px'
          >
            <Validator>
              <RequiredRule message={`Дата наступного ОТК - ${required}`} />
            </Validator>
          </DateBox>
        </FormField>
      </>)
      }
      <br />
      <FormField textWidth='300px' text='Дата початку дії полісу: '>
        <DateBox
          value={data?.start_date ?? today.add(1, 'day')}
          id='start_date'
          type='date'
          displayFormat='dd-MM-yyyy'
          useMaskBehavior={true}
          stylingMode='outlined'
          onValueChanged={handleDateChanged}
          hint='Дата початку дії полісу'
          // dateOutOfRangeMessage={
          //   'максимальне 15 днів до дати наступного ОТК'
          // }
          min={today.add(1, 'day')}
          // max={dayjs(data?.next_service_date)?.subtract(15, 'day')}
          width='200px'
          // disabled={!data?.next_service_date}
        >
          <Validator>
            <RequiredRule message={`Дата початку дії полісу - ${required}`}/>
          </Validator>
        </DateBox>
      </FormField>
      <br />
      {/* <FormField textWidth={'520px'} text=
        {'(Значення залежить від дат: наступного ОТК і початку дії полісу.)'}
      >
        {''}
      </FormField> */}
      <FormField textWidth='300px' text='Строк дії договору: '>
        <SelectBox
          id='period'
          items={periodsList}
          onValueChanged={handleValueChanged}
          value={data?.period ?? 'year'}
          displayExpr='name'
          valueExpr='code'
          showClearButton={true}
          wrapItemText={true}
          stylingMode='outlined'
          width='200px'
          disabled={false}
        >
          <Validator>
            <RequiredRule message={`Термін дії полісу - ${required}`} />
          </Validator>
        </SelectBox>

      </FormField>
      <FormField textWidth={'500px'}
        text='_________________________________________________________'>
      </FormField>
      <br />
      <SearchCity setData={setData} data={data} />
      <CheckBox
        id='agreement'
        value={data?.agreement}
        onValueChanged={handleValueChanged}
        text='Згоден з обробкою персональних даних та угодою користувача'
      />
      <br />
      <br />
      <CheckBox
        id='no_taxi'
        value={data?.no_taxi}
        onValueChanged={handleValueChanged}
        text='ТЗ НЕ використовується як таксі/маршрутне таксі'
      />
      <br />
      <br />
      <ValidationSummary id='summary'/>
      <br />
      <FormField textWidth='300px' text='Пошук пропозицій СК:'>
        <Button onClick={handleSearch}
          disabled={!data?.agreement || !data?.no_taxi}
        > Пошук
        </Button>
      </FormField>
      <br />
      <br />
    </ValidationGroup>
  </>);
};

Part1.propTypes = {
  setData: PropTypes.func,
  data: PropTypes.object,
};

export default Part1;
