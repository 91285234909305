import dayjs from 'dayjs';
import { v4 as uuid_v4 } from 'uuid';
import { loader } from 'graphql.macro';
import { policyReserve, policySendOtp, policyVerifyOtp, paymentCreateOrder, policyConfirm } from '../api/requests';
import { IInsuranceCompany, IInsurancePolicy, TCarCategories } from 'types/datatypes/IInsurance';
import { ApolloClient } from '@apollo/client';
import { IDocProps } from 'types/datatypes/classes';
import { showError } from 'utils/notify';


const updateAssurance = loader('../gql/updateAssurance.graphql');

interface ICallPolicyProps {
  setData: (state:any) => void,
  data: IInsurancePolicy 
};

//перевірка типу ТЗ
//для категорій CDE - додатково потрібно вказати Дату наступного ОТК
export const checkVehicleTypeCDE = (type:TCarCategories) => [ 'C1', 'C2', 'D1', 'D2', 'E' ]
  .includes(type);
//для категорій AB- можливо обрати пільгу
export const checkVehicleTypeAB1_B3 = (type:TCarCategories) => [ 'A1', 'A2', 'B1', 'B2', 'B3' ]
  .includes(type);

//фільтруємо дані по наявності в них франшизи
//використовується при зміні юзером франшизи потрібно відфільтрувати
//список попозицій від СК у яких є ця франшиза
export const filterByFranchise = (dataArray:IInsuranceCompany[], franchise:any) => {
  const res:any[] = [];
  dataArray.forEach((element) => {
    element?.tariffs?.forEach((item:any) => {
      if (item?.franchise === franchise) res.push(element);
    });
  });
  return res;
};

//+++ 2023-05-17 функціонал перенесено на сторону бек-енду
//підтвердження оплати
// виклик лише з картки поліса - перевірка оплати
export const confirmPolicyPay = async ({ setData, data }:ICallPolicyProps) => {
  const SEND_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
  const { date, payment_id, sign } = data.policy_easyPay;
  const policyConfirmData = {
    policy_number: data.policy_verify?.policy_number || data.policy_number,
    payed_at: dayjs(date).format(SEND_DATE_FORMAT),
    paysystem: 'easypay',
    payment_receipt_id: payment_id,
    sign,
  };
  const confirm = await policyConfirm(policyConfirmData);
  if (confirm) {
    data.policy_confirm = confirm;
    data.policy_status = 'confirmed';
    setData((prev:IInsurancePolicy) => ({
      ...prev,
      policy_confirm: confirm,
      policy_status: 'confirmed',
    }));
  } else {
    showError('Помилка відправки підвердження');
  }
};


//отримання списку документів зі списку пільгів
export const getDocumentsFromPrivileges = (dataInput:any) => {
  const res:any = {};
  dataInput?.forEach((element:any) => {
    const documents:any[] = [];
    element?.documents?.forEach((item:any) => {
      documents.push({ name: item?.name, code: item?.code });
    });
    res[element?.code] = documents;
  });
  return res;
};

//+++ 2023-05-17 функціонал перенесено на сторону бек-енду
//підтвердження оплати
// виклик лише з картки поліса - перевірка оплати


//резервування полісу
export const reservePolicy = async ({ setData, data }:ICallPolicyProps) => {
  const DATE_FORMAT = 'YYYY-MM-DD';
  const saveUuidOrderId = data?.ref || uuid_v4();
  const { companie } = data;

  //const amount = 1;
  //TEST
  const amount = companie?.selectedPrice;

  setData((prev:IInsurancePolicy) => ({ ...prev, policy_reserve: null, policy_verify: null, policy_payment: null }));
  // const paysystems = await getPaysystems();
  // console.log('paysystems', paysystems);
  // ==== Поля для резервування полісу =====
  const phone_normalized = data?.phone?.replace('+', '')?.replace('(', '')
    ?.replace(')', '')?.replace('-', '');
  const requestOptins = {} as IInsurancePolicy;
  requestOptins.start_date = data?.start_date
    ? dayjs(data?.start_date).format('YYYY-MM-DD HH:mm:ss')
    : dayjs(data?.policy_start_date).format('YYYY-MM-DD HH:mm:ss');
  requestOptins.pay_sum = amount;
  requestOptins.privilege = data?.privilege;
  requestOptins.vehicle_type = data?.vehicle_type;
  requestOptins.vehicle_plate_num = data?.vehicle_plate_num;
  requestOptins.vehicle_mark = data?.vehicle_mark;
  requestOptins.vehicle_model = data?.vehicle_model;
  requestOptins.vehicle_year = data?.vehicle_year;
  requestOptins.vehicle_vin = data?.vehicle_vin;
  if (data?.vehicle_engine_volume) requestOptins.vehicle_engine_volume = data?.vehicle_engine_volume;
  requestOptins.vehicle_city_id = data?.vehicle_city_id;
  requestOptins.company = companie?.code;
  requestOptins.franchise = companie?.selectedFranchise;
  requestOptins.firstname = data?.firstname;
  requestOptins.lastname = data?.lastname;
  if (data?.middlename) requestOptins.middlename = data?.middlename;
  requestOptins.birth_date = dayjs(data?.birth_date).format(DATE_FORMAT);
  requestOptins.egrpou = data?.egrpou;
  requestOptins.email = data?.email;
  requestOptins.phone = data?.phone?.replace('+380(', '')?.replace(')', '')?.replace('-', '');
  requestOptins.region = data?.region;
  requestOptins.city = data?.city;
  requestOptins.street = data?.street;
  requestOptins.house = data?.house;
  if (data?.flat) requestOptins.flat = data?.flat; //не обов'язкове
  requestOptins.document_type = data?.document_type;
  if (data?.document_type !== 'id_card') requestOptins.document_series = data?.document_series;
  
  requestOptins.document_number = data?.document_number;
  requestOptins.document_issue_date = dayjs(data?.document_issue_date).format(DATE_FORMAT);
  requestOptins.document_issued = data?.document_issued;
  if (data?.next_service_date) requestOptins.next_service_date = dayjs(data?.next_service_date).format(DATE_FORMAT);
  if (data?.period) requestOptins.period = data?.period;
  if (data?.document_type === 'id_card') requestOptins.document_record = data?.document_record;
  // ======================================================
  // Резервування полісу
  const reserve:any = await policyReserve(requestOptins);
  // console.log('reserve', reserve);
  if (!!reserve?.policy_number) {
    setData((prev:IInsurancePolicy) => ({ ...prev, policy_reserve: reserve, phone_normalized }));
    // Отримання otp паролю
    const otp:any = await policySendOtp({ policy_number: reserve?.policy_number });
    if (otp?.otp) {
      // Перевірка otp паролю
      const verify_otp:any = await policyVerifyOtp({ policy_number: otp?.policy_number, otp: otp?.otp });
      if (verify_otp?.requisites) {
        setData((prev:IInsurancePolicy) => ({ ...prev, policy_verify: verify_otp }));
        //test Payment
        const PayerPhone = data?.phone?.replaceAll('+', '')?.replaceAll('-', '')?.replaceAll('(', '')?.replaceAll(')', '');
        const expire = dayjs(reserve.policy_created_at).add(1, 'day').format('YYYY-MM-DDTHH:mm:ss');
        const test_payment:any = await paymentCreateOrder({
          userInfo: {
            phone: data?.phone,
            firstName: data?.firstname,
            lastName: data?.lastname,
            email: data?.email,
          },
          order: {
            orderId: saveUuidOrderId,
            description: verify_otp?.requisites?.purpose_of_payment,
            amount,
            expire,
            additionalItems: {
              PayerEmail: data?.email,
              PayerPhone,
              'Merchant.PolisNumber': reserve.policy_number,
            },
            BankingDetails: {
              Payee: {
                Id: verify_otp?.requisites?.edrpou,
                Bank: {
                  Mfo: verify_otp?.requisites?.mfo,
                  Account: verify_otp?.requisites?.iban,
                },
              },
              Payer: { Name: `${data?.firstname} ${data?.lastname}` },
              Narrative: {
                Name: verify_otp?.requisites?.purpose_of_payment },
            },
          },
        });
        if (!test_payment?.error) {
          setData((prev:IInsurancePolicy) => ({ ...prev, policy_payment: test_payment,
            ref: saveUuidOrderId }));
        }
      }
    }
  }
};

// збереження даних у базі
interface SavePolicyDataProps {
  gqlClient: ApolloClient<any>,
  data:IInsurancePolicy
}

interface DocToSave extends IDocProps{
  body:IInsurancePolicy
}
export const savePolicyData = async ({ gqlClient, data }:SavePolicyDataProps) => {

  const saveUuid = data?.ref || uuid_v4();
  const class_name = 'doc.assurance';
  const today = dayjs().format();

  const docToSave:DocToSave = {
    ref: saveUuid,
    _id: data._id ?? `${class_name}|${saveUuid}`,
    class_name,
    date: data.date ?? today,
    body: {
      ref: saveUuid,
      confirm_personal_data: data.confirm_personal_data,
      agreement: data.agreement,
      no_taxi: data.no_taxi,
      policy_type: data.policy_type,
      privilege: data.privilege,
      companie: data.companie,
    //
      selectedPrice: data.companie.selectedPrice,
      selectedFranchise: data.companie.selectedFranchise,
      paymentPrice: data?.policy_payment?.amount ?? 0,
      paymentType: data.payment_type || 'card',
      policy_number: data.policy_reserve?.policy_number || '',
      policy_status: data.policy_confirm?.policy_status || '',

      // user data
      type_of_insured_person: data.type_of_insured_person,
      firstname: data?.firstname || '',
      lastname: data?.lastname || '',
      middlename: data?.middlename || '',
      birth_date: data?.birth_date,
      region: data.region,
      city: data.city,
      street: data.street,
      house: data.house,
      flat: data?.flat || '',
      email: data?.email || '',
      phone: data?.phone || '',
      phone_normalized: data?.phone_normalized || '',
      egrpou: data?.egrpou,

      // document data
      document_type: data.document_type,
      document_issue_date: data.document_issue_date,
      document_issued: data.document_issued,
      document_number: data.document_number,
      document_series: data.document_series,
      document_record: data.document_record,
    //
      next_service_date: data?.next_service_date,
      period: data?.period,
    //
    // policy_payment: data?.policy_payment,
      policy_payment: data?.policy_payment,
      policy_cashless_payment:data?.policy_cashless_payment,

      /// policy_reserve: data?.policy_reserve,
      policy_reserve: data?.policy_reserve, 
    ///
    // policy_confirm - маємо отримувати після оплати
      policy_confirm: data?.policy_confirm,
    ///

      policy_start_date: data?.policy_start_date,
      start_date: data?.start_date,
      vehicle_city: data?.vehicle_city,
      vehicle_city_id: data?.vehicle_city_id,
      vehicle_mark: data?.vehicle_mark,
      vehicle_model: data?.vehicle_model,
      vehicle_plate_num: data?.vehicle_plate_num,
      vehicle_type: data?.vehicle_type,
      vehicle_vin: data?.vehicle_vin,
      vehicle_year: data?.vehicle_year,
      vehicle_engine_volume: data?.vehicle_engine_volume,

      policy_easyPay: data?.policy_easyPay,
  }
};
  return  gqlClient.mutate({
    mutation: updateAssurance,
    variables: { input: docToSave },
  });
};
