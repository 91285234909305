import { useCallback, useEffect, useRef, useState } from 'react';
import { DoclistPageTemplate } from 'components';
import DataGrid, { Column, Lookup } from 'devextreme-react/data-grid';
import { createDataSource } from 'utils/gql-datasource';
import { OptionsBar } from './components/optionsBar/optionsBar';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'contexts/auth';
import { gql } from 'apollo';
import { IPartner } from 'types/datatypes/IPartner';
import { IProj } from 'types/datatypes/IProj';

const pageName = 'Термінальні документи';

interface ITerminalDoc {
  ref: string;
  date: string;
  number_doc: string;
  caption: string;
  doc_amount: number;
  payer: string;
  doctype: string;
  proj: string;
  partner: IPartner
}

const dsGetOrders = gql(`query terminalDocs($limit: Int, $jfilt: [JSON], $offset: Int, $sort: sort, $requireTotalCount: Boolean!, $userData: userData)	{
  totalcount: terminalDocs(jfilt: $jfilt, totalCount: 1, userData: $userData) @include(if: $requireTotalCount) { totalcount }
  list: terminalDocs(limit: $limit, jfilt: $jfilt, offset: $offset, sort: $sort, userData: $userData) { ref date number_doc caption doc_amount payer doctype proj partner {name} }
}
`);
const dsOrders = createDataSource<ITerminalDoc>(dsGetOrders);
dsOrders.on('loading', (options: any) => { options.userData = dsOrders?.userData });

interface IUserData {
  phone: string;
  doctype: Pick<IProj, 'ref'>;
}

export const TerminalListDocsPage = () => {
  const { projects } = useAuth();
  const [userData, setUserData] = useState<IUserData>();
  const navigate = useNavigate();
  const gridRef = useRef<DataGrid>(null);

  useEffect(() => {
    dsOrders.userData = userData;
    gridRef.current?.instance.refresh();
  }, [userData]);

  const handleOptionsChanghe = useCallback(({ phone, doctype }: IUserData) => 
    setUserData({ phone, doctype })
  , [])

  return (
    <DoclistPageTemplate
      gridRef={gridRef}
      deps={[userData, projects]}
      pageName={pageName}
      remoteFiltering={false}
      sorting={false}
      dataSource={dsOrders}
      columnsDataFields={{ date: 'date', docNumber: 'number_doc' }}
      onEdit={(ref) => navigate(`/order/${ref}`)}
      stateStoringEnabled={true}
      optionsComponent={<OptionsBar onChange={handleOptionsChanghe} />}
    >
      <Column
        dataField='proj'
        dataType='string'
        caption='Тип'
        alignment='center'
        allowHiding={false}
        allowResizing={true}
      >
        <Lookup dataSource={projects.items} valueExpr='ref' displayExpr='name'/>
      </Column>
      <Column
        dataField='partner.name'
        dataType='string'
        caption='Контрагент'
        alignment='left'
        allowHiding={false}
        allowResizing={true}
        minWidth={160}
      />
      <Column
        dataField='doc_amount'
        dataType='number'
        caption='Сума'
        alignment='right'
        allowHiding={false}
        allowResizing={true}
      />
    </DoclistPageTemplate>
  );
};
