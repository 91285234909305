import { API } from './api';
import { API_PAYMENT } from './apiPayment';

export const get_vehicle_by_plate_num = new API('get-vehicle/by-plate-num');
export const policy_exist = new API('policy/exist');
export const get_companies_offers = new API('get-companies/offers');
export const get_vehicle_types = new API('get-vehicle-types');
export const search_cities = new API('get-vehicle-cities/search');
export const get_privileges = new API('get-privileges');
export const get_periods = new API('get-periods');
export const policy_reserve = new API('policy/reserve');
export const policy_reserve_check = new API('policy/reserve/check');
export const policy_send_otp = new API('policy/send-otp');
export const policy_verify_otp = new API('policy/verify-otp');
export const get_paysystems = new API('get-paysystems');
export const policy_confirm = new API('policy/confirm');
export const payment_create_order = new API_PAYMENT('merchant/create-order');
export const payment_order_state = new API_PAYMENT('merchant/order-state');

interface initapiProps {
  assuranceToken?:string;
  easyPayToken?:string;
}
export const initapi = ({ assuranceToken, easyPayToken }:initapiProps) => {
  if (!assuranceToken || !easyPayToken) return;

  get_vehicle_by_plate_num.pkey = assuranceToken;
  policy_exist.pkey = assuranceToken;
  get_companies_offers.pkey = assuranceToken;
  get_vehicle_types.pkey = assuranceToken;
  search_cities.pkey = assuranceToken;
  get_privileges.pkey = assuranceToken;
  get_periods.pkey = assuranceToken;
  policy_reserve.pkey = assuranceToken;
  policy_reserve_check.pkey = assuranceToken;
  policy_send_otp.pkey = assuranceToken;
  policy_verify_otp.pkey = assuranceToken;
  get_paysystems.pkey = assuranceToken;
  policy_confirm.pkey = assuranceToken;

  payment_create_order.pkey = easyPayToken;
  payment_order_state.pkey = easyPayToken;

};
