import React, { forwardRef,  useImperativeHandle, useMemo, useRef, useState } from 'react';
import { List, Popup, Button, ScrollView } from 'devextreme-react';
import { showError } from 'utils/notify';
import { messages } from 'messages';
import { LiqpayWidget } from './liqpay-widget';
import { ToolbarItem } from 'devextreme-react/popup';
import { EasyPayWidget } from './easypay-widget';

import styles from './payment-form.module.scss';

const paymentTypes = [
  { id: 'checkout', name: 'Liqpay (недоступно)', requires: 'LiqPay', disabled: true, visible: false },
  { id: 'easyPay', name: 'EasyPay', requires: 'EasyPay' },
  { id: 'test', name: 'Тест', requires: 'testSys' },
];

const ListItem = (data:{id:string, name:string}) => (
  <div className={styles.listItem} id={data.id}>
    <div>{data.name}</div>
    <div className={`dx-icon-info ${styles.infoIcon}`} title={data.name}></div>
  </div>
);

interface PaymentFormProps {
  visible:boolean;
  paymentData:any,
  onClose?:()=>void,
  availableMethods:any,
  description:any
}

export const PaymentForm = forwardRef(({ visible = true, paymentData, onClose, availableMethods, description }:PaymentFormProps,ref) => {
  
  const types = paymentTypes.filter((type) => ((availableMethods ?? []).includes(type.requires)));
  const defaultType = types?.find((t) => !(t?.disabled ?? false))?.id;


  const [ state, setState ] = useState('SELECT');
  const [ selectedMethod, setSelectedMethod ] = useState(defaultType);
  const popupRef = useRef<Popup>(null);

  useImperativeHandle(ref, () => {
    return {
      show,
    };
  });

  const show = () => {
    //@ts-ignore
    // popupRef.current?.instance.visible=true;
    popupRef.current?.instance.show();
  }

  const handleError = () => {
    showError(messages.PAYMENT_FAILED);
    setState('SELECT');
  };

  const innerComponent = useMemo(() => {
    let innerComponent;
    switch (selectedMethod) {
    case 'checkout':
      innerComponent = <LiqpayWidget paymentData={paymentData} onError={handleError}/>;
      break;
    case 'easyPay':
      innerComponent = <EasyPayWidget paymentData={paymentData} onError={handleError}/>;
      break;
    default:
      
      break;
    }
    if (selectedMethod === 'checkout') {
      innerComponent = <LiqpayWidget paymentData={paymentData} onError={handleError}/>;
    }
    return innerComponent;
  }, [paymentData, selectedMethod]);

  return (
    <Popup
      ref={popupRef}
      className={styles.popup}
      // visible={visible && !!paymentData}
      onHiding={onClose}
      onHidden={() => setState('SELECT')}
      dragEnabled
      closeOnOutsideClick={state === 'SELECT'}
      showCloseButton
      showTitle
      title={state === 'SELECT' ? 'Вибір методу оплати' : ''}
      width='min-content'
      maxWidth='700px'
      height='auto'
      maxHeight='100%'
    >
      <ToolbarItem
        widget='dxButton'
        toolbar='top'
        location='before'
        visible={state !== 'SELECT'}
        options={{
          icon: 'chevronleft',
          focusStateEnabled: false,
          text: 'Назад',
          onClick: () => setState('SELECT'),
        }}

      />

      {state === 'SELECT' ?
        <div>
          <div className={styles.description}>
            {description}
          </div>
          <div className={styles.amount}>
            {`${paymentData?.amount} UAH`}
          </div>
          <List
            className={styles.list}
            focusStateEnabled={false}
            itemRender={ListItem}
            items={types}
            keyExpr='id'
            displayExpr='name'
            selectionMode='single'
            defaultSelectedItemKeys={defaultType ? [ defaultType ] : null}
            onSelectionChanged={(e) => setSelectedMethod(e.addedItems?.[0]?.id)}
          />
          <div className={styles.buttonContainer}>
            <Button
              activeStateEnabled
              focusStateEnabled={false}
              text='вибрати'
              icon='check'
              disabled={!selectedMethod}
              onClick={() => setState('PAY')}
            />
          </div>
        </div> :
        <ScrollView showScrollbar='onHover'>
          <div className={styles.payContainer}>
            {innerComponent}
          </div>
        </ScrollView>
      }
    </Popup>
  );
});

// PaymentForm.propTypes = {
//   paymentData: PropTypes.shape({
//     id: PropTypes.string.isRequired,
//     amount: PropTypes.number.isRequired,
//     description: PropTypes.oneOfType(
//       [ PropTypes.string, PropTypes.element ],
//     ).isRequired,
//     proj: PropTypes.string,
//     data: PropTypes.object,
//     organization: PropTypes.string,
//   }),
//   description: PropTypes.string,
//   availableMethods: PropTypes.arrayOf(
//     PropTypes.oneOf([ 'LiqPay', 'EasyPay', 'testSys' ]),
//   ),
//   visible: PropTypes.bool,
//   onClose: PropTypes.func,
// };

export default PaymentForm;
