import React from 'react';
import { API_HOST } from 'app-constants';
import { IInsurancePolicy } from 'types/datatypes/IInsurance';

const qr_code_url = `${API_HOST}/barcode?bcid=qrcode&text=`;

interface QRCodePaymentFormProps {
  data:IInsurancePolicy
}

export const QRCodePaymentForm:React.FC<QRCodePaymentFormProps> = ({ data }) => {
  const url = `${qr_code_url}${data.policy_payment?.forwardUrl ?? data?.forwardUrl}`;
  return (
    <div style={{ display: 'flex', height: 'auto', margin: '0 auto', width: '100%', justifyContent: 'center' }}>
      <img src={url} width={200} height={200} alt=''/>
    </div>
  );
};

export default QRCodePaymentForm;
