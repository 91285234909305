import { v4 as uuid } from 'uuid';

export class DBObject<T extends {ref:string}=any> {
  class_name:string;
  isNew:boolean;
  ref:string;
  to1C: boolean; 

  constructor({ class_name, ref }:{class_name:string, ref:string}) {
    this.class_name = class_name;
    this.ref = ref === 'new' ? uuid() : ref;
    this.isNew = ref === 'new';
    this.to1C = false;
  }

  get _id() {
    return `${this.class_name}|${this.ref}`;
  }

  export() {
    return {
      _id: this._id,
      ref: this.ref,
      class_name: this.class_name,
      to1C: true,
    };
  }

  load(data:T) {
    this.ref = data?.ref;
    this.isNew = false;
  }

}
