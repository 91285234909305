import { gqlClient } from 'gql-client.js';
import { gql } from 'graphql.macro';
import { messages } from 'messages';
import { useState, useEffect } from 'react';
import { showError } from 'utils/notify';

const getBrands = gql`query ($limit: Int) { car_brand(limit: $limit) }`;

export const useBrands = () => {
    const [brands, setBrands] = useState([]);
    const [brandList, setBrandList] = useState([]);
  useEffect(() => {
    gqlClient
      .query({ query: getBrands, variables: {}, })
      .then((response) => {
        const res = response?.data?.car_brand;
        if (res) {
            setBrands(res);
            setBrandList(res?.map((item:any) => item?.name));
        }
      })
      .catch(() => showError(messages?.DATA_LOAD_FAILED));
  }, []);

  return {
    brands, brandList
  };
};
