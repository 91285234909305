
import { IInsurancePolicy } from 'types/datatypes/IInsurance';

interface TerminalPaymentFormProps {
  data:IInsurancePolicy
}

export const TerminalPaymentForm:React.FC<TerminalPaymentFormProps> = () => (
  <>Terminal</>
);

export default TerminalPaymentForm;
