import { useEffect, useState } from 'react';
import { HashRouter as Router } from 'react-router-dom';
import './dx-styles.scss';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';

import 'react-toastify/dist/ReactToastify.css';
import './scss/otk-styles.scss';
import LoadPanel from 'devextreme-react/load-panel.js';
import { NavigationProvider } from 'contexts/navigation';
import { AuthProvider, useAuth } from 'contexts/auth';
import { useScreenSizeClass } from 'utils/media-query';
import { Content } from './Content';
import { UnauthenticatedContent } from './UnauthenticatedContent';

import uaMessages from 'contexts/ua.json';
import { appInfo } from './app-info';
import { MessageProvider } from 'contexts/messages/messages';
import { NotificationManager } from 'components';
import { News } from 'pages/news/news.jsx';
import { Suspense } from 'react';
import { loadMessages, locale } from 'devextreme/localization';

const App = () => {
  loadMessages(uaMessages);
  locale('uk');

  const { user, loading, reAuth } = useAuth();
  const [firstStart, setFirstStart] = useState(false);

  useEffect(() => {
    document.title = appInfo.docTitle;
  }, []);

  useEffect(() => {
    const chkUser = async () => {
      if (!user) {
        const res = await reAuth();
        if (res.isOk) {
          setFirstStart(false);
          // history.push(location.pathname);
        } else {
          setFirstStart(true);
        }
      }
    };

    chkUser();
  }, [reAuth, user]);

  const cbHandler = () => {
    setFirstStart(false);
    return 0;
  };

  if (loading) {
    return <LoadPanel visible={true} />;
  }

  if (user) {
    if (firstStart) {
      return <News onApprove={cbHandler} />;
    }

    return (
      <MessageProvider>
        <NotificationManager />
        <Suspense fallback={<LoadPanel />}>
          <Content />;
        </Suspense>
      </MessageProvider>
    );
  }

  return <UnauthenticatedContent />;
};

const AppMain = () => {
  const screenSizeClass = useScreenSizeClass();

  return (
    <Router>
      <AuthProvider>
        <NavigationProvider>
          <div className={`app ${screenSizeClass}`}>
            <App />
          </div>
        </NavigationProvider>
      </AuthProvider>
    </Router>
  );
};

export default AppMain;
