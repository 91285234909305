import { gql } from "apollo/gql";

export const ORDER_FOR_PAY_QUERY = gql(/* GraphQL */`
query orderForPay($ref: String) {
  orderForPay(ref: $ref) {
    _id _rev
   ref number_doc input_doc_number date 
   partner {ref name} 
   note doc_amount trust_doc account_kind
   pos_blank {
    row 
    nom {ref name}
    nomType price quantity amount vat_rate vat_amount coefficient in_doc_number in_doc_date file_lab file_1c }
  }
}`);

export const MUTATE_ORDER_QUERY = gql(`mutation setOrderForPay($input: JSONObject){setOrderForPay(input: $input)}`);
