import { useQuery } from '@apollo/client';
import { useAuth } from 'contexts';
import { gql } from 'graphql.macro';
import { ILab } from 'types/datatypes/ILab';

const getLab = gql`query getLab($ref: String){getLab(ref: $ref)}`;

interface QueryAnswer {
  getLab: ILab[]
}

export const useLab = () => {
  const { user } = useAuth();
  const { data, loading } = useQuery<QueryAnswer>(getLab, {
    variables: { ref: user.branch },
    fetchPolicy: 'cache-first',
  });
  return {
    loading,
    lab: data?.getLab?.[0],
  };
};
