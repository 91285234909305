import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import ContextMenu, { Position } from 'devextreme-react/context-menu';
import List from 'devextreme-react/list';
import PropTypes from 'prop-types';
import { useAuth } from 'contexts';
import './user-panel.scss';



const UserPanel = ({ menuMode }) => {
  const { user: { name, avatarUrl }, signOut } = useAuth();
  const navigate = useNavigate();

  const userOut = useCallback(async () => signOut(undefined), [signOut]);
  const navigateToProfile = useCallback(() => navigate('/profile'), [navigate]);

  const menuItems = useMemo(() => [
    { text: 'Профіль', icon: 'user', onClick: navigateToProfile, disabled: true },
    { text: 'Вихід', icon: 'runner', onClick: userOut },
  ], [navigateToProfile, userOut]);

  return (
    <div className={'user-panel'}>
      <div className={'user-info'}>
        <div className={'image-container'}>
          <div style={{
              background: `url(${avatarUrl}) no-repeat #fff`,
              backgroundSize: 'cover',
            }}
            className={'user-image'}
          />
        </div>
        <div className={'user-name'}>{name}</div>
      </div>

      {menuMode === 'context' &&
        <ContextMenu
          items={menuItems}
          target={'.user-button'}
          showEvent={'dxclick'}
          width={140}
          cssClass={'user-menu'}
        >
          <Position my={'top center'} at={'bottom center'} />
        </ContextMenu>
      }
      {menuMode === 'list' &&
        <List className={'dx-toolbar-menu-action'} items={menuItems} />
      }
    </div>
  );
};

UserPanel.propTypes = {
  menuMode: PropTypes.string,
};

export default UserPanel;
