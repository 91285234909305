import { loader } from 'graphql.macro';
import { IPartner } from 'types/datatypes/IPartner';
import { createDataSource } from 'utils/gql-datasource';

const dsDebits = loader('./dsDebitsByAct.gql');

export interface IDebitorka {
  amount: number; // 852
  direction: 'd'|'k';
  key_field_desc: string; //"Акт об оказании производственных услуг УТС-0084-004631 від 20.09.2024 10:07:48"
  partner: Partial<IPartner> & {
    isWarn:boolean
  }; //{ref: "47fdeab4-ea22-11eb-8abf-00155d000c0a", name: "КРИВБАСВОДОКАНАЛ КП"}
  name: string; // "КРИВБАСВОДОКАНАЛ КП"
  ref: string; // "47fdeab4-ea22-11eb-8abf-00155d000c0a"
  trans: {
    ref?: string; //"089f86dd-771d-11ef-8b3d-00155d000b4b",
    caption: string; // "Замовлення УТС-0084-003071 від 20.09.2024",…
    doc_amount: number; //
    proj?: string; //"ОТК"
  }  
}

export const dsDebitsByAct = createDataSource<IDebitorka>(dsDebits, {
  processData: (data) => {
    const partnersWithWarning:string[] = [];

    const newdata = data?.map((row, index) => {
      if (row.trans === null) {
        row.trans = {
          caption: '',
          doc_amount: 0,
        };
        partnersWithWarning.push(row.partner.ref ?? '');
      }
      return { ...row, ref: index };
    });

    if (partnersWithWarning.length) {
      newdata.forEach((r) => {
        if (partnersWithWarning.includes(r.partner.ref ?? '')) {
          r.partner.isWarn = true;
        }
      });
    }

    return newdata;

  },
  loadOptions: {
    take: 9999,
  },
});

dsDebitsByAct.on('loading', (options:any) => {
  options.userData = dsDebitsByAct?.userData;
});
