import { PngIcon } from "components/menuItem/PngItem";
import { TextBox } from "devextreme-react";
import { useState } from "react";
import { useDebounceCallback } from "usehooks-ts";

interface SearchButtonProps {
  onInput: (text: string | undefined) => void
}

const inputAttr = { style: 'color:black; background:white' };

export const SearchButton = ({ onInput }: SearchButtonProps) => {
  const [value, setValue] = useState('')
  const debounced = useDebounceCallback(onInput, 500);
  return (
    <search style={{display:'flex', alignItems:'center', flexGrow:2}}>
    <TextBox 
      placeholder="...назва містить..."
      inputAttr={inputAttr}
      onInput={(e) => {
        const nameText = (e.event?.currentTarget as HTMLInputElement).value;
        debounced( nameText > '' ? nameText : undefined)
      }}
      onValueChanged={({value})=>setValue(value)}
      value={value}
      width={'100%'}
    />
    <PngIcon iconType="remove"  onClick={()=>{setValue(''); onInput(undefined)}} />
    </search>
  )

}