import { OrderCollections } from "models/documents/orderCollections/orderCollectionModel";

interface IState {
  doc: OrderCollections;
  loading: boolean,
}

interface IROption {
  type:Ttypes,
  payload:any
}
type Ttypes = 'LOADING'|'UPDATE'|'UPDATE_DOC';

export const reducer = (state:IState, { type, payload }:IROption) => {
  switch (type) {
    case 'LOADING':
      if (payload !== state.loading) {
        return { ...state, loading: payload };
      }
      break;

    case 'UPDATE':
      return { ...state, ...payload };

    case 'UPDATE_DOC':
      if (payload.ext_json) {
        state.doc.ext_json = {
          ...state.doc.ext_json,
          ...payload.ext_json,
        };
        delete payload.ext_json;
      }
      Object.keys(payload as Partial<OrderCollections>).forEach((key ) => { 
        state.doc[key as  keyof Pick<OrderCollections, 'date'|'ext_json'> ] = payload[key];
      });
      return { ...state };

    default:
      break;
  }
  return state;
};
