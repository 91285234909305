import { Popup } from "devextreme-react";
import { RefObject } from "react";

import { ITotalValues } from "../IReportRow";

import styles from '../labreport.module.scss';
import { totalByKeyLegend } from "../helper";

interface PopupLegendProps {
  payDetailPopupRef:RefObject<Popup>,
  totals: ITotalValues;
}

export const PopupLegend = ({ payDetailPopupRef, totals }: PopupLegendProps) => {
  return <Popup ref={payDetailPopupRef} closeOnOutsideClick width={'400'} height={'auto'} title="Підсумки" showCloseButton>
    <div className={styles.popupWrapper}>
      {Object.keys(totals).map((tabkey) => {
        return (<div style={{ paddingBottom: '20px' }} key={tabkey}>
          <span className={styles.h4}>{tabkey}</span>
          <div  className={styles.cardRow}>
            <div className={styles.cardCol}>
              {Object.keys(totals[tabkey]).map((key) => {
                if (key === 'byType') return undefined
                return (
                  <div className={styles.cash} key={`${tabkey}${key}`}>
                    <span>{key}</span><span>{(totals[tabkey][key] as number).toFixed(0)} </span>
                  </div>
                );
              })}
            </div>
            <div className={styles.cardCol}>
              {Object.keys(totals[tabkey].byType).map((key) => {
                const data = totals[tabkey].byType;
                return (
                  <div className={styles.cash} key={`${tabkey}${key}`}>
                    <span>{totalByKeyLegend[key as keyof typeof totalByKeyLegend]}</span><span>{data[key].toFixed(0)} </span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>)
      })
      }
    </div>
  </Popup>
}
