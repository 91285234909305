import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import styles from './optionsBar.module.scss';
import SelectBox from 'devextreme-react/ui/select-box';
import { useAuth } from 'contexts';
import { normalizePhoneNumber } from 'utils/phone-number';
import { Button, RadioGroup } from 'devextreme-react';
import { PhonePopup } from '../changePhone/ChangePhone';
import { updateBranchOptions } from 'utils/helpers';

export const OptionsBar = ({ onChange }) => {
  const [ phone, setPhone ] = useState();
  const [ proj, setProj ] = useState(null);
  const [ showPhonePopup, setShowPhonePoup ] = useState(false);
  const { projects, lab, options: { branchOptions = {} } } = useAuth();
  const [ items, setItems ] = useState([]);

  useEffect(() => {
    setPhone(normalizePhoneNumber(lab.contacts.phone_corp));
  }, [ lab.contacts.phone_corp ]);

  useEffect(() => {
    const _items = [ normalizePhoneNumber(lab.contacts.phone_corp) ];
    if (branchOptions?.alterPhone && branchOptions?.alterPhone !== '') _items.push(branchOptions.alterPhone);
    setItems(_items);
  }, [ lab.contacts.phone_corp, branchOptions?.alterPhone ]);

  useEffect(() => {
    onChange({ phone, doctype: proj });
  }, [onChange, phone, proj]);

  const onClickPhoneChange = () => setShowPhonePoup(true);

  return (
    <div className={styles.wrapper}>
      <div className={styles.caption}> Додаткові фільтри: </div>
      <SelectBox
        items={projects.available}
        placeholder="Напрям..."
        displayExpr="name"
        valueExpr="id"
        showClearButton={true}
        value={proj}
        onValueChanged={(value) => setProj(value.value)}
        width={150}
      />
      <RadioGroup
        items={items}
        value={phone}
        layout="horizontal"
        onValueChange={(val) => setPhone(val)}
      />
      <Button stylingMode="outlined" onClick={onClickPhoneChange}>
        {items.length === 1 ? 'Додати' : 'Змінити'} додатковий номер
      </Button>
      <PhonePopup
        visible={showPhonePopup}
        currentPhone={branchOptions?.alterPhone ?? ''}
        onValueChanged={async (val) => {
          setShowPhonePoup(false);
          branchOptions.alterPhone = val;
          await updateBranchOptions(branchOptions);
          if (val === '') {
            setPhone(normalizePhoneNumber(lab.contacts.phone_corp));
            setItems([ normalizePhoneNumber(lab.contacts.phone_corp) ]);
          } else {
            setPhone(val);
            setItems([
              normalizePhoneNumber(lab.contacts.phone_corp),
              branchOptions.alterPhone,
            ]);
          }
        }}
        onHide={() => setShowPhonePoup(false)}
      />
    </div>
  );
};

OptionsBar.propTypes = {
  onChange: PropTypes.func.isRequired,
};
