import { gql } from 'apollo';
import { createDataSource } from 'utils/gql-datasource';
import { IReport } from './IReport';

const dsGetLabReports = gql(`query dsGetLabReports($ref: String, $limit: Int, $jfilt: [JSON], $offset: Int, $sort: sort, $requireTotalCount: Boolean!) {
  totalcount: lab_report(ref: $ref, jfilt: $jfilt, totalCount: 1 ) @include(if: $requireTotalCount) { totalcount }
  list: lab_report(ref: $ref, limit: $limit, jfilt: $jfilt, offset: $offset, sort: $sort) {
    ref amount status number_doc date has_error vehicle_map note gnumber warn_maybe_error
    partner {id name}
    service {id name}
    vehicle_model {ref id name}
    ext_json
  }
}`);

const dsGetLabReportsFull = gql(`query dsGetLabReportsFull($ref: String, $limit: Int, $jfilt: [JSON], $offset: Int, $sort: sort, $requireTotalCount: Boolean!) {
    totalcount: lab_report(ref: $ref, jfilt: $jfilt, totalCount: 1) @include(if: $requireTotalCount) {totalcount}
    list: lab_report(ref: $ref, limit: $limit, jfilt: $jfilt, offset: $offset, sort: $sort) {
      ref  amount rp rv status number_doc date has_error vehicle_map note blank_series blank_number warn_maybe_error gnumber ext_json
      partner { ref name individual_legal }
      service { ref name }
      invoice { ref caption number_doc doc_amount pay_kind date isSubContract ext_json badact_reason
        services { nomRef gos_code quantity amount msto }
      }  
      vehicle_model {ref id name type {ref name} brand {ref name} }
      source_report {ref}
    }
  }`);



export const dsLabReports = createDataSource<IReport, 'ref'>(dsGetLabReports);
export const dsLabReportsFull = createDataSource<IReport, 'ref'>(dsGetLabReportsFull);
