import React, {
  Dispatch,
  FC,
  useEffect,
  useState,
} from 'react';

import dayjs from 'dayjs';
//components
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { TextLine } from 'pages/ep-main/components/text-line';

import { getCompaniesOffers } from '../../api/requests';
import { CompanieOfferItem } from './companie_offer_item';
import { checkVehicleTypeCDE, filterByFranchise } from '../utils';
import styles from './style.module.scss';
import { IInsuranceCompany, IInsurancePolicy } from 'types/datatypes/IInsurance';

interface FormProps {
  setData : Dispatch<React.SetStateAction<IInsurancePolicy>>,
  data:IInsurancePolicy,
}

export const CompaniesOffers:FC<FormProps> = ({setData, data}) => {

  const { franchise, privilege, period } = data;
  const [ companiesOffers, setCompaniesOffers ] = useState([]);
  const [ filteredCompaniesOffers, setFilteredCompaniesOffers ] = useState<IInsuranceCompany[]>([]);
  const [ loading, setLoading ] = useState(false);

  const handleSetConpanieOffer = (data:IInsuranceCompany) => {
    setData((prev) => ({ ...prev, step: 3, companie: { ...data } }));
  };

  const requestOptins:any = {
    vehicle_type: data?.vehicle_type || '',
    vehicle_city_id: data?.vehicle_city_id || '',
    privilege,
    period,
  };
  const isCDE = checkVehicleTypeCDE(data.vehicle_type);
  if (isCDE && data.next_service_date) {
    requestOptins.next_service_date = dayjs(data.next_service_date).format('YYYY-MM-DD');
  }
  if (isCDE && data.start_date) {
    requestOptins.start_date = dayjs(data.start_date).format('YYYY-MM-DD HH:mm:ss');
  }
  if (isCDE && period) requestOptins.period = period;

    requestOptins.vehicle_engine_volume = data?.vehicle_engine_volume;

  useEffect(() => {
    setLoading(true);
    getCompaniesOffers(requestOptins)?.then((offers) => {
      setCompaniesOffers(offers);
      setFilteredCompaniesOffers(filterByFranchise(offers, franchise));
      setLoading(false);
    }, () => {
      setLoading(false);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ privilege, period ]);

  useEffect(() => {
    setFilteredCompaniesOffers(filterByFranchise(companiesOffers, franchise));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ franchise ]);
  return (<>
    <TextLine text="Список предложений" />
    <div className={styles?.container}>
      
        <LoadIndicator visible={loading}
          className={styles?.res_loader}
          indicatorSrc='img/loader.gif'
          width={50}
          height={50}
        /> 
      { filteredCompaniesOffers?.length > 0 ?
          filteredCompaniesOffers?.map((offer, index) => (
            <CompanieOfferItem
              key={index}
              offer={offer}
              franchise={franchise}
              setCompanieOffer={handleSetConpanieOffer}
            />),
          ) : 'Пропозицій не знайдено'}
    </div>
    <TextLine />
  </>);
};


export default CompaniesOffers;
