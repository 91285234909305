export type TInsurancePeriod = 'year'|'month'| '15 day';
export type TPaymentType = 'link'|'card'|'qr'|'terminal'|'cashless';
export type TInsuranceCompanyCode = 'bbc';
export type TInsurancePolicyStatus = "confirmed"|"declined"|'';
export type TCarCategories = 'A1'| 'A2'| 'B1' | 'B2'| 'B3'|'C1'| 'C2'| 'D1'| 'D2'| 'E';

export const payType: {
    [ key in TPaymentType ]: string;
} = {
  card: 'Картка',
  qr: 'QR-code',
  terminal: 'Терминал',
  link: 'SMS, Viber, Email',
  cashless: 'Безготівка',
};

export interface IInsuranceCompany {
    dgo: any[],
    code: TInsuranceCompanyCode; //"bbc",
    name: string; //"ББС ІНШУРАНС",
    fullname: string;// "АКЦІОНЕРНЕ ТОВАРИСТВО \"СТРАХОВА КОМПАНІЯ \"ББС ІНШУРАНС\"",
    mtsbu_id: number; //129,
    selectedPrice: number; //4096,
    selectedFranchise: number; //0,
    additional_options:  { code: string; label: string }[];
    tariffs?:any[]
}

export interface IPaymentInfo {
  payment_id: number;
  sign: string;
  date: string;
  amount: number,
}

export interface IPolicyEasyPayment {
  error?: string|null,
  amount: number; //4096,
  amountMax?: number; //20000,
  amountMin?: number; //1,
  forwardUrl?: string; //"https://easypay.ua/ua/whitepage/badef311-d946-4877-aa61-6ae04f24fc81",
  responseItems?: any,
  transactionId?: any,
  retrievalReferenceNo?: any;
  sign?:string
} 

export interface IPolicyRecerve {
  id: number; //2658280,
  start_date: string; //"2024-11-01 00:00:00",
  policy_number: string;
  policy_status: "reserved",
  policy_created_at: string; //"2024-10-30 19:16:54",
  policy_direct_link: string; //"https://policy.mtsbu.ua/qr/policyinfo/ua?md=20F04D523AE963A41F55EA5B9D33BA33B87C545158A38B4A3EC05951642A3F32"
}

export interface IPolicyCashlessPayment {
  order_for_payment?: string;
}

export interface IInsurancePolicy {
    ref?: string; //"fc5c57c9-24cf-4b21-b729-5fdaeb9b6249",
    city: string; //"Київ",
    flat: string; //"53",
    email: string; //"o.kosovtsova@otk.in.ua",
    house: string; //"10",
    phone: string; // "+380(67)234-3405",
    egrpou: string; //"3171320855",
    period: TInsurancePeriod; //"year",
    region: string; //"Київ",
    street: string; //"пр-т. Науки",
    no_taxi: boolean,
    companie: IInsuranceCompany; 
    lastname: string; // "Марченко",
    agreement: boolean; //true,
    firstname: string; // "Євгеній",
    privilege: "without",
    birth_date: string; //"1986-10-29T00:00:00",
    middlename: string; // "Анатольйович",
    start_date: string; //"2024-11-01T00:00:00",
    paymentType: 'link'|'card'|'qr'|'terminal'|'cashless',
    policy_type: "osago",
    vehicle_vin: string; //"JTJCV00W504011536",
    paymentPrice: number; // 4096,
    vehicle_city: string //"м.Київ",
    vehicle_mark: string; //"LEXUS",
    vehicle_type: TCarCategories; //"B4",
    vehicle_year: string; //"2019",
    document_type: 'passport'|'id_card',
    policy_number?: string; // "224441005",
    policy_status?: TInsurancePolicyStatus,
    selectedPrice: number; //4096,
    vehicle_model: string; //"Н/Д",
    policy_confirm?: {
      id: number; //2658280,
      end_date: string; //"2025-10-31 00:00:00",
      start_date: string; //"2024-11-01 00:00:00",
      policy_number: string; //"224441005",
      policy_status?: TInsurancePolicyStatus,
      policy_pdf_link?: string; //"https://api.xsurance.com.ua/policy/pdf/0c7aea7fb614da0be4b4792afbfcd09e",
      policy_dgo_number?: string|number|null,
      policy_direct_link?: string; //"https://policy.mtsbu.ua/qr/policyinfo/ua?md=3A6E04742E15AA212E6B00A435FEFD5D00A85A7AE91B404272BA0027AEA9404C",
      policy_dgo_pdf_link?: string|null,
      road_assident_faq_link?: string; // "https://api.xsurance.com.ua/storage/app/uploads/public/645/2bb/5d4/6452bb5d4913b222799388.pdf"
    },
    policy_easyPay: IPaymentInfo | {
      body: {
        date: string; // "2024-10-30T20:31:37.0028168+02:00",
        action: "payment",
        details: {
          "desc": string; // "=Страховий платіж без ПДВ №224441005 від 30/10/2024;4096 грн; 2658280;",
          amount: number; // 4096,
          payment_id: number;// 1502041614,
          recurrent_id: any
        },
        version: "v3.2",
        order_id: string; //"fc5c57c9-24cf-4b21-b729-5fdaeb9b6249",
        merchant_id: 16028,
        additionalitems: {
          Rrn: string; //"430420444178",
          AuthCode: string; //"839406",
          BankName: string; //"OJSC UNIVERSAL BANK(Ukraine)",
          "Card.Pan": string; //"516797******7730",
          MerchantKey: "easypay.ua",
          "Card.BrandType": "Visa"|"MasterCard",
          [x:string]: any;
        }
      },
      date: string; // "2024-10-30T20:31:37",
      payment_id?: number; // 1502041614
      sign?:string
    },
    policy_payment?: IPolicyEasyPayment,
    policy_cashless_payment?: IPolicyCashlessPayment,
    policy_reserve?: IPolicyRecerve,
    document_issued: string; //"Голосіївським РУ ГУМВС України в м.Києві",
    document_number: string; //"385043",
    document_record: string; //"",
    document_series: string; //"МЕ",
    vehicle_city_id: string; //"8000000000",
    phone_normalized: string; //"380672343405",
    next_service_date?: string|null,
    policy_start_date?: string; //"2023-02-01T00:00:00",
    selectedFranchise: number,
    vehicle_plate_num: string; //"KA5779MC",
    document_issue_date: string; //"2014-05-11T00:00:00",
    confirm_personal_data: boolean,
    vehicle_engine_volume: string ; //"4461",
    type_of_insured_person: 'Фіз. особа' |'Юр. особа';
    ///////////////////
    payment_type?:TPaymentType;
    [x:string]: any;
}