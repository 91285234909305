import { DOCLIST_PAGE_SIZE, uaFilterRowText } from "app-constants";
import { dsPartners } from "datasources";
import { CheckBox, DataGrid, Menu, Tooltip } from "devextreme-react";
import { Column, FilterRow, Paging, Scrolling, Selection } from "devextreme-react/data-grid";
import { useSearchDatagridHandler } from "hooks";
import { forwardRef, useEffect, useMemo, useState } from "react";
import { IPartner } from "types/datatypes/IPartner";
import { ItemInfo, MenuItem } from "types/otkTypes";

import styles from './partner-search.module.scss';

export interface IPatrnerFromState {
  visible: boolean,
  new: boolean,
  ref: string | null
};

interface PartnerDatagridProps {
  searchField?: string;
  value: Partial<IPartner>;
  onSelectionChanged: (row: IPartner) => void;
  setPartnerFormState: (prop: IPatrnerFromState) => void;
}

export const PartnerDatagrid = forwardRef( ({
    searchField = 'name',
    value,
    onSelectionChanged,
    setPartnerFormState,
  }: PartnerDatagridProps, ref: any) => {
  const [currentRowData, setCurrentRowData] = useState<IPartner>({} as IPartner);
  const [globalSearch, setGlobalSearch] = useState(false);
  const [globalSearchTooltip, setGlobalSearchTooltip] = useState(false);

  const { gridRef, focusElementRef, dataReady, onCurrentRowChange }
    = useSearchDatagridHandler<IPartner>(ref, searchField, onSelectionChanged);

  useEffect(() => {
    dsPartners.userData = {
      ...dsPartners?.userData,
      globalSearch,
    };
    gridRef.current?.instance?.refresh();
  }, [globalSearch, gridRef]);

  const handleMenuClick = (e: ItemInfo) => {
    switch (e.itemData?.id) {
      case 'open': {
        setPartnerFormState({
          visible: true,
          new: false,
          ref: currentRowData.ref,
        });
        break;
      }
      case 'select': {
        onSelectionChanged?.(currentRowData);
        break;
      }
      case 'new': {
        setPartnerFormState({
          visible: true,
          new: true,
          ref: null,
        });
      }
  }};

    const rowselected = !!currentRowData?.ref;
    const menuItems: MenuItem[] = useMemo(() => [
      {
        text: 'Вибрати',
        id: 'select',
        icon: 'check',
        disabled: !rowselected,
      },
      {
        text: 'Відкрити',
        id: 'open',
        icon: 'edit',
        disabled: !rowselected,
      },
      {
        text: 'Додати контрагента',
        id: 'new',
        icon: 'add',
      },
    ], [rowselected]);

    return (
      <div ref={focusElementRef} className={styles.container}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Menu
            onItemClick={handleMenuClick}
            activeStateEnabled={false}
            items={menuItems}
          />
          <div style={{ display: 'flex' }}
            onMouseEnter={() => setGlobalSearchTooltip(true)}
            onMouseLeave={() => setGlobalSearchTooltip(false)}
          >
            <CheckBox
              text='Повний пошук' id='gSearch'
              value={globalSearch}
              onValueChanged={(val) => setGlobalSearch(val.value)}
            />
            <Tooltip target='#gSearch' visible={globalSearchTooltip}>
              <div className='tooltip'>
                Розширений пошук за назвою контрагента.<br />
                Необхідно вказати більше трох символів.<br />
                Час пошуку - збільшується
              </div>
            </Tooltip>
          </div>
        </div>
        <DataGrid
          ref={gridRef}
          dataSource={dataReady ? dsPartners : []}
          hoverStateEnabled
          onRowDblClick={({ data }) => onSelectionChanged && onSelectionChanged(data)}
          onSelectionChanged={({ selectedRowsData }) => {
            if (selectedRowsData.length > 0) {
              setCurrentRowData(selectedRowsData[0]);
              onCurrentRowChange(selectedRowsData[0]);
            }
          }}
          remoteOperations
          allowColumnResizing
          showBorders
          height='90%'
        >
          <Selection mode='single' />
          <Scrolling mode='virtual' rowRenderingMode='virtual' />
          <Paging enabled={true} pageSize={DOCLIST_PAGE_SIZE} />
          <FilterRow visible={true} {...uaFilterRowText} />
          <Column
            dataField='name'
            dataType='string'
            caption='Назва'
            filterOperations={['contains', 'startswith', 'endswith']}
            allowSorting={false}
          />
          <Column
            dataField='edrpou'
            dataType='string'
            alignment='left'
            caption='Код ЄДРПОУ'
            width={130}
            filterOperations={['startswith']}
            allowSorting={false}
          />
          <Column
            dataField='phones'
            dataType='string'
            alignment='left'
            caption='Телефон'
            filterOperations={['contains']}
            width={130}
            allowSorting={false}
          />
        </DataGrid>
      </div>
    )
  }
);
