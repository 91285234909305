import { showError } from 'utils/notify';
import fileds from './textFields.json';

//документація https://test.xsurance.com.ua/
//      swagger/1cadd254cb40eaa5db6a0e960c1ee0f0fa1f6d17b91be9739123e3a9d528af13
//інпортуємо для побудови базового URL запитів
const proxyUrl = process.env.REACT_APP_POLICY_API_PROXY_API;

export class API {
  constructor(entity = '') {
    this.URL = `${proxyUrl}/xsurance/${entity}`;
    this.pkey = '';
  }

  dataToURL(data = {}) {
    let value = '';
    value = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      ).join('&');
    return `?${value}`;
  }

  async errorShowMes(data = {}) {
    const messageArray = [];
    const dataJson = await data?.json() || {};
    Object.keys(dataJson)
      .forEach(
        (key) => {
          if (Array.isArray(dataJson[key])) {
            messageArray.push(`\n${fileds?.[key] || key}:`,
              ...dataJson[key],
              '\n');
          }
        },
      );
    showError(messageArray.join('\n'), 5000);
  }

  async errorMes500Code(data = {}) {
    const dataText = await data?.text() || 'error code 500';
    showError(dataText, 8000);
  }

  async request({ url, method, isAuth, body, type }) {
    const headers = {};
    const options = { method };
    //token use soon
    const token = '';
    if (isAuth) headers.authorization = `Bearer ${token}`;
    //==============
    if (type !== 'file' && body) {
      options.body = JSON.stringify(body);
      headers['content-type'] = 'application/json';
    }
    headers.pkey = this.pkey;
    options.headers = headers;

    return fetch(url, options).then((res) => {
      if (res?.status === 200) {
        return res.json();
      } else if (res?.status === 406) {
        this.errorShowMes(res);
        return null;
      } else if (res?.status === 500) {
        this.errorMes500Code(res);
        return null;
      }
      return null;
    }).catch((error) => {
      console.log('error', error);
    });
  }

  get(path, isAuth = false, queries = null) {
    return this.request({
      url: `${this.URL}/${path}${queries ? this.dataToURL(queries) : ''}`,
      method: 'GET',
      isAuth,
    });
  }

  post(path = '', isAuth = false, body = {}, options = {}) {
    // console.log('options', options);
    return this.request({
      url: `${this.URL}/${path}${body ? this.dataToURL(body) : ''}`,
      method: 'POST',
      isAuth,
      // body,
      // type: options?.type,
    });
  }

}
