import { gqlClient } from 'gql-client.js';
import { DEFAULT_PROJ_PNAME } from 'app-constants';
import { gql } from 'apollo';
import { CustomStoreExt } from 'types/datatypes/classes';
import { ILab } from 'types/datatypes/ILab';
import { IProj, TProjID } from 'types/datatypes/IProj';

export const dsGetProj = gql(/* GraphQL */`query dsGetProj {list: getProj { id ref name predefined_name}}`);

const getProj = () => gqlClient
  .query({ query: dsGetProj, fetchPolicy: 'cache-first' })
  .then((res) => res.data?.list ?? []);

export const dsProj = new CustomStoreExt<IProj|undefined>({
  key: 'ref',
  load: () => getProj(),
  // byKey: (ref) => getProj().then((res) => res?.filter((p) => p?.ref === ref)),
});



export class Projects {
  items: IProj[];
  available:IProj[];
  default:IProj;
  sert:string;
  gbo:string;
  otk:string;
  kl:string;
  ekmt:string;

  constructor(initArray:IProj[]=[], lab:ILab) {
    this.items = initArray;
    const labProjs = lab.projs.map((p) => p.direction) ?? [];
    this.available = this.items.filter((p) => labProjs.includes(p.ref)) ?? [];
    const defProject = this.byName(DEFAULT_PROJ_PNAME);
    this.default = this.available.find((p) => p.ref === defProject?.ref) ?? this.available?.[0];

    //-- predefined projects
    this.sert = this.refById('sert')!;
    this.gbo = this.refById('gbo')!;
    this.otk = this.refById('otk')!;
    this.kl = this.refById('kl')!;
    this.ekmt = this.refById('ekmt')!;
  }

  public nameByRef(ref:string) {
    return this.items.find((p) => p.ref === ref)?.name;
  }

  public byName(name:string) {
    return this.items.find((p) => p.predefined_name === name);
  }

  public refById(id:TProjID) {
    return this.items.find((p) => p.id === id)?.ref;
  }

  public idByRef(ref:string) {
    return this.items.find((p) => p.ref === ref)?.id;
  }

}
