import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useCallback, useMemo } from 'react';

const getPayKinds = loader('./getPayKinds.gql');

const DEFAULT_PAYMENT_TYPE_ID = '000000002';
const T300_PAYMENT_TYPE_ID = '000000005';
const ONLINE_PAYMENT_TYPE_ID = '000000004';
const TERMINAL_PAYMENT_TYPE_ID = '000000003';
const NOCASH_PAYMENT_TYPE_ID = '000000002';

const payKindIds = {
    online: ONLINE_PAYMENT_TYPE_ID,
    default: DEFAULT_PAYMENT_TYPE_ID,
    t300: T300_PAYMENT_TYPE_ID,
    terminal: TERMINAL_PAYMENT_TYPE_ID,
    nocash: NOCASH_PAYMENT_TYPE_ID
  };

export const usePayKinds = () => {
  
  const { data } = useQuery(getPayKinds, { fetchPolicy: 'cache-first' });
  const payKinds = useMemo(()=>(data?.list ?? []),[data?.list]);

  const payKindByRef = (ref) => payKinds?.find((r) => r.ref === ref);
  const byId = useCallback((id) => payKinds?.find(p => p.id === id),[payKinds]);
  const availablePayKinds = useCallback((lab, { disableT300 } = { disableT300: false }) => {
    //disable T300 in def list
    let availPayKinds = payKinds.map( (r) => ({
          ...r,
          disabled: r.id === payKindIds.t300
         }));
    // use payKind list from lab settings in 1C if available
    const labPayKinds = lab?.pay_kinds?.map((r) => r.pay_kind) ?? [];
    if (labPayKinds.length) {
      availPayKinds = payKinds.map((r) => ({
        ...r,
        disabled: !labPayKinds.includes(r.ref) || (r.id === payKindIds.t300 && disableT300),
      }));
    }
    return availPayKinds;
  }, [payKinds]);

  const payKindByName = useMemo(()=>({
    default: byId(payKindIds.default) ?? payKinds?.[0],
    online: byId(payKindIds.online),
    t300: byId(payKindIds.t300),
    terminal: byId(payKindIds.terminal),
    nocash: byId(payKindIds.nocash)
  }),[byId, payKinds]);

  return {
    payKinds, availablePayKinds, payKindIds, payKindByName, payKindByRef };
};
