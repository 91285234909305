import React, { useState } from 'react';
import { TextBox } from 'devextreme-react';

import styles from './optionsBar.module.scss';
import { normalizeCarNumber } from 'utils/normalize-car-number';

interface OptionsBarProps {
  onChange:(s:string)=>void;
  onChangeEdrpou:(s:string)=>void
}

export const OptionsBar = ({ onChange, onChangeEdrpou }:OptionsBarProps) => {
  const [ gos_code, setGosCode ] = useState('');
  const [ edrpou, setEdrpou ] = useState('');
  const inputAttr = { style: 'color:black; background:white; padding: 8px 4px' };
  return (
    <div className={styles.wrapper}>
      <div className={styles.caption}> Додаткові фільтри: </div>
      <div className={styles.filtfields}>
      <TextBox
        placeholder='Держ номер'
        label='Держ номер'
        stylingMode='underlined'
        showClearButton={true}
        value={gos_code}
        inputAttr={inputAttr}
        onValueChanged={({ value }) => {
          onChange(value);
          setGosCode(normalizeCarNumber(value));
        }}/>
        <TextBox
        placeholder='ЄДРПОУ'
        label='ЄДРПОУ'
        hint='код ЄДРПОУ починається з...'
        stylingMode='underlined'
        showClearButton={true}
        value={edrpou}
        inputAttr={inputAttr}
        onValueChanged={({ value }) => {
          onChangeEdrpou(value);
          setEdrpou(value);
        }}/>
        </div>
    </div>
  );
};
