import { gql } from 'apollo';
import { gqlClient } from 'gql-client';
import { DEFAULT_AVATAR_URL } from 'app-constants';
// import * as Sentry from '@sentry/react';
import { IUser } from 'types/datatypes/IUser';
import { IApiResponse } from 'types/otkTypes';
import { IOptions } from 'types/IOptions';
import { ILab } from 'types/datatypes/ILab';
import { IProj } from 'types/datatypes/IProj';

const AUTH = gql(`
  query getAuth($user: String, $pass: String) {
    auth(name: $user, pass: $pass)
  }
`);

const LOGOUT = gql(`
  query logout {
    logout
  }
`);

const GETOPTIONS = gql(`
  query getOptions($branch:String){ 
    getOptions (branch:$branch)
  }
`);

const LOADINIT = gql(`
  query loadInit($branch:String){ 
    getOptions (branch:$branch)
    getLab (with_price:true)
    getProj { id ref name predefined_name }
  }
`);

const getAuth = async (user:string, pass:string) => gqlClient.query({
  query: AUTH,
  variables: { user, pass },
})
  .then((response) => {
    if (response.data.auth) response.data.auth.isOk = true;
    return response.data?.auth || { isOk: false };
  })
  .catch((e) => {
    // Sentry.captureException(e)
  });

export const logout = async () => gqlClient.query({ query: LOGOUT })
  .then((response) => response.data.logout);

export const loadOptions = async (branch:any) => gqlClient.query({
  query: GETOPTIONS,
  variables: { branch },
})
  .then((response) => (response.data.getOptions || {}),
  )
  .catch((e) => console.error('getOptions failed', e));

export const getInit = async (user:IUser):Promise<{
  options:IOptions,
  lab: ILab,
  proj: IProj[]
}> => gqlClient.query({
  query: LOADINIT,
  variables: { branch: user.branch },
})
  .then((response) => {
    return {
      options: response.data.getOptions,
      lab: response.data.getLab?.[0],
      proj: response.data?.getProj as IProj[],
    };
  });

export const signIn = async (email:string, password:string):Promise<IApiResponse> => getAuth(email, password)
  .then((result) => ({
    isOk: !!result?.isOk,
    message: result?.isOk ? '' : 'Помилка входу',
    data: {
      ...result,
      email,
      avatarUrl: result.avatarUrl || DEFAULT_AVATAR_URL,
    },
  }))
  .catch((e) => {
    // Sentry.captureException(e);
    return ({
      isOk: false,
      message: 'Помилка входу',
      data:undefined
    });
  });


export const createAccount = async (email:string, password:string) => {
  console.log(email, password);
  Promise.resolve({
    isOk: true,
  });
  /*
  {
    isOk: false,
    message: 'Failed to create account',
  };
  */
};


export const changePassword = async (email:string, recoveryCode:any) => {
  console.log(email, recoveryCode);
  Promise.resolve({
    isOk: true,
  });
  /*
  {
    isOk: false,
    message: 'Failed to change password',
  };
  */
};

export const resetPassword = async (email:string) => {
  console.log(email);
  Promise.resolve({
    isOk: true,
  });
  /*
    {
      isOk: false,
      message: 'Failed to reset password',
    };
  */
};
