import { PngIcon, PNGIconTypes } from "components/menuItem/PngItem";
import { TagBox } from "devextreme-react";
import { FC, useCallback, useState } from "react";
import { TStatuses } from "types/otkTypes";
import { TStates } from "../Clients/Clients";
import CustomStore from "devextreme/data/custom_store";

const items = new CustomStore({
    loadMode: 'raw',
    key: 'state',
    load() {
        return [
            { state: 'remind', text: '< 1 міс' },
            { state: 'urgent', text: 'щойно минув' },
            { state: 'lost', text: '> 1 міс' }
        ] as { state: TStates, text: string }[];
    },
})

interface TagProps {
    product: any;
    onMouseEnter: any;
}

const getIconName = (state: string) => {
    let iconName: PNGIconTypes = 'bell';
    switch (state) {
        case 'remind': {
            iconName = 'bell';
            break;
        }
        case 'urgent': {
            iconName = 'alert';
            break;
        }
        case 'lost': {
            iconName = 'cross';
            break;
        }
    }
    return iconName
}

const Tag = ({ product, onMouseEnter }: TagProps) => {
    return (
        <div onMouseEnter={(e) => onMouseEnter(e, product)}>
            <PngIcon iconType={getIconName(product.state)} />
        </div>
    )
}

interface ItemProps {
    data: any;
}
const Item = ({ data }: ItemProps) => {
    return (
        <div style={{ display: 'flex', gap: '1rem' }} className="custom-item">
            <PngIcon iconType={getIconName(data.state)} />
            <div className="product-name">{data.text}</div>
        </div>
    );
}

interface TagStatusProps {
    onInput: (states: TStates[] | undefined) => void
}

export const TagStatus: FC<TagStatusProps> = ({onInput}) => {

    const [gridBoxValue, setGridBoxValue] = useState<TStatuses[]>([]);

    const syncDataGridSelection = useCallback((e: any) => {
        if (e.value) {
            setGridBoxValue(e.value || []);
            onInput(e.value || [])
        }
    }, [onInput]);

    return <TagBox
        style={{ flexGrow: 0 }}
        width={140}
        showClearButton={true}
        valueExpr='state'
        displayExpr='text'
        value={gridBoxValue}
        onValueChanged={syncDataGridSelection}
        dataSource={items}
        tagRender={(data) => (
            <Tag product={data} onMouseEnter={(e: any, p: any) => {}} />
        )}
        itemRender={(data) => (<Item data={data} />)}
    />
}
