import { PageContent } from "components"
import { TabPanel, Item } from "devextreme-react/tab-panel"
import { FC } from "react"
import { tabsData } from "./data"
import { TabButton } from "./components/TabButton"

const CRMPage: FC = () => {
  return (<PageContent size='large' >
    <div style={{ height: '91vh' }}>
      <TabPanel style={{ height: '100%' }}
        defaultSelectedIndex={0}
        itemComponent={TabButton}
      >
        {tabsData.map(tabItem => {
          return (
            <Item key={`tab-${tabItem.id}`} icon={tabItem.icon} title={tabItem.title} component={tabItem.component} />
          )
        })}

      </TabPanel>
    </div>
  </PageContent>)
}

export default CRMPage