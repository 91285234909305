//components
import { FormField } from 'components/form-field/form-field';
import { FC } from 'react';
import { IInsurancePolicy } from 'types/datatypes/IInsurance';

export const AutoShortInfo:FC<{data:IInsurancePolicy}> = ({data}) => {
  return (<>
    <FormField textWidth={'300px'} text={'Марка + Модель + Держ.Номер: '}>
      <span>
        {data?.vehicle_mark} {data?.vehicle_model} {data?.vehicle_plate_num}
      </span>
    </FormField>
    <FormField textWidth={'300px'} text={'Тип ТЗ + Vin Code: '}>
      <span>{data?.vehicle_type} {data?.vehicle_vin}</span>
    </FormField>
    <br />
  </>);
};

export default AutoShortInfo;
