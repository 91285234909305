import { gql } from 'apollo';
import CustomStore from 'devextreme/data/custom_store';
import { gqlClient } from 'gql-client.js';

const dsGetPayKinds = gql(`query getPayKinds { list: getPayKindes { ref id pay_form name } }`);

const getPayKinds = () => gqlClient
  .query({ query: dsGetPayKinds, fetchPolicy: 'cache-first' })
  .then((res) => res.data.list);

export const dsPayKinds = new CustomStore({
  key: 'ref',
  load: () => getPayKinds(),
  byKey: (ref) => getPayKinds().then((res) => res.filter((p) => p.ref === ref)),
});
